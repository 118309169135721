import { useEffect } from 'react';

// The `element` parameter should point to the observed element (pass ref.current instead of ref).
function useClickOutside({ element, onClickOutside, condition = true }) {
    useEffect(() => {
        function handleClickOutside(event) {
            if ((element && !element.contains(event.target)) && condition) {
                onClickOutside();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [element, onClickOutside, condition]);
}

export default useClickOutside;