import './ErrorComponent.scss';

import PropTypes from 'prop-types';
import React from 'react';

import appErrorTypes from '../../../constants/appErrorTypes';
import { ReactComponent as ConnectionLostDarkImg } from '../../images/errorComponent/connection-lost-dark.svg';
import { ReactComponent as ConnectionLostImg } from '../../images/errorComponent/connection-lost.svg';
import { ReactComponent as EncriptedQueryDarkImg } from '../../images/errorComponent/encripted-query-dark.svg';
import { ReactComponent as EncriptedQueryImg } from '../../images/errorComponent/encripted-query.svg';
import { ReactComponent as EndOfImagesDarkImg } from '../../images/errorComponent/end-of-images-dark.svg';
import { ReactComponent as EndOfImagesImg } from '../../images/errorComponent/end-of-images.svg';
import { ReactComponent as Error404DarkImg } from '../../images/errorComponent/error-404-dark.svg';
import { ReactComponent as Error404Img } from '../../images/errorComponent/error-404.svg';
import { ReactComponent as Error500DarkImg } from '../../images/errorComponent/error-500-dark.svg';
import { ReactComponent as Error500Img } from '../../images/errorComponent/error-500.svg';
import { ReactComponent as GenericErrorDarkImg } from '../../images/errorComponent/generic-error-dark.svg';
import { ReactComponent as GenericErrorImg } from '../../images/errorComponent/generic-error.svg';
import { ReactComponent as NoResultsInMarketDarkImg } from '../../images/errorComponent/no-results-in-market-dark.svg';
import { ReactComponent as NoResultsInMarketImg } from '../../images/errorComponent/no-results-in-market.svg';
import { ReactComponent as PrivacyErrorDarkImg } from '../../images/errorComponent/privacy-error-dark.svg';
import { ReactComponent as PrivacyErrorImg } from '../../images/errorComponent/privacy-error.svg';
import { ReactComponent as ResultsNotFoundDarkImg } from '../../images/errorComponent/results-not-found-dark.svg';
import { ReactComponent as ResultsNotFoundImg } from '../../images/errorComponent/results-not-found.svg';
import Button from '../Button/Button';

const { searchErrors } = appErrorTypes;

ErrorComponent.defaultProps = {
	className: '',
	children: '',
	title: '',
	img: '',
	imgDark: '',
	imgWidth: '',
	buttonText: '',
	align: '',
};

ErrorComponent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	img: PropTypes.string,
	imgDark: PropTypes.string,
	imgWidth: PropTypes.number,
	buttonText: PropTypes.string,
	align: PropTypes.oneOf(['', 'left']),
};

function ErrorComponent({
	className,
	children,
	title,
	errorType,
	imgWidth,
	buttonText,
	linkButtonText,
	align,
	buttonClickHandler,
}) {
	const getLightThemeImg = (errorType) => {
		switch (errorType) {
			case searchErrors.linkExpired:
				return (
					<PrivacyErrorImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.internalServerError:
				return (
					<Error500Img
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.genericError:
				return (
					<GenericErrorImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.endOfImages:
				return (
					<EndOfImagesImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.endOfVideos:
				return (
					<EndOfImagesImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.noResultsInData:
				return (
					<ResultsNotFoundImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.resourceNotFound:
				return (
					<Error404Img
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.connectionLost:
				return (
					<ConnectionLostImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case 'EncriptedQueryError':
				return (
					<EncriptedQueryImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			case searchErrors.noResultsInMarket:
				return (
					<NoResultsInMarketImg
						className="error-component__img"
						width={imgWidth}
					/>
				);
			default:
				return null;
		}
	};

	const getDarkThemeImg = (errorType) => {
		switch (errorType) {
			case searchErrors.linkExpired:
				return (
					<PrivacyErrorDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.internalServerError:
				return (
					<Error500DarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.genericError:
				return (
					<GenericErrorDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.endOfImages:
				return (
					<EndOfImagesDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.endOfVideos:
				return (
					<EndOfImagesDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.noResultsInData:
				return (
					<ResultsNotFoundDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.resourceNotFound:
				return (
					<Error404DarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.connectionLost:
				return (
					<ConnectionLostDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case 'EncriptedQueryError':
				return (
					<EncriptedQueryDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			case searchErrors.noResultsInMarket:
				return (
					<NoResultsInMarketDarkImg
						className="error-component__img error-component__img--dark"
						width={imgWidth}
					/>
				);
			default:
				return '';
		}
	};

	return (
		<div
			className={`error-component${className ? ` ${className}` : ''}${
				align ? ` error-component--${align}` : ''
			}`}
		>
			<div className="error-component__inner">
				<div
					className={`error-component__info error-component__info--left`}
				>
					<h3 className="error-component__title">{title}</h3>
					<div className="error-component__text">{children}</div>
					{linkButtonText && (
						<Button
							className="error-component__link-button"
							onClick={buttonClickHandler}
						>
							{linkButtonText}
						</Button>
					)}
				</div>
				<div className="error-component__picture">
					{getLightThemeImg(errorType)}
					{getDarkThemeImg(errorType)}
				</div>
				{buttonText ? (
					<Button
						type="button"
						className="error-component__button"
						onClick={buttonClickHandler}
					>
						{buttonText}
					</Button>
				) : null}
			</div>
		</div>
	);
}

export default ErrorComponent;
