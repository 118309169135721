const faqQuestionCatalog = [
    {
        id: "question1",
        question: "homepage-section-4-question-1-ask",
        answer: "homepage-section-4-question-1-answer",
    },
    {
        id: "question2",
        question: "homepage-section-4-question-2-ask",
        answer: "homepage-section-4-question-2-answer",
    },
    {
        id: "question3",
        question: "homepage-section-4-question-3-ask",
        answer: "homepage-section-4-question-3-answer",
    },
    {
        id: "question4",
        question: "homepage-section-4-question-4-ask",
        answer: "homepage-section-4-question-4-answer",
    },
    {
        id: "question5",
        question: "homepage-section-4-question-5-ask",
        answer: "homepage-section-4-question-5-answer",
    }
];

export default faqQuestionCatalog;