import { fetchEventSource } from '@microsoft/fetch-event-source';
import axiosRetry from 'axios-retry';
import fetchJsonp from 'fetch-jsonp';
import moment from 'moment';
import * as queryStringHelper from 'query-string';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { change } from 'redux-form';
import toQueryString from 'to-querystring';

import history from '../app/history';
import constants from '../constants';
import appErrorTypes from '../constants/appErrorTypes';
import countries from '../constants/countries';
import filterOptions from '../constants/filterOptions';
import * as loadStatuses from '../constants/loadStatuses';
import protectedParams from '../constants/protectedParams';
import searchTypes from '../constants/searchTypes';
import { encrypt, getLimitedQueryString } from '../utils/cryptoHelper';
import { getLocationSharingPermissions } from '../utils/getSharingLocation';
import {
    formatLocationPopupOptionsLabel,
    getFromLocalStorage,
    removeFromLocalStorage,
    saveToLocalStorage,
    storageAvailable,
    validatePublicKey,
} from '../utils/helpers';
import {
    changeBetaStatus,
    changeEmailSubmissionStatus,
    changeSearchType,
    historyPush,
    setMapSearchType,
    setRecentActionData,
    submitMetrics,
    toggleTimeToShowFooter,
} from './app';
import { headers as apiHeaders, axios, getAPIBase } from './axiosConfig';

export const formatLocation = (lat, long) => {
    return `lat:${lat};long:${long};re:${18000}`;
};
export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        window.location.hostname === 'bs-local.com' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);
export const safeJSONParse = (value, defaultValue = null) => {
    try {
        return JSON.parse(value);
    } catch (error) {
        if (error instanceof SyntaxError) {
            return defaultValue;
        }
        throw error;
    }
};
const corsKey = process.env.REACT_APP_CORS_KEY;
const isMobileButNotIPad = isMobile && !(isTablet && isIOS);

export const SEARCH_RESULTS_FETCHED = 'SEARCH_RESULTS_FETCHED';
export const WEB_PAGES_FETCHED = 'WEB_PAGES_FETCHED';
export const TOTAL_ESTIMATED_MATCHES_FETCHED = 'TOTAL_ESTIMATED_MATCHES_FETCHED';
export const WEB_PAGES_FETCHED_MOBILE = 'WEB_PAGES_FETCHED_MOBILE';
export const MOBILE_PAGES_ARE_LOADING = 'MOBILE_PAGES_ARE_LOADING';
export const VIDEOS_FETCHED = 'VIDEOS_FETCHED';
export const WIKIBOX_DATA_FETCHED = 'WIKIBOX_DATA_FETCHED';
export const NEWS_FETCHED = 'NEWS_FETCHED';
export const NEWS_FETCHED_MOBILE = 'NEWS_FETCHED_MOBILE';
export const MOBILE_NEWS_ARE_LOADING = 'MOBILE_NEWS_ARE_LOADING';
export const IMAGES_FETCHED = 'IMAGES_FETCHED';
export const RELATED_SEARCHES_FETCHED = 'RELATED_SEARCHES_FETCHED';
export const RANKING_RESPONSE_FETCHED = 'RANKING_RESPONSE_FETCHED';
export const RANKING_RESPONSE_FETCHED_MOBILE = 'RANKING_RESPONSE_FETCHED_MOBILE';
export const QUERY_CONTEXT_FETCHED = 'QUERY_CONTEXT_FETCHED';
export const CHANGE_FILTER_BY_TIME_SEARCH_SETTING = 'CHANGE_FILTER_BY_TIME_SEARCH_SETTING';
export const CLEAR_ALL_FILTERS_SEARCH_SETTING = 'CLEAR_ALL_FILTERS_SEARCH_SETTING';
export const CHANGE_LOCATION_SEARCH_SETTING = 'CHANGE_LOCATION_SEARCH_SETTING';
export const CLEAR_WIKIBOX = 'CLEAR_WIKIBOX';
export const SEARCH_QUERY_SAVED = 'SEARCH_QUERY_SAVED';
export const SAVE_FORM_INPUT = 'SAVE_FORM_INPUT';
export const SET_SEARCH_PAGE_NUMBER = 'SET_SEARCH_PAGE_NUMBER';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';
export const CLEAR_VIDEOS = 'CLEAR_VIDEOS';
export const READ_QUERY_FROM_URL = 'READ_QUERY_FROM_URL';
export const CHANGE_FILTER_BY_IMAGE_SIZE_SETTING = 'CHANGE_FILTER_BY_IMAGE_SIZE_SETTING';
export const CHANGE_FILTER_BY_COLOR_SETTING = 'CHANGE_FILTER_BY_COLOR_SETTING';
export const CHANGE_FILTER_BY_IMAGE_TYPE_SETTING = 'CHANGE_FILTER_BY_IMAGE_TYPE_SETTING';
export const CHANGE_FILTER_BY_IMAGE_ASPECT_RATIO_SETTING = 'CHANGE_FILTER_BY_IMAGE_ASPECT_RATIO_SETTING';
export const CHANGE_FILTER_BY_IMAGE_LICENSE_SETTING = 'CHANGE_FILTER_BY_IMAGE_LICENSE_SETTING';
export const CHANGE_SOURCE_API = 'CHANGE_SOURCE_API';
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
export const CHANGE_FILTER_BY_VIDEO_LENGTH_SETTING = 'CHANGE_FILTER_BY_VIDEO_LENGTH_SETTING';
export const CHANGE_FILTER_BY_VIDEO_RESOLUTION_SETTING = 'CHANGE_FILTER_BY_VIDEO_RESOLUTION_SETTING';
export const GET_LOCATION = 'GET_LOCATION';
export const WIDGET_VIDEOS_FETCHED = 'WIDGET_VIDEOS_FETCHED';
export const WIDGET_IMAGES_FETCHED = 'WIDGET_IMAGES_FETCHED';
export const WEATHER_WIDGET_DATA_FETCHED = 'WEATHER_WIDGET_DATA_FETCHED';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_RANKING_RESPONSE = 'CLEAR_RANKING_RESPONSE';
export const FOOTBALL_WIDGET_DATA_FETCHED = 'FOOTBALL_WIDGET_DATA_FETCHED';
export const CLEAR_WEB_PAGES = 'CLEAR_WEB_PAGES';
export const CLEAR_NEWS_PAGES = 'CLEAR_NEWS_PAGES';
export const WIDGET_NEWS_FETCHED = 'WIDGET_NEWS_FETCHED';
export const READ_SEARCH_LOCATION_INFO = 'READ_SEARCH_LOCATION_INFO';
export const READ_SAVED_LOCATION_FOR_WIDGET = 'READ_SAVED_LOCATION_FOR_WIDGET';
export const BASKETBALL_WIDGET_DATA_FETCHED = 'BASKETBALL_WIDGET_DATA_FETCHED';
export const CURRENCY_WIDGET_DATA_FETCHED = 'CURRENCY_WIDGET_DATA_FETCHED';
export const STOCKS_WIDGET_DATA_FETCHED = 'STOCKS_WIDGET_DATA_FETCHED';
export const CRICKET_WIDGET_DATA_FETCHED = 'CRICKET_WIDGET_DATA_FETCHED';
export const GET_PUBLIC_KEY = 'GET_PUBLIC_KEY';
export const SET_RESPONSE_TIME = 'SET_RESPONSE_TIME';
export const RELATED_IMAGES_FETCHED = 'RELATED_IMAGES_FETCHED';
export const CLEAR_RELATED_IMAGES = 'CLEAR_RELATED_IMAGES';
export const WIKIBOX_INFO_BOX_DATA_FETCHED = 'WIKIBOX_INFO_BOX_DATA_FETCHED';
export const WIKIBOX_INFO_BOX_DATA_NOT_FOUND = 'WIKIBOX_INFO_BOX_DATA_NOT_FOUND';
export const SET_RICHHEADER_LOAD_STATUS = 'SET_RICHHEADER_LOAD_STATUS';
export const SET_WIDGET_TYPE = 'SET_WIDGET_TYPE';
export const SET_WIDGET_DATA_TYPE = 'SET_WIDGET_DATA_TYPE';
export const SET_SPORTS_WIDGET_VIEW = 'SET_SPORTS_WIDGET_VIEW';
export const BUSINESS_WIDGET_DATA_FETCHED = 'BUSINESS_WIDGET_DATA_FETCHED';
export const EXPECTED_ENTITIES_FETCHED = 'EXPECTED_ENTITIES_FETCHED';
export const MOVIES_INFOCARD_FETCHED = 'MOVIES_INFOCARD_FETCHED';
export const MOVIES_COLLECTION_INFOCARD_FETCHED = 'MOVIES_COLLECTION_INFOCARD_FETCHED';
export const ADS_FETCHED = 'ADS_FETCHED';
export const INSTANT_ANSWER_COMPUTATION_FETCHED = 'INSTANT_ANSWER_COMPUTATION_FETCHED';
export const INSTANT_ANSWER_TRANSLATION_FETCHED = 'INSTANT_ANSWER_TRANSLATION_FETCHED';
export const INSTANT_ANSWER_TIMEZONE_FETCHED = 'INSTANT_ANSWER_TIMEZONE_FETCHED';
export const CLEAR_INSTANT_ANSWER = 'CLEAR_INSTANT_ANSWER';
export const EMAIL_SUBMITTED = 'EMAIL_SUBMITTED';
export const WIKIBOX_DATA_IS_EXPECTED = 'WIKIBOX_DATA_IS_EXPECTED';
export const CHANGE_PT = 'CHANGE_PT';
export const SET_IS_HEADER_FIXED = 'SET_IS_HEADER_FIXED';
export const CHANGE_PARAM = 'CHANGE_PARAM';
export const TIMEZONE_WIDGET_FETCHED = 'TIMEZONE_WIDGET_FETCHED';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SET_GEOLOCATION = 'SET_GEOLOCATION';
export const OPEN_WIDGET_IMAGE_IN_IMAGES_TAB = 'OPEN_WIDGET_IMAGE_IN_IMAGES_TAB';
export const SET_CLEAR_IMAGE_HANDLER = 'SET_CLEAR_IMAGE_HANDLER';
export const SET_LOCATION_SHARING = 'SET_LOCATION_SHARING';
export const SET_RECENT_QUERY_FILTERS = 'SET_RECENT_QUERY_FILTERS';
export const SET_DATA_FETCHING = 'SET_DATA_FETCHING';
export const SET_LOCATION_TIP_ALLOWED = 'SET_LOCATION_TIP_ALLOWED';
export const SET_LOCATION_TIP_STEP2_ALLOWED = 'SET_LOCATION_TIP_STEP2_ALLOWED';
export const SET_SEARCH_RESULT_CACHE_DISABLED = '';
export const GET_MORE_BUSINESSES = 'GET_MORE_BUSINESSES';
export const SET_MAP_ACTIVE_PIN = 'SET_MAP_ACTIVE_PIN';
export const SET_IS_MAP_TAB = 'SET_IS_MAP_TAB';
export const SAVE_BUSINESS_CONTEXT = 'SAVE_BUSINESS_CONTEXT';
export const SET_APPLE_MAP_SEARCH_LOCATION = 'SET_APPLE_MAP_SEARCH_LOCATION';
export const SET_DETAILED_MAP_QUERY = 'SET_DETAILED_MAP_QUERY';
export const WIKIHOW_WIDGET_DATA_FETCHED = 'WIKIHOW_WIDGET_DATA_FETCHED';
export const SET_SEARCH_EXTRA_PARAMS = 'SET_SEARCH_EXTRA_PARAMS';
export const SET_ADS_CONTEXT = 'SET_ADS_CONTEXT';
export const SET_YAHOO_ADS = 'SET_YAHOO_ADS'

const { paths } = constants;
const { searchErrors } = appErrorTypes;
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_KEY;
const disableHTTPHeaders = !isLocalhost;

export const sourceEventLogger = (event) => {
    console.log('-----------------');
    // eslint-disable-next-line
    console.log('%c Event Type: ' + `%c ${event.event}`, 'background: #222; color: #bada55', 'color: #EA4747');
    console.log('%c Timestamp: ', 'background: #222; color: #bada55', moment().format('hh : mm : ss : ms'));
    console.log('%c Service data: ', 'background: #222; color: #bada55', event);
    console.log('%c Data: ', 'background: #222; color: #bada55', JSON.parse(event.data), '\n\n');
};

export const isMobileBasedOnOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return true;
    }

    if (/android/i.test(userAgent)) {
        return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }

    return false;
};

export function submitBeta(email) {
    return async (dispatch, getState) => {
        let { publicKey, publicKeyVersion, publicKeyExpirationTime, publicKeyDomain } = getState().search;

        if (!validatePublicKey(publicKey, publicKeyExpirationTime, publicKeyDomain)) {
            await dispatch(getPublicKey());
            publicKey = getState().search.publicKey;
            publicKeyVersion = getState().search.publicKeyVersion;
        }
        const params = {
            email: email,
        };
        const withLimitedQueryStringParams = getLimitedQueryString({
            sendParams: params,
        });
        const encryptedQuery = encrypt(withLimitedQueryStringParams, publicKey, publicKeyVersion, 'submitBeta');
        return axios()({
            method: 'get',
            url: paths.register,
            params: encryptedQuery,
        }).then(() => {
            dispatch(changeEmailSubmissionStatus(true));
        });
    };
}

export function sendVisibilityFeedback(data) {
    return (dispatch, getState) => {
        const { openBetaSearchToken } = getState().app;
        let headers = {};
        let params = {
            type: 'mv',
            reqver: data.reqver,
            rg: data.rg,
            tids: data.impressionTokens.join(','),
        };
        headers['X-Search-Token'] = openBetaSearchToken;
        return axios()({
            method: 'get',
            url: paths.visibilityFeedback,
            params: params,
            headers,
        });
    };
}

export function getPublicKey() {
    return (dispatch, getState) => {
        let publicKey, publicKeyExpirationTime, publicKeyVersion, publicKeyDomain;
        if (storageAvailable('localStorage')) {
            publicKey = localStorage.getItem('publicKey');
            publicKeyExpirationTime = localStorage.getItem('publicKeyExpirationTime');
            publicKeyVersion = localStorage.getItem('publicKeyVersion');
            publicKeyDomain = localStorage.getItem('publicKeyDomain');
        }

        const apiBase = getAPIBase();
        if (!validatePublicKey(publicKey, publicKeyExpirationTime, publicKeyDomain)) {
            return axios()({
                method: 'get',
                url: paths.publicKeyPath,
            })
                .then((response) => {
                    dispatch({
                        type: GET_PUBLIC_KEY,
                        data: {
                            key: response.data,
                            headers: response.headers,
                            apiBase,
                        },
                    });
                })
                .catch((e) => {
                    if (e.message.indexOf('Network') !== -1) {
                        dispatch(setErrorMessageType(searchErrors.connectionLost));
                    }
                });
        } else {
            dispatch({
                type: GET_PUBLIC_KEY,
                data: {
                    key: publicKey,
                    publicKeyExpTimeFromLS: publicKeyExpirationTime,
                    headers: {
                        'x-public-key-version': publicKeyVersion,
                    },
                },
            });
        }
    };
}

export function searchRequest(
    query,
    params,
    type,
    mobile,
    pop,
    headers,
    shouldEncrypt,
    publicKeyVersion,
    publicKey,
    isEncryptedQueryFromURL,
    locationSetting,
    ignoreBusinessWidget,
    extraParams,
) {
    return (dispatch, getState) => {
        const { logger, encryptionSetting } = getState().app;
        const { pt, additionalParams, searchExtraParams } = getState().search;
        let sendParams = { ...params };
        let encryptedQuery;
        let timeout;
        let wikiTimeout;
        sendParams.q = query;
        sendParams.rh = 1;
        sendParams.wb = 1;
        sendParams.wbvers = 'v2';

        const sanitizedAdditionalParams = getSanitizedAdditionalParams(additionalParams);

        if (headers.lat && headers.long) {
            apiHeaders['X-Search-Location'] = formatLocation(headers.lat, headers.long);
        }

        const withLimitedQueryStringParams = getLimitedQueryString({
            sendParams,
            sanitizedAdditionalParams,
            pt,
        });

        if (shouldEncrypt && !isEncryptedQueryFromURL && publicKey) {
            encryptedQuery = encrypt(withLimitedQueryStringParams, publicKey, publicKeyVersion, 'Search');
        }
        if (isEncryptedQueryFromURL) {
            encryptedQuery = {
                encq: query.encq,
                encv: query.encv,
                encha: 'sha1',
            };
        }

        const time = performance.now();
        const apiBase = getAPIBase();
        const paramBuf = shouldEncrypt ? encryptedQuery : sendParams;
        const queryString = toQueryString(
            pt ? { ...sanitizedAdditionalParams, ...paramBuf, pt } : { ...sanitizedAdditionalParams, ...paramBuf },
        );
        dispatch(
            submitMetrics({
                Category: 'serp',
                Action: 'search',
                Object: 'query',
                Extras: params.mkt,
            }),
        );
        dispatch(setDataFetching(true));

        const onBingSearch = (event) => {
            if (logger) {
                sourceEventLogger(event);
            }
            const results = JSON.parse(event.data);
            const getQueryFromResponse = results.queryContext.originalQuery;

            const responseTime = (performance.now() - time) / 1000;
            dispatch({
                type: SET_RESPONSE_TIME,
                data: responseTime,
            });
            dispatch(
                submitMetrics({
                    Category: 'serp',
                    Action: 'measure',
                    Object: 'loadTime',
                    Value: Math.round(responseTime * 1000),
                    Extras: 'search',
                }),
            );
            if (query) {
                dispatch(setQuerySaved(query));
            }
            if (results.webPagesYahoo) {
                dispatch({
                    type: WEB_PAGES_FETCHED,
                    data: results.webPagesYahoo.value,
                });
                dispatch({
                    type: TOTAL_ESTIMATED_MATCHES_FETCHED,
                    data: results?.webPagesYahoo?.totalEstimatedMatches || 0,
                });
                if (results.rankingResponse) {
                    const shouldRenderImagesWidget =
                        results.images &&
                        results.images.value?.length > 0 &&
                        !results.rankingResponse.mainline?.items?.some((x) => x.answerType === 'Images');
                    if (sendParams.offset === 0 || !mobile) {
                        dispatch({
                            type: RANKING_RESPONSE_FETCHED,
                            data: results.rankingResponse,
                            shouldRenderImagesWidget: shouldRenderImagesWidget,
                        });
                    } 
                    // else if (mobile) {
                    //     dispatch(mobilePagesAreLoading(false));
                    //     dispatch({
                    //         type: RANKING_RESPONSE_FETCHED_MOBILE,
                    //         data: results.webPagesYahoo.value,
                    //         shouldRenderImagesWidget: shouldRenderImagesWidget,
                    //     });
                    // }
                }
            } else if (!results.webPagesYahoo) {
                dispatch({
                    type: !mobile ? WEB_PAGES_FETCHED : WEB_PAGES_FETCHED_MOBILE,
                    data: null,
                });
            }

            if (results.relatedSearches) {
                dispatch({
                    type: RELATED_SEARCHES_FETCHED,
                    data: results.relatedSearches,
                });
            } else if (!results.relatedSearches) {
                dispatch({
                    type: RELATED_SEARCHES_FETCHED,
                    data: [],
                });
            }
            if (results.queryContext) {
                dispatch(setQueryContext(results.queryContext, searchTypes.all));
            }

            if (results.images) {
                dispatch({
                    type: WIDGET_IMAGES_FETCHED,
                    data: results.images,
                });
            }
            if (results.videos) {
                dispatch({
                    type: WIDGET_VIDEOS_FETCHED,
                    data: results.videos,
                });
            }
            if (results.news) {
                dispatch({
                    type: WIDGET_NEWS_FETCHED,
                    data: results.news,
                });
            }
            if (results.ads && (!params.offset || !isMobileButNotIPad)) {
                dispatch({
                    type: ADS_FETCHED,
                    data: results.adsYahoo,
                });
            }
            if (results.adsYahoo) {
                dispatch({
                    type: SET_YAHOO_ADS,
                    data: results.adsYahoo.value
                });
            }
            if (results.adsContext) {
                dispatch({
                    type: SET_ADS_CONTEXT,
                    data: results.adsContext
                });
            }

            if (results.computation) {
                dispatch({
                    type: INSTANT_ANSWER_COMPUTATION_FETCHED,
                    data: results.computation,
                });
            }

            if (results.translations) {
                dispatch({
                    type: INSTANT_ANSWER_TRANSLATION_FETCHED,
                    data: results.translations,
                });
            }

            if (results.timeZone) {
                dispatch({
                    type: INSTANT_ANSWER_TIMEZONE_FETCHED,
                    data: results.timeZone,
                });
            }

            if (results.wikiEntity) {
                dispatch({
                    type: WIKIBOX_DATA_IS_EXPECTED,
                    data: results.wikiEntity,
                });
                wikiTimeout = setTimeout(() => {
                    dispatch({
                        type: WIKIBOX_DATA_IS_EXPECTED,
                        data: false,
                    });
                }, 5000);
            }

            if (results?.richHeader?.[0]?.entities?.[0].data) {
                const richHeaderData = results.richHeader;
                const richHeaderType = richHeaderData[0].entities[0].triggered.name;
                const richHeaderDataType =
                    richHeaderData[0]?.entities[0]?.metadata?.data_type ||
                    richHeaderData[0]?.entities[0]?.triggered?.data_type;

                if (richHeaderType === constants.richHeaderTypes.weather) {
                    dispatch({
                        type: WEATHER_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.soccer) {
                    dispatch({
                        type: FOOTBALL_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.basketball) {
                    dispatch({
                        type: BASKETBALL_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.cricket) {
                    dispatch({
                        type: CRICKET_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.stocks) {
                    dispatch({
                        type: STOCKS_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.currency) {
                    dispatch({
                        type: CURRENCY_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.location && !ignoreBusinessWidget) {
                    const isNearMe = richHeaderData[0]?.entities[0]?.data?.context?.nearme_loc;
                    const shouldRenderBusinessWidget = (isNearMe && locationSetting) || !isNearMe;
                    dispatch({
                        type: BUSINESS_WIDGET_DATA_FETCHED,
                        data: {
                            data: richHeaderData,
                            shouldRenderWidget: shouldRenderBusinessWidget,
                        },
                    });
                } else if (
                    richHeaderType === constants.richHeaderTypes.timezone &&
                    richHeaderDataType === 'timezone_compare'
                ) {
                    dispatch({
                        type: TIMEZONE_WIDGET_FETCHED,
                        data: richHeaderData,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.wikihow) {
                    dispatch({
                        type: WIKIHOW_WIDGET_DATA_FETCHED,
                        data: richHeaderData,
                    });
                } else if (
                    richHeaderType === constants.richHeaderTypes.movies &&
                    richHeaderDataType === constants.widgetDataTypes.movie
                ) {
                    dispatch({
                        type: MOVIES_INFOCARD_FETCHED,
                        data: richHeaderData,
                    });
                } else if (
                    richHeaderType === constants.richHeaderTypes.movies &&
                    richHeaderDataType === constants.widgetDataTypes.collection
                ) {
                    dispatch({
                        type: MOVIES_COLLECTION_INFOCARD_FETCHED,
                        data: richHeaderData,
                    });
                }
                dispatch({
                    type: SET_WIDGET_TYPE,
                    data: richHeaderType,
                });
                dispatch({
                    type: SET_RICHHEADER_LOAD_STATUS,
                    data: loadStatuses.completed,
                });
                if (richHeaderDataType) {
                    dispatch({
                        type: SET_WIDGET_DATA_TYPE,
                        data: richHeaderDataType,
                    });
                }
                clearTimeout(timeout);
                dispatch(
                    submitMetrics({
                        Category: 'infocards',
                        Action: 'trigger',
                        Object: richHeaderType,
                        Value: 'instant',
                        Extras: ['multiple', 'single'].includes(richHeaderDataType) ? richHeaderDataType : null,
                    }),
                );
            }
            if (!ignoreBusinessWidget && results.richHeader?.[0]?.entities?.[0]?.expected?.data_probability > 0) {
                dispatch({
                    type: EXPECTED_ENTITIES_FETCHED,
                    data: results.richHeader?.[0]?.entities,
                });
                timeout = setTimeout(() => {
                    dispatch({
                        type: SET_RICHHEADER_LOAD_STATUS,
                        data: loadStatuses.idle,
                    });
                    const richHeaderType = results.richHeader?.[0].entities[0].triggered.name;
                    dispatch(
                        submitMetrics({
                            Category: 'infocards',
                            Action: 'trigger',
                            Object: richHeaderType,
                            Value: 'timeout',
                        }),
                    );
                }, 6000);
            }

            if (encryptionSetting) {
                delete sendParams.q;
                sendParams.encq = encryptedQuery.encq;
                sendParams.encv = encryptedQuery.encv;
                sendParams.encha = 'sha1';
            } else {
                sendParams.q = getQueryFromResponse;
            }

            dispatch(saveFormInput(getQueryFromResponse, 'search-searchRequest'));
            dispatch(change('search_input', 'search_field', getQueryFromResponse));
            dispatch(setQuerySaved(getQueryFromResponse));

            if (sendParams.offset === 0 || isMobileButNotIPad) {
                delete sendParams.offset;
            }
            delete sendParams.count;
            delete sendParams.mkt;
            delete sendParams.safe_search;
            delete sendParams.source;
            delete sendParams.rh;
            delete sendParams.wb;
            delete sendParams.wbvers;
            delete sendParams.trafficType;
            delete sendParams.form;
            delete sendParams.lang;
            delete sendParams.modal;
            const queryString = toQueryString(sendParams);
            if (
                type !== searchTypes.map &&
                (!pop ||
                    (encryptionSetting && history.location.key === undefined) ||
                    encryptionSetting !== shouldEncrypt) &&
                (!params.offset || params.offset === 0 || !isMobileButNotIPad)
            ) {
                dispatch(
                    historyPush(
                        {
                            pathname: '/search',
                            search: queryString,
                        },
                        'search.js :729',
                    ),
                );
            }
            dispatch(setDataFetching(false));
        };

        const onRichHeader = (event) => {
            const responseTime = Math.round(performance.now() - time);
            if (logger) {
                sourceEventLogger(event);
            }
            dispatch(
                submitMetrics({
                    Category: 'serp',
                    Action: 'measure',
                    Object: 'loadTime',
                    Value: responseTime,
                    Extras: 'infocard',
                }),
            );
            const results = JSON.parse(event.data);
            const richHeaderType = results?.[0]?.entities[0].triggered.name;
            const richHeaderDataType =
                results?.[0]?.entities[0]?.data?.context?.data_type ||
                results?.[0]?.entities[0]?.metadata?.data_type ||
                results?.[0]?.entities[0]?.triggered?.data_type;

            if (results?.length > 0) {
                if (richHeaderType === constants.richHeaderTypes.weather) {
                    dispatch({
                        type: WEATHER_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.soccer) {
                    dispatch({
                        type: FOOTBALL_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.basketball) {
                    dispatch({
                        type: BASKETBALL_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.cricket) {
                    dispatch({
                        type: CRICKET_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.stocks) {
                    dispatch({
                        type: STOCKS_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.currency) {
                    dispatch({
                        type: CURRENCY_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.location && !ignoreBusinessWidget) {
                    const isNearMe = results[0]?.entities[0]?.data?.context?.nearme_loc;
                    const shouldRenderWidget = (isNearMe && locationSetting) || !isNearMe;
                    dispatch({
                        type: BUSINESS_WIDGET_DATA_FETCHED,
                        data: {
                            data: results,
                            shouldRenderWidget: shouldRenderWidget,
                        },
                    });
                } else if (
                    richHeaderType === constants.richHeaderTypes.movies &&
                    richHeaderDataType === constants.widgetDataTypes.movie
                ) {
                    dispatch({
                        type: MOVIES_INFOCARD_FETCHED,
                        data: results,
                    });
                } else if (
                    richHeaderType === constants.richHeaderTypes.movies &&
                    richHeaderDataType === constants.widgetDataTypes.collection
                ) {
                    dispatch({
                        type: MOVIES_COLLECTION_INFOCARD_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.timezone) {
                    dispatch({
                        type: TIMEZONE_WIDGET_FETCHED,
                        data: results,
                    });
                } else if (richHeaderType === constants.richHeaderTypes.wikihow) {
                    dispatch({
                        type: WIKIHOW_WIDGET_DATA_FETCHED,
                        data: results,
                    });
                }
                dispatch({
                    type: SET_WIDGET_TYPE,
                    data: richHeaderType,
                });
                if (richHeaderDataType) {
                    dispatch({
                        type: SET_WIDGET_DATA_TYPE,
                        data: richHeaderDataType,
                    });
                }
                clearTimeout(timeout);
                dispatch({
                    type: SET_RICHHEADER_LOAD_STATUS,
                    data: loadStatuses.completed,
                });
                dispatch(
                    submitMetrics({
                        Category: 'infocards',
                        Action: 'trigger',
                        Object: richHeaderType,
                        Value: 'delayed',
                        Extras: ['multiple', 'single'].includes(richHeaderDataType) ? richHeaderDataType : null,
                    }),
                );
            } else {
                dispatch({
                    type: SET_RICHHEADER_LOAD_STATUS,
                    data: loadStatuses.idle,
                });
                dispatch(
                    submitMetrics({
                        Category: 'infocards',
                        Action: 'trigger',
                        Object: richHeaderType,
                        Value: 'timeout',
                        Extras: ['multiple', 'single'].includes(richHeaderDataType) ? richHeaderDataType : null,
                    }),
                );
            }
        };

        const onWikiEntity = (event) => {
            const responseTime = Math.round(performance.now() - time);
            if (logger) {
                sourceEventLogger(event);
            }
            clearTimeout(wikiTimeout);
            if (event.data && JSON.parse(event.data).length > 0) {
                dispatch({
                    type: WIKIBOX_DATA_FETCHED,
                    data: JSON.parse(event.data)[0],
                });
            }
            dispatch(
                submitMetrics({
                    Category: 'serp',
                    Action: 'measure',
                    Object: 'loadTime',
                    Value: responseTime,
                    Extras: 'wikibox',
                }),
            );
        };

        const onError = (error) => {
            if (logger) {
                console.log('Connection error', error);
            }
            if (error?.message && error.message.indexOf('fetch') !== -1) {
                dispatch(setErrorMessageType(searchErrors.connectionLost));
            } else {
                dispatch(setErrorMessageType(searchErrors.genericError));
            }
            dispatch(setDataFetching(false));
            throw error; // rethrow to stop the operation
        };

        const onOpen = (response) => {
            dispatch(setDataFetching(false));
            if (!response?.status || response?.status === 200) {
                return;
            }
            if (logger) {
                console.log('Error code', response.status, response);
            }
            let errorType;
            if (response.status === 401 || response.status === 403) {
                errorType = searchErrors.linkExpired;
            } else if (response.status === 500) {
                errorType = searchErrors.internalServerError;
            } else if (response.status === 404) {
                errorType = searchErrors.resourceNotFound;
            } else {
                errorType = searchErrors.genericError;
            }
            if (!!errorType) {
                dispatch(setErrorMessageType(errorType));
            }
        };

        const postDetails = {};
        if (extraParams && extraParams.postPayload) {
            postDetails.method = 'POST';
            postDetails.body = JSON.stringify(extraParams.postPayload);
        } else if (searchExtraParams && searchExtraParams.postPayload) {
            postDetails.method = 'POST';
            postDetails.body = JSON.stringify(searchExtraParams.postPayload);
            dispatch({
                type: SET_SEARCH_EXTRA_PARAMS,
                data: null,
            });
        }

        fetchEventSource(`${apiBase}${paths.searchPathV2}${queryString}`, {
            headers: apiHeaders,
            onmessage(msg) {
                switch (msg.event) {
                    case 'bingSearch':
                        return onBingSearch(msg);
                    case 'richHeader':
                        return onRichHeader(msg);
                    case 'wikiEntity':
                        return onWikiEntity(msg);
                    default: // Uncomment next line see the unhandled events.
                    	sourceEventLogger(msg);
                }
            },
            onerror: onError,
            onopen: onOpen,
            ...postDetails,
        }).then();
    };
}

export function getMoreBusinesses(useUsersCoord, forWidget) {
    return (dispatch, getState) => {
        const state = getState();
        const { locationSetting } = state.app;
        const {
            savedLocationForBusinessWidget,
            searchLocationInfo,
            businessWidgetContext
        } = state.search;
        const context = businessWidgetContext;
        const queryParams = {
            term: context.term,
            category: context.category,
            locale: context?.locale,
            n: forWidget ? 3 : 50,
            near_search: context.near_search,
        };
        const location = context?.location;
        const headers = {};
        let lat,
            long;
        if (context?.location.name && !useUsersCoord) {
            queryParams.location = location.name;
        } else {
            queryParams.latitude = location.latitude;
            queryParams.longitude = location.longitude;
            if (
                locationSetting && context.nearme_loc && (
                    (savedLocationForBusinessWidget?.Latitude && savedLocationForBusinessWidget?.Longitude) ||
                    useUsersCoord
                )
            ) {
                queryParams.latitude = useUsersCoord?.latitude
                    ? useUsersCoord.latitude
                    : savedLocationForBusinessWidget?.Latitude;
                queryParams.longitude = useUsersCoord?.longitude
                    ? useUsersCoord.longitude
                    : savedLocationForBusinessWidget?.Longitude;
            }
        }
        if (!queryParams.location && !queryParams.latitude && !queryParams.latitude) {
            return;
        }
        if (!queryParams.term && !queryParams.category) {
            return;
        }

        if (savedLocationForBusinessWidget?.Latitude && savedLocationForBusinessWidget?.Longitude) {
            lat = savedLocationForBusinessWidget.Latitude;
            long = savedLocationForBusinessWidget.Longitude;
        } else if (searchLocationInfo) {
            lat = searchLocationInfo.Latitude;
            long = searchLocationInfo.Longitude;
        }

        if (locationSetting && lat && long) {
            headers['X-Search-Location'] = formatLocation(lat, long);
        }

        return axios()({
            method: 'get',
            url: `${paths.searchBusinesses}`,
            params: queryParams,
            headers: headers,
        }).then((response) => {
            dispatch({
                type: GET_MORE_BUSINESSES,
                data: {
                    params: queryParams,
                    businesses: response.data.data.businesses,
                    forWidget: forWidget,
                },
            });
        });
    };
}

export function setActiveCardFromWidget(index) {
    return (dispatch) => {
        dispatch({
            type: SET_MAP_ACTIVE_PIN,
            data: index,
        });
    };
}

export function setDetailedMapQuery(query) {
    return (dispatch) => {
        dispatch({
            type: SET_DETAILED_MAP_QUERY,
            data: query,
        });
    };
}

export function setIsMapTab(type) {
    return (dispatch) => {
        dispatch({
            type: SET_IS_MAP_TAB,
            data: type,
        });
    };
}

export function setErrorMessageType(errorType) {
    return (dispatch) => {
        dispatch({
            type: SHOW_ERROR_MESSAGE,
            data: errorType,
        });
    };
}

export function setLocation(query) {
    return (dispatch, getState) => {
        const state = getState();
        const { searchLocation, searchLocationInfo } = state.search;
        const { locationSetting } = state.app;
        const getLocationFromLS = storageAvailable('localStorage') && JSON.parse(localStorage.getItem('mkt'));
        const regionsToReplace = ['ru-RU', 'zh-CN', 'ja-JP', 'ko-KR', 'pl-PL', 'en-ZA', 'tr-TR'];
        if (getLocationFromLS && getLocationFromLS.mcode && regionsToReplace.includes(getLocationFromLS.mcode)) {
            getLocationFromLS.mcode = '00';
            getLocationFromLS.code = '00';
            getLocationFromLS.name = 'All Regions';
            getLocationFromLS.value = 'All Regions';
        }
        const getLocationFromQuery = query?.mkt;

        dispatch(readSavedLocationForBusinessWidgetFromLS());

        if (!searchLocationInfo) {
            dispatch(readSearchLocationInfoFromLS());
        }
        if (!getLocationFromLS && !getLocationFromQuery && !searchLocationInfo && (!searchLocation || searchLocation?.code === '00')) {
            dispatch(getLocation());
        } else if (
            locationSetting &&
            !getFromLocalStorage('locationSetManually') &&
            !getFromLocalStorage('locationSetFromBrowser')
        ) {
            dispatch(getLocation());
        }

        if (getLocationFromQuery && !getLocationFromLS) {
            dispatch(changeLocation(query.mkt));
        }

        if (!getLocationFromQuery && getLocationFromLS && getLocationFromLS.value) {
            dispatch(changeLocation(getLocationFromLS));
        }
    };
}

// Redundant action used for loading additional data for the WikiBox
export function getEntityInfoBox(url) {
    return (dispatch, getState) => {
        return axios()({
            method: 'get',
            url: `${paths.enittyInfoBoxPath}${url}`,
        })
            .then((response) => {
                dispatch({
                    type: WIKIBOX_INFO_BOX_DATA_FETCHED,
                    data: response.data,
                });
            })
            .catch((e) => {
                if (e?.response?.status === 404) {
                    if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
                        console.clear();
                    }
                    dispatch({
                        type: WIKIBOX_INFO_BOX_DATA_NOT_FOUND,
                        data: true,
                    });
                }
            });
    };
}

export function setupENVs() {
    return (dispatch, getState) => {
        dispatch(changeSourceAPI(process.env.REACT_APP_SOURCEAPI));
		dispatch(changeBetaStatus(process.env.REACT_APP_OPENBETA));
		saveToLocalStorage(null, 'cors', process.env.REACT_APP_CORS_KEY);
    }
}

export function readQueryFromURL(location, src) {
    // console.log('Read URL called from the ', src, history)
    return (dispatch, getState) => {
        const url = location ? location : history.location;
        const query = queryStringHelper.parse(url.search);
        if (!navigator.onLine) {
            window.location.reload(true);
        }
        dispatch({
            type: READ_QUERY_FROM_URL,
            data: query,
        });
        dispatch(setLocation(query));
        if (query && url.search) {
            if (query.type) {
                dispatch(changeSearchType(query.type, 'search, read query from url'));
            }
            if (query.freshness) {
                dispatch(changeFilterByTime(query.freshness));
            }
            if (query.color) {
                dispatch(changeFilterByColor(query.color));
            }
            if (query.size) {
                dispatch(changeFilterByImageSize(query.size));
            }
            if (query.image_type) {
                dispatch(changeFilterByImageType(query.image_type));
            }
            if (query.aspect) {
                dispatch(changeFilterByImageAspectRatio(query.aspect));
            }
            if (query.license) {
                dispatch(changeFilterByImageLicense(query.license));
            }
            if (query.video_length) {
                dispatch(changeFilterByVideoLength(query.video_length));
            }
            if (query.pt) {
                dispatch(changePT(query.pt));
            }
            if (query.setparam) {
                dispatch(changeParam(query.setparam));
            }
            if (query.sourceapi) {
                dispatch(changeSourceAPI(query.sourceapi));
                dispatch(historyPush('/'));
            }
            if (query.openbeta) {
                dispatch(changeBetaStatus(query.openbeta));
                dispatch(historyPush('/'));
            }
            if (query.closebeta || query.closebeta === null) {
                dispatch(changeBetaStatus(null));
                dispatch(historyPush('/'));
            }
            if (query.stk) {
                dispatch(changeBetaStatus(query.stk));
            }
            if (query.set) {
                if (query.set === constants.searchSourceTypes.tcat) {
                    saveToLocalStorage(null, 'source', constants.searchSourceTypes.tcat);
                } else if (query.set === constants.searchSourceTypes.msft) {
                    removeFromLocalStorage('source');
                }
            }
            if (query.mapSearchType) {
                dispatch(setMapSearchType(query.mapSearchType));
                dispatch(
                    saveBusinessContext({
                        term: query.term,
                        category: query.category,
                        provider: query.mapSearchType,
                        // TODO: Find a better solution for storing boolean values in url
                        nearme_loc: query.nearMe === 'true',
                        location: {
                            latitude: query.lat,
                            longitude: query.long,
                            name: query.name,
                        },
                        locale: query.locale,
                    }),
                );
            }
            if (query.cors) {
                saveToLocalStorage(null, 'cors', query.cors);
                dispatch(historyPush('/'));
            }
            if (query.ismaptab && query.ismaptab === 'true') {
                dispatch(setIsMapTab(true));
            }
            if (query.movie) {
                dispatch({
                    type: SET_SEARCH_EXTRA_PARAMS,
                    data: {
                        postPayload: {
                            infocard: 'movie',
                            movie_type: 'movie',
                            movie_id: parseInt(query.movie),
                        },
                    },
                });
            }
        }
        if (!url.search && url.pathname === '/search') {
            dispatch(historyPush('/'));
        }
    };
}

function saveBusinessContext(data) {
    return (dispatch) => {
        dispatch({
            type: SAVE_BUSINESS_CONTEXT,
            data: data,
        });
    };
}

export function readSearchLocationInfoFromLS() {
    return (dispatch) => {
        dispatch({
            type: READ_SEARCH_LOCATION_INFO,
            data: storageAvailable('localStorage') && safeJSONParse(localStorage.getItem('searchLocationInfo')),
        });
    };
}

export function readSavedLocationForBusinessWidgetFromLS() {
    return (dispatch) => {
        dispatch({
            type: READ_SAVED_LOCATION_FOR_WIDGET,
            data:
                storageAvailable('localStorage') &&
                safeJSONParse(localStorage.getItem('savedLocationForBusinessWidget')),
        });
    };
}

export function getLocation() {
    return (dispatch) => {
        return fetchJsonp(`${paths.getLocationPath}`)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                dispatch({
                    type: GET_LOCATION,
                    data: response,
                });
                return response;
            })
            .catch((e) => console.log(e));
    };
}

export function setGeolocation(longitude, latitude, placeName = null) {
    return (dispatch) => {
        dispatch({
            type: SET_GEOLOCATION,
            data: { longitude, latitude, placeName },
        });
    };
}

export const getLocationWebsite = (alias) => {
    return new Promise((resolve, reject) => {
        return axios()({
            method: 'get',
            url: paths.locationWebsite,
            params: {
                alias: alias,
            },
        })
            .then((response) => {
                resolve(response.data.website);
            })
            .catch((e) => {
                console.log(e);
            });
    });
};

export function getLocationSuggestions(query) {
    return new Promise((resolve, reject) => {
        const params = {
            access_token: MAPBOX_TOKEN,
            autocomplete: true,
            types: 'place',
            limit: 6,
        };
        return axios()({
            method: 'get',
            url: paths.locationQuery.replace('{searchQuery}', query),
            params,
        })
            .then((response) => {
                resolve(
                    response.data.features.map((feature) => ({
                        label: formatLocationPopupOptionsLabel(feature.place_name),
                        name: feature.place_name,
                        value: feature.id,
                        latitude: feature?.center[1],
                        longitude: feature?.center[0],
                    })),
                );
            })
            .catch((e) => {
                console.log(e);
            });
    });
}

export function getSuggestions(query) {
    return async (dispatch, getState) => {
        let { encryptionSetting } = getState().app;
        let {
            publicKey,
            publicKeyExpirationTime,
            publicKeyVersion,
            publicKeyDomain,
            searchLocation,
            searchLocationInfo,
            suggestions,
            suggestionsQuery,
            savedLocationForBusinessWidget,
        } = getState().search;

        let encryptedQuery;
        let headers = {};

        const { locationSetting } = getState().app;
        if (locationSetting) {
            let location =
                savedLocationForBusinessWidget?.Latitude && savedLocationForBusinessWidget?.Longitude
                    ? savedLocationForBusinessWidget
                    : searchLocationInfo;
            if (!location) {
                location = await dispatch(getLocation());
            }
            headers['X-Search-Location'] = formatLocation(location.Latitude, location.Longitude);
        } else {
            if (headers['X-Search-Location']) {
                delete headers['X-Search-Location'];
            }
        }

        const sendParams = {
            q: query,
        };

        const getMcode = searchLocation.mcode ? searchLocation.mcode : searchLocation.value;
        if (getMcode !== countries.list[0].mcode) {
            sendParams.mkt = getMcode;
        }

        if (encryptionSetting && !validatePublicKey(publicKey, publicKeyExpirationTime, publicKeyDomain)) {
            await dispatch(getPublicKey());
            publicKey = getState().search.publicKey;
            publicKeyVersion = getState().search.publicKeyVersion;
        }

        const withLimitedQueryStringParams = getLimitedQueryString({
            sendParams,
        });

        if (encryptionSetting) {
            encryptedQuery = encrypt(withLimitedQueryStringParams, publicKey, publicKeyVersion, 'Suggestions');
        }
        if (
            (suggestions?.length > 0 && suggestionsQuery === query) ||
            suggestionsQuery === withLimitedQueryStringParams?.q
        ) {
            return
        }
        return axios(disableHTTPHeaders)({
            method: 'get',
            url: `${paths.suggestionsPathV2}${
                encryptedQuery ? toQueryString(encryptedQuery) : toQueryString(sendParams)
            }`,
            headers: disableHTTPHeaders ? {} : headers,
        })
            .then((response) => {
                dispatch({
                    type: GET_SUGGESTIONS,
                    data: response.data,
                });
            })
            .catch((e) => console.log(e));
    };
}

export function clearSuggestions(query) {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SUGGESTIONS,
        });
    };
}

export function resetReadQueryFromURL() {
    return (dispatch) => {
        dispatch({
            type: READ_QUERY_FROM_URL,
            data: { q: '' },
        });
    };
}

export function openWidgetImageInImagesTab(thumbnailId) {
    return (dispatch) => {
        dispatch({
            type: OPEN_WIDGET_IMAGE_IN_IMAGES_TAB,
            data: thumbnailId,
        });
    };
}

export function searchImages(
    query,
    params,
    headers,
    pop,
    publicKeyVersion,
    publicKey,
    shouldEncrypt,
    isEncryptedQueryFromURL,
    relatedImages,
    seeMoreSearch,
) {
    let encryptedQuery;
    let sendParams = { ...params };
    sendParams.q = query;
    if (isEncryptedQueryFromURL) {
        encryptedQuery = {
            encq: query.encq,
            encv: query.encv,
            encha: 'sha1',
        };
    }
    const withLimitedQueryStringParams = getLimitedQueryString({
        sendParams,
    });
    if (shouldEncrypt && !isEncryptedQueryFromURL && publicKey) {
        encryptedQuery = encrypt(withLimitedQueryStringParams, publicKey, publicKeyVersion, 'Images');
    }

    return (dispatch, getState) => {
        const time = performance.now();
        const axiosInstance = axios();
        const { encryptionSetting } = getState().app;
        const { pt, additionalParams } = getState().search;
        axiosRetry(axiosInstance, {
            retries: 5,
            retryDelay: axiosRetry.exponentialDelay,
        });
        const paramBuf = shouldEncrypt ? encryptedQuery : sendParams;
        const sanitizedAdditionalParams = getSanitizedAdditionalParams(additionalParams);
        dispatch(setDataFetching(true));

        return axiosInstance({
            method: 'get',
            url: `${paths.imagePath}`,
            params: pt
                ? { ...sanitizedAdditionalParams, ...paramBuf, pt }
                : { ...sanitizedAdditionalParams, ...paramBuf },
            headers: headers,
        })
            .then((response) => {
                const responseTime = (performance.now() - time) / 1000;
                const getQueryFromResponse = response?.data?.queryContext?.originalQuery;
                sendParams.type = searchTypes.images;
                if (encryptionSetting) {
                    delete sendParams.q;
                    sendParams.encq = encryptedQuery.encq;
                    sendParams.encv = encryptedQuery.encv;
                    sendParams.encha = 'sha1';
                } else if (!!getQueryFromResponse) {
                    sendParams.q = getQueryFromResponse;
                }
                if (!relatedImages && !!getQueryFromResponse) {
                    dispatch(saveFormInput(getQueryFromResponse));
                    dispatch(change('search_input', 'search_field', getQueryFromResponse));
                    dispatch(setQuerySaved(getQueryFromResponse));
                }
                delete sendParams.mkt;
                delete sendParams.safe_search;
                delete sendParams.count;

                const queryString = toQueryString(sendParams);
                if (
                    ((!pop && !sendParams.offset) ||
                        (shouldEncrypt && history.location.key === undefined) ||
                        encryptionSetting !== shouldEncrypt) &&
                    !relatedImages
                ) {
                    dispatch(
                        historyPush(
                            {
                                search: queryString,
                            },
                            'search.js :1511',
                        ),
                    );
                }
                if (query && !isEncryptedQueryFromURL && !relatedImages) {
                    dispatch(setQuerySaved(query));
                }
                dispatch({
                    type: SET_RESPONSE_TIME,
                    data: responseTime,
                });
                dispatch(
                    submitMetrics({
                        Category: 'serp',
                        Action: 'measure',
                        Object: 'loadTime',
                        Value: Math.round(responseTime * 1000),
                        Extras: 'search',
                    }),
                );
                if (response.data.queryContext && !relatedImages && !seeMoreSearch) {
                    dispatch(setQueryContext(response.data.queryContext, searchTypes.images));
                }
                if (!relatedImages) {
                    dispatch({
                        type: IMAGES_FETCHED,
                        data: response.data,
                    });
                } else if (relatedImages) {
                    dispatch({
                        type: RELATED_IMAGES_FETCHED,
                        data: response.data,
                    });
                }
                dispatch(
                    submitMetrics({
                        Category: 'serp',
                        Action: 'measure',
                        Object: 'loadTime',
                        Value: Math.round(responseTime * 1000),
                        Extras: 'search',
                    }),
                );
                dispatch(setDataFetching(false));
            })
            .catch((e) => {
                dispatch(setDataFetching(false));
                if (e.message.indexOf('Network') !== -1) {
                    dispatch(setErrorMessageType(searchErrors.connectionLost));
                } else if (e.message.indexOf('timeout') !== -1) {
                    dispatch(setErrorMessageType(searchErrors.connectionTimeout));
                }
            });
    };
}

export function setHistory(type, params, query, src) {
    return (dispatch) => {
        //console.log('setHistory is called from: ', src);
        if (type !== searchTypes.all) {
            params.type = type;
        } else {
            delete params?.type;
        }
        params.q = query;
        delete params?.mkt;
        delete params?.safe_search;
        delete params?.source;
        delete params?.count;
        const queryString = toQueryString(params);
        dispatch(
            historyPush(
                {
                    pathname: '/search',
                    search: queryString,
                },
                'search.js :1598',
            ),
        );
    };
}

export function searchVideos(
    query,
    params,
    headers,
    pop,
    publicKeyVersion,
    publicKey,
    shouldEncrypt,
    isEncryptedQueryFromURL,
) {
    let encryptedQuery;
    let sendParams = { ...params };
    sendParams.q = query;
    const withLimitedQueryStringParams = getLimitedQueryString({
        sendParams,
    });
    if (shouldEncrypt && !isEncryptedQueryFromURL && publicKey) {
        encryptedQuery = encrypt(withLimitedQueryStringParams, publicKey, publicKeyVersion, 'Videos');
    }
    if (isEncryptedQueryFromURL) {
        encryptedQuery = {
            encq: query.encq,
            encv: query.encv,
            encha: 'sha1',
        };
    }
    return (dispatch, getState) => {
        const time = performance.now();
        const { encryptionSetting } = getState().app;
        const { pt, additionalParams } = getState().search;
        const sanitizedAdditionalParams = getSanitizedAdditionalParams(additionalParams);
        const paramBuf = shouldEncrypt ? encryptedQuery : sendParams;
        dispatch(setDataFetching(true));
        return axios()({
            method: 'get',
            url: `${paths.videoPath}`,
            params: pt
                ? { ...sanitizedAdditionalParams, ...paramBuf, pt }
                : { ...sanitizedAdditionalParams, ...paramBuf },
            headers,
        })
            .then((response) => {
                const getQueryFromResponse = response.data.queryContext.originalQuery;
                const responseTime = (performance.now() - time) / 1000;
                dispatch({
                    type: SET_RESPONSE_TIME,
                    data: responseTime,
                });
                dispatch(
                    submitMetrics({
                        Category: 'serp',
                        Action: 'measure',
                        Object: 'loadTime',
                        Value: Math.round(responseTime * 1000),
                        Extras: 'search',
                    }),
                );
                if (response.data.queryContext) {
                    dispatch(setQueryContext(response.data.queryContext, searchTypes.videos));
                }
                if (encryptionSetting) {
                    delete sendParams.q;
                    sendParams.encq = encryptedQuery.encq;
                    sendParams.encv = encryptedQuery.encv;
                    sendParams.encha = 'sha1';
                } else {
                    sendParams.q = getQueryFromResponse;
                }

                dispatch(saveFormInput(getQueryFromResponse));
                dispatch(change('search_input', 'search_field', getQueryFromResponse));
                dispatch(setQuerySaved(getQueryFromResponse));

                sendParams.type = searchTypes.videos;
                delete sendParams.mkt;
                delete sendParams.safe_search;
                delete sendParams.source;
                delete sendParams.count;
                delete sendParams.offset;

                const queryString = toQueryString(sendParams);
                if (
                    !pop ||
                    (shouldEncrypt && history.location.key === undefined) ||
                    encryptionSetting !== shouldEncrypt
                ) {
                    dispatch(
                        historyPush(
                            {
                                search: queryString,
                            },
                            'search.js :1711',
                        ),
                    );
                }
                if (query) {
                    dispatch(setQuerySaved(query));
                }
                dispatch({
                    type: VIDEOS_FETCHED,
                    data: response.data,
                });
                dispatch(setDataFetching(false));
            })
            .catch((e) => {
                dispatch(setDataFetching(false));
                if (e.message.indexOf('Network') !== -1) {
                    dispatch(setErrorMessageType(searchErrors.connectionLost));
                }
            });
    };
}

export function searchNews(
    query,
    params,
    headers,
    pop,
    isWidget,
    publicKeyVersion,
    publicKey,
    shouldEncrypt,
    isEncryptedQueryFromURL,
    mobile,
) {
    let encryptedQuery;
    let sendParams = { ...params };
    sendParams.q = query;

    const withLimitedQueryStringParams = getLimitedQueryString({
        sendParams,
    });

    if (shouldEncrypt && !isEncryptedQueryFromURL && publicKey) {
        encryptedQuery = encrypt(withLimitedQueryStringParams, publicKey, publicKeyVersion, 'News');
    }
    if (isEncryptedQueryFromURL) {
        encryptedQuery = {
            encq: query.encq,
            encv: query.encv,
            encha: 'sha1',
        };
    }
    return (dispatch, getState) => {
        const time = performance.now();
        const { encryptionSetting } = getState().app;
        const { pt, additionalParams } = getState().search;
        const sanitizedAdditionalParams = getSanitizedAdditionalParams(additionalParams);
        const paramBuf = shouldEncrypt ? encryptedQuery : sendParams;
        dispatch(setDataFetching(true));
        return axios()({
            method: 'get',
            url: `${paths.newsPath}`,
            params: pt
                ? { ...sanitizedAdditionalParams, ...paramBuf, pt }
                : { ...sanitizedAdditionalParams, ...paramBuf },
            headers,
        })
            .then((response) => {
                const getQueryFromResponse = response.data?.queryContext?.originalQuery;
                const responseTime = (performance.now() - time) / 1000;
                dispatch({
                    type: SET_RESPONSE_TIME,
                    data: responseTime,
                });
                dispatch(
                    submitMetrics({
                        Category: 'serp',
                        Action: 'measure',
                        Object: 'loadTime',
                        Value: Math.round(responseTime * 1000),
                        Extras: 'search',
                    }),
                );
                sendParams.type = searchTypes.news;
                if (encryptionSetting) {
                    delete sendParams.q;
                    sendParams.encq = encryptedQuery.encq;
                    sendParams.encv = encryptedQuery.encv;
                    sendParams.encha = 'sha1';
                } else {
                    sendParams.q = getQueryFromResponse;
                }

                dispatch(change('search_input', 'search_field', getQueryFromResponse));
                dispatch(saveFormInput(getQueryFromResponse));
                dispatch(setQuerySaved(getQueryFromResponse));

                if (isWidget) {
                    delete sendParams.type;
                } else if (!isWidget) {
                    if (response.data?.queryContext) {
                        dispatch(setQueryContext(response.data.queryContext, searchTypes.news));
                    }
                }
                delete sendParams.mkt;
                delete sendParams.safe_search;
                delete sendParams.count;
                const queryString = toQueryString(sendParams);
                if (
                    (!pop ||
                        (encryptionSetting && history.location.key === undefined) ||
                        encryptionSetting !== shouldEncrypt) &&
                    (!params.offset || params.offset === 0 || !isMobileButNotIPad) &&
                    !isWidget
                ) {
                    dispatch(
                        historyPush(
                            {
                                search: queryString,
                            },
                            'search.js :1849',
                        ),
                    );
                }
                if (query) {
                    dispatch(setQuerySaved(query));
                }
                dispatch({
                    type: !!isWidget ? WIDGET_NEWS_FETCHED : mobile ? NEWS_FETCHED_MOBILE : NEWS_FETCHED,
                    data: response.data,
                });
                dispatch(setMobileNewsAreLoading(false));
                dispatch(setDataFetching(false));
            })
            .catch((e) => {
                dispatch(setMobileNewsAreLoading(false));
                dispatch(setDataFetching(false));
                if (e.message.indexOf('Network') !== -1) {
                    dispatch(setErrorMessageType(searchErrors.connectionLost));
                }
            });
    };
}

const isSameQuery = (currentFilter, previousFilter, searchType, ignoreOffset = false, ignoreTime = false) => {
    const {
        recentQuery,
        recentFilterByTime,
        recentFilterByImageSize,
        recentFilterByImageColor,
        recentFilterByImageType,
        recentFilterByImageAspectRatio,
        recentFilterByImageLicense,
        recentFilterByVideoLength,
        recentFilterByVideoResolution,
        recentSearchLocation,
        recentOffset,
        recentLatitude,
        recentLongitude,
    } = previousFilter;
    const {
        query,
        filterByTime,
        filterByImageSize,
        filterByImageColor,
        filterByImageType,
        filterByImageAspectRatio,
        filterByImageLicense,
        filterByVideoLength,
        filterByVideoResolution,
        searchLocation,
        offset,
        latitude,
        longitude,
    } = currentFilter;
    let sameQuery =
        query === recentQuery &&
        (ignoreTime || (recentFilterByTime && recentFilterByTime === filterByTime)) &&
        recentSearchLocation &&
        (recentSearchLocation.mcode === searchLocation.mcode || recentSearchLocation.value === searchLocation.value) &&
        (ignoreOffset || (!offset && !recentOffset) || (recentOffset && recentOffset === offset));
    if (searchType === searchTypes.images && sameQuery) {
        return (
            (!recentFilterByImageSize || recentFilterByImageSize === filterByImageSize) &&
            (!recentFilterByImageColor || recentFilterByImageColor === filterByImageColor) &&
            (!recentFilterByImageType || recentFilterByImageType === filterByImageType) &&
            (!recentFilterByImageAspectRatio || recentFilterByImageAspectRatio === filterByImageAspectRatio) &&
            (!recentFilterByImageLicense || recentFilterByImageLicense === filterByImageLicense) &&
            (ignoreOffset || !offset)
        );
    }
    if (searchType === searchTypes.videos && sameQuery) {
        return (
            (!recentFilterByVideoLength || recentFilterByVideoLength === filterByVideoLength) &&
            (!recentFilterByVideoResolution || recentFilterByVideoResolution === filterByVideoResolution)
        );
    }
    if ((searchType === searchTypes.all || searchType === searchTypes.home) && sameQuery) {
        return (!recentLatitude || recentLatitude === latitude) && (!recentLongitude || recentLongitude === longitude);
    }
    return sameQuery;
};

export function runSearch({
   query,
   offset,
   isEncryptedQueryFromURL,
   relatedSearch,
   seeMoreSearch,
   extraParams,
}) {
    return async (dispatch, getState) => {
        const {
            filterByTime,
            filterByImageSize,
            filterByImageColor,
            filterByImageType,
            filterByImageAspectRatio,
            filterByImageLicense,
            filterByVideoLength,
            filterByVideoResolution,
            searchLocation,
            searchLocationInfo,
            savedFormInput,
            searchPageNumber,
            entities,
            showErrorMessage,
            withSharedLocation,
            webPages,
            news,
            images,
            videos,
            recentQueryFilters,
            searchResultCacheDisabled,
            savedLocationForBusinessWidget,
            businessWidgetContext,
        } = getState().search;
        let {
            publicKey,
            publicKeyExpirationTime,
            publicKeyVersion,
            publicKeyDomain,
            widgetType,
            widgetEntityType
        } = getState().search;

        const {
            safeSearchType,
            searchType,
            locationSetting,
            appLanguage,
            recentActionData,
        } =
            getState().app;
        let { encryptionSetting } = getState().app;

        const searchTypeIsAll = searchType === searchTypes.home || searchType === searchTypes.all;
        const mobile = (isMobile || isIOS || isAndroid || isMobileBasedOnOS()) && !(isTablet && isIOS);
        const isNewSearch = query !== savedFormInput || offset === 0 || !offset;
        let params = {};
        let headers = {};

        dispatch(toggleTimeToShowFooter(false));
        setTimeout(() => {
            dispatch(toggleTimeToShowFooter(true));
        }, 5000);

        const pop = recentActionData && recentActionData.action === 'POP';

        // Location sharing
        const isAskedUserForSharingLocation = getFromLocalStorage('hideLocationPopup');
        const sharedLocation =
            withSharedLocation === undefined && isAskedUserForSharingLocation && !locationSetting
                ? await getLocationSharingPermissions()
                : withSharedLocation;
        const locationInfo =
            savedLocationForBusinessWidget?.Latitude && savedLocationForBusinessWidget?.Longitude
                ? savedLocationForBusinessWidget
                : searchLocationInfo;

        // Set up filters
        const filterByCoordinates = (locationSetting || sharedLocation) && locationInfo;
        const currentFilter = {
            query,
            filterByTime,
            filterByImageSize,
            filterByImageColor,
            filterByImageType,
            filterByImageAspectRatio,
            filterByImageLicense,
            filterByVideoLength,
            filterByVideoResolution,
            searchLocation,
            offset,
            latitude: filterByCoordinates ? locationInfo.Latitude : undefined,
            longitude: filterByCoordinates ? locationInfo.Longitude : undefined,
        };
        const previousFilter = recentQueryFilters?.hasOwnProperty(searchType) ? recentQueryFilters[searchType] : {};

        // Detect same query for data-caching
        const sameQuery = !searchResultCacheDisabled && isSameQuery(currentFilter, previousFilter, searchType);
        const sameQueryIgnoreOffset =
            !searchResultCacheDisabled && isSameQuery(currentFilter, previousFilter, searchType, true);
        const sameQueryIgnoreTime =
            !searchResultCacheDisabled && isSameQuery(currentFilter, previousFilter, searchType, false, true);
        const sameSingleBusinessQuery =
            sameQueryIgnoreTime && widgetType === constants.richHeaderTypes.location && widgetEntityType === 'single';

        // Set encryptionSetting on if its Encrypted Query From URL
        if (isEncryptedQueryFromURL) {
            encryptionSetting = true;
        }

        // Get a new public key if the old one has expired
        if (
            !isEncryptedQueryFromURL &&
            encryptionSetting &&
            !validatePublicKey(publicKey, publicKeyExpirationTime, publicKeyDomain)
        ) {
            await dispatch(getPublicKey());
            publicKey = getState().search.publicKey;
            publicKeyVersion = getState().search.publicKeyVersion;
        }

        // Clear the state of the components
        if (isNewSearch && !sameQuery) {
            if (!!entities?.value) {
                dispatch(clearWikiBox());
            }
            if (searchTypeIsAll) {
                if (!mobile) {
                    dispatch(clearRankingResponse());
                }
                // don't clear single business widget if query didn't change
                // https://github.com/tempest-tech-ltd/tempest-search/issues/3352
                if (!sameSingleBusinessQuery) {
                    dispatch(clearState());
                }
            }
        }
        if (relatedSearch) {
            dispatch(clearRelatedImages());
        }
        if (showErrorMessage) {
            dispatch(setErrorMessageType(null));
        }
        if (!sameQueryIgnoreOffset && searchType === searchTypes.images && !relatedSearch) {
            dispatch(clearImages());
        }
        if (!sameQueryIgnoreOffset && searchType === searchTypes.videos) {
            dispatch(clearVideos('runSearch'));
        }

        // Set up search params
        params.lang = appLanguage;
        // !offset means that its a first page
        if (searchType !== searchTypes.images && searchType !== searchTypes.videos) {
            if (!relatedSearch) {
                if (!offset) {
                    params.count = 15;
                } else {
                    params.count = 10;
                }
            }
        } else if (searchType === searchTypes.images) {
            params.count = offset > 256 ? 64 : 256;
        } else if (searchType === searchTypes.videos) {
            params.count = 64;
        }

        if ((offset || offset === 0) && !relatedSearch) {
            if (offset > 0) {
                params.offset = parseInt(offset);
            }

            const page = (parseInt(offset) + 10) / 10;

            if (!searchPageNumber || page !== searchPageNumber) {
                dispatch(setSearchPageNumber(page, 'action-MakeSearch'));
            }
        } else if (!!relatedSearch) {
            params.count = 12;
        } else if (!offset) {
            dispatch(setSearchPageNumber(1, 'action-MakeSearch'));
        }

        if (storageAvailable('localStorage') && localStorage.getItem('source') === constants.searchSourceTypes.tcat) {
            params.source = constants.searchSourceTypes.tcat;
        }
        if (searchLocation && (searchLocation.value || searchLocation.mcode) && searchLocation) {
            const getMcode = searchLocation.mcode ? searchLocation.mcode : searchLocation.value;
            if (getMcode === countries.list[0].mcode) {
                params.mkt = 'en-US';
            } else {
                params.mkt = getMcode;
            }
        }
        if (filterByTime && filterByTime !== filterOptions.filterByTimeOptions[0].value) {
            params.freshness = filterByTime;
        }
        if (safeSearchType && safeSearchType !== constants.safeSearchOptions[0].value) {
            params.safe_search = safeSearchType;
        }

        // Set up headers
        if (locationSetting) {
            dispatch(setLocation());
            if (savedLocationForBusinessWidget?.Latitude && savedLocationForBusinessWidget?.Longitude) {
                headers.lat = savedLocationForBusinessWidget.Latitude;
                headers.long = savedLocationForBusinessWidget.Longitude;
            } else if (searchLocationInfo) {
                headers.lat = searchLocationInfo.Latitude;
                headers.long = searchLocationInfo.Longitude;
                if (storageAvailable('localStorage') && !safeJSONParse(localStorage.getItem('searchLocationInfo'))) {
                    localStorage.setItem('searchLocationInfo', JSON.stringify(searchLocationInfo));
                }
            }
        } else if (!locationSetting) {
            delete apiHeaders['X-Search-Location'];
        }

        // Fire a request based on the current app's searchType

        if (searchTypeIsAll || searchType === searchTypes.map) {
            if (!sameQuery) {
                if (!mobile) {
                    dispatch(clearWebPages());
                } else if (
                    (mobile &&
                        !!savedFormInput &&
                        isNewSearch &&
                        savedFormInput.length !== 0 &&
                        query.length !== 0) ||
                    offset === 0 ||
                    !offset
                ) {
                    dispatch(clearWebPages());
                }
            }
            if (mobile && !isNewSearch) {
                dispatch(mobilePagesAreLoading(true));
            }
            if (!(webPages && sameQuery)) {
                dispatch(
                    searchRequest(
                        query,
                        params,
                        searchType,
                        mobile,
                        pop,
                        headers,
                        encryptionSetting,
                        publicKeyVersion,
                        publicKey,
                        isEncryptedQueryFromURL,
                        locationSetting,
                        sameSingleBusinessQuery,
                        extraParams,
                    ),
                );
            } else {
                dispatch(change('search_input', 'search_field', currentFilter.query));
                if (!pop) {
                    dispatch(setHistory(searchTypes.all, {}, currentFilter.query, 'search :2256'));
                }
            }
        } else if (searchType === searchTypes.images) {
            if (filterByImageColor && filterByImageColor !== filterOptions.imageFilterByColorOptions[0].value) {
                params.color = filterByImageColor;
            }
            if (filterByImageSize !== filterOptions.imageSizeFilterOptions[0].value) {
                params.size = filterByImageSize;
            }
            if (filterByImageType !== filterOptions.imageTypeFilterOptions[0].value) {
                params.image_type = filterByImageType;
            }
            if (filterByImageAspectRatio !== filterOptions.imageAspectRatioFilterOptions[0].value) {
                params.aspect = filterByImageAspectRatio;
            }
            if (filterByImageLicense !== filterOptions.imageLicenseFilterOptions[0].value) {
                params.license = filterByImageLicense;
            }
            if (!(sameQuery && images && images.hasOwnProperty('value') && images.value.length) || relatedSearch) {
                dispatch(
                    searchImages(
                        query,
                        params,
                        headers,
                        pop,
                        publicKeyVersion,
                        publicKey,
                        encryptionSetting,
                        isEncryptedQueryFromURL,
                        relatedSearch,
                        seeMoreSearch,
                    ),
                );
            } else {
                dispatch(change('search_input', 'search_field', currentFilter.query));
                if (!pop) {
                    dispatch(setHistory(searchTypes.images, params, currentFilter.query, 'search :2328'));
                }
            }
        } else if (searchType === searchTypes.videos) {
            if (filterByVideoLength && filterByVideoLength !== filterOptions.videoDurationFilter[0].value) {
                params.video_length = filterByVideoLength;
            }
            if (
                filterByVideoResolution &&
                filterByVideoResolution !== filterOptions.videoResolutionFilter[0].value
            ) {
                params.resolution = filterByVideoResolution;
            }
            if (!(sameQuery && videos && videos.hasOwnProperty('value') && videos.value.length)) {
                dispatch(
                    searchVideos(
                        query,
                        params,
                        headers,
                        pop,
                        publicKeyVersion,
                        publicKey,
                        encryptionSetting,
                        isEncryptedQueryFromURL,
                    ),
                );
            } else {
                dispatch(change('search_input', 'search_field', currentFilter.query));
                if (!pop) {
                    dispatch(setHistory(searchTypes.videos, params, currentFilter.query, 'search :2380'));
                }
            }
        } else if (searchType === searchTypes.news) {
            if ((!mobile && !sameQuery) || (mobile && !sameQueryIgnoreOffset)) {
                dispatch(clearNewsPages());
            }
            if (mobile && !isNewSearch) {
                dispatch(setMobileNewsAreLoading(true));
            }
            if (!(sameQuery && news && news.hasOwnProperty('value') && news.value.length)) {
                dispatch(
                    searchNews(
                        query,
                        params,
                        headers,
                        pop,
                        false,
                        publicKeyVersion,
                        publicKey,
                        encryptionSetting,
                        isEncryptedQueryFromURL,
                        mobile,
                    ),
                );
            } else {
                dispatch(change('search_input', 'search_field', currentFilter.query));
                if (!pop) {
                    dispatch(setHistory(searchTypes.news, params, '2424'));
                }
            }
        }
        // Detailed map view path is handled inside of common/components/AppleMaps/AppleMapsDetailedView/index.js


        if (!relatedSearch) {
            const rqData = {
                recentQuery: query,
                recentFilterByTime: filterByTime,
                recentFilterByImageSize: filterByImageSize,
                recentFilterByImageColor: filterByImageColor,
                recentFilterByImageType: filterByImageType,
                recentFilterByImageAspectRatio: filterByImageAspectRatio,
                recentFilterByImageLicense: filterByImageLicense,
                recentFilterByVideoLength: filterByVideoLength,
                recentFilterByVideoResolution: filterByVideoResolution,
                recentSearchLocation: searchLocation,
                recentOffset: offset,
                recentLatitude: null,
                recentLongitude: null,
            };
            if ((locationSetting || sharedLocation) && locationInfo && businessWidgetContext?.nearme_loc) {
                rqData.recentLatitude = locationInfo.Latitude;
                rqData.recentLongitude = locationInfo.Longitude;
            }
            dispatch(setRecentQueryFilters(searchType, rqData));
        }
        dispatch(setRecentActionData(null));
        dispatch(setSearchResultCacheDisabled(false));
    };
}

function mobilePagesAreLoading(isLoading) {
    return (dispatch) => {
        dispatch({
            type: MOBILE_PAGES_ARE_LOADING,
            data: isLoading,
        });
    };
}

function setMobileNewsAreLoading(isLoading) {
    return (dispatch) => {
        dispatch({
            type: MOBILE_NEWS_ARE_LOADING,
            data: isLoading,
        });
    };
}

export function getPagePrivacyInfo(url) {
    return (dispatch, getState) => {
        const { openBetaSearchToken } = getState().app;
        let headers = {};
        if (openBetaSearchToken) {
            headers['X-Search-Token'] = openBetaSearchToken;
        }
        const axiosInstance = axios();
        delete axiosInstance.defaults.headers['X-Allow-All-Origin-Key'];
        return axiosInstance({
            method: 'get',
            timeout: 60000,
            url: `${paths.privacyReportPath}${url}`,
            headers,
        })
            .then((response) => {
                axiosInstance.defaults.headers['X-Allow-All-Origin-Key'] = corsKey;
                return response.data;
            })
            .catch((e) => {
                console.log(e);
            });
    };
}

export function getRelatedImgSearch(token) {
    return (dispatch, getState) => {
        const { openBetaSearchToken } = getState().app;
        const { searchLocation } = getState().search;
        const params = {
            mkt: searchLocation.mcode ? searchLocation.mcode : searchLocation.value,
            insightsToken: token,
            stk: openBetaSearchToken,
        };
        return axios()({
            method: 'get',
            url: paths.imageDetailPath,
            params,
        })
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                console.log(e);
            });
    };
}

export function getCurrencyRates(base, target, amount) {
    return (dispatch, getState) => {
        const { openBetaSearchToken } = getState().app;
        const { searchLocation, currencyWidgetMetaData } = getState().search;
        const params = {
            mkt: searchLocation.mcode ? searchLocation.mcode : searchLocation.value,
            base,
            target,
            amount,
            stk: openBetaSearchToken,
        };
        return axios()({
            method: 'get',
            url: paths.currencyPath,
            params,
        })
            .then((response) => {
                const richHeaderData = [
                    {
                        entities: [
                            {
                                data: response.data,
                                metadata: currencyWidgetMetaData,
                            },
                        ],
                    },
                ];
                dispatch({
                    type: CURRENCY_WIDGET_DATA_FETCHED,
                    data: richHeaderData,
                });
                return response.data;
            })
            .catch((e) => {
                console.log(e);
            });
    };
}

export function clearWebPages() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_WEB_PAGES,
        });
    };
}

export function clearNewsPages() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_NEWS_PAGES,
        });
    };
}

export function clearImages() {
    return (dispatch, getState) => {
        const { clearImageHandler } = getState().search;
        if (clearImageHandler && typeof clearImageHandler === 'function') {
            clearImageHandler();
        }
        dispatch({
            type: CLEAR_IMAGES,
        });
    };
}

export function clearRelatedImages() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_RELATED_IMAGES,
        });
    };
}

export function clearVideos(src) {
    // console.log('Clear Videos actions called from: ', src)
    return (dispatch) => {
        dispatch({
            type: CLEAR_VIDEOS,
        });
    };
}

export function clearState() {
    // console.log('Clear Widgets action called')
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE,
        });
    };
}

export function clearRankingResponse() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_RANKING_RESPONSE,
        });
    };
}

export function clearWikiBox() {
    // console.log('clearEntity action called')
    return (dispatch) => {
        dispatch({
            type: CLEAR_WIKIBOX,
        });
    };
}

export function saveFormInput(input, src) {
    return (dispatch) => {
        dispatch({
            type: SAVE_FORM_INPUT,
            data: input && input.search_field ? input.search_field : input,
        });
    };
}

export function setSearchPageNumber(number, source) {
    // console.log('setSearchPageNumber', number, source)
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_PAGE_NUMBER,
            data: number,
        });
    };
}

export function clearAllFilters() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_FILTERS_SEARCH_SETTING,
        });
    };
}

export function changeLocation(location) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_LOCATION_SEARCH_SETTING,
            data: location,
        });
    };
}

export function changeFilterByTime(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_TIME_SEARCH_SETTING,
            data: value,
        });
    };
}

export function changeFilterByVideoLength(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_VIDEO_LENGTH_SETTING,
            data: value,
        });
    };
}

export function changeFilterByVideoResolution(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_VIDEO_RESOLUTION_SETTING,
            data: value,
        });
    };
}

export function changeFilterByImageSize(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_IMAGE_SIZE_SETTING,
            data: value,
        });
    };
}

export function changeFilterByColor(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_COLOR_SETTING,
            data: value,
        });
    };
}

export function changeFilterByImageType(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_IMAGE_TYPE_SETTING,
            data: value,
        });
    };
}

export function changeFilterByImageAspectRatio(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_IMAGE_ASPECT_RATIO_SETTING,
            data: value,
        });
    };
}

export function changeFilterByImageLicense(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_FILTER_BY_IMAGE_LICENSE_SETTING,
            data: value,
        });
    };
}

export function changeSourceAPI(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_SOURCE_API,
            data: value,
        });
    };
}

export function changePT(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_PT,
            data: value,
        });
    };
}

export function setIsHeaderFixed(isHeaderFixed) {
    return (dispatch) => {
        dispatch({
            type: SET_IS_HEADER_FIXED,
            data: isHeaderFixed,
        });
    };
}

export function changeParam(value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_PARAM,
            data: value,
        });
    };
}

export function getSanitizedAdditionalParams(paramList) {
    protectedParams.forEach((paramName) => {
        if (paramList.hasOwnProperty(paramName)) {
            delete paramList[paramName];
        }
    });
    return paramList;
}

export function setClearImageHandler(clearImageHandler) {
    return (dispatch) => {
        dispatch({
            type: SET_CLEAR_IMAGE_HANDLER,
            data: clearImageHandler,
        });
    };
}

export function setRecentQueryFilters(searchType, filters) {
    return (dispatch) => {
        dispatch({
            type: SET_RECENT_QUERY_FILTERS,
            data: { searchType, filters },
        });
    };
}

export function getLocationSharing() {
    return async (dispatch) => {
        const isAskedUserForSharingLocation = getFromLocalStorage('hideLocationPopup');

        const coords = isAskedUserForSharingLocation ? await getLocationSharingPermissions() : false;

        dispatch({
            type: SET_LOCATION_SHARING,
            data: coords,
        });
    };
}

export function setDataFetching(value) {
    return (dispatch) => {
        dispatch({
            type: SET_DATA_FETCHING,
            data: value,
        });
    };
}

export function disableLocationTip() {
    return (dispatch) => {
        dispatch({
            type: SET_LOCATION_TIP_ALLOWED,
            data: false,
        });
    };
}

export function enableLocationTipStep2() {
    return (dispatch) => {
        dispatch({
            type: SET_LOCATION_TIP_STEP2_ALLOWED,
            data: true,
        });
    };
}

export function setSearchResultCacheDisabled(value) {
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_RESULT_CACHE_DISABLED,
            data: value,
        });
    };
}

export function setQueryContext(queryContext, searchType) {
    return (dispatch) => {
        dispatch({
            type: QUERY_CONTEXT_FETCHED,
            data: { queryContext, searchType },
        });
    };
}

export function setQuerySaved(query) {
    return (dispatch) => {
        dispatch({
            type: SEARCH_QUERY_SAVED,
            data: query,
        });
    };
}

export function setAppleMapSearchLocation(coordinates) {
    return (dispatch) => {
        dispatch({
            type: SET_APPLE_MAP_SEARCH_LOCATION,
            data: coordinates,
        });
    };
}
