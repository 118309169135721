const filterOptions = {
	filterByTimeOptions: [
		{
			value: 'any',
			label: 'Any time',
			key: 'header-filters-filterbytime-anytime',
		},
		{
			value: 'day',
			label: 'Past 24 hours',
			key: 'header-filters-filterbytime-24hours',
		},
		{
			value: 'week',
			label: 'Past week',
			key: 'header-filters-filterbytime-week',
		},
		{
			value: 'month',
			label: 'Past month',
			key: 'header-filters-filterbytime-month',
		},
	],
	//&size=
	imageSizeFilterOptions: [
		{
			label: 'Any Size',
			value: 'any',
			key: 'header-filters-filterbysize-anysize',
		},
		{
			label: 'Small',
			value: 'small',
			key: 'header-filters-filterbysize-small',
		},
		{
			label: 'Medium',
			value: 'medium',
			key: 'header-filters-filterbysize-medium',
		},
		{
			label: 'Large',
			value: 'large',
			key: 'header-filters-filterbysize-large',
		},
		{
			label: 'Extra large',
			value: 'wallpaper',
			key: 'header-filters-filterbysize-extralarge',
		},
	],
	//&color=
	imageFilterByColorOptions: [
		{
			label: 'Any Color',
			value: 'any',
			key: 'header-filters-filterbycolor-anycolor',
			className:
				'react-select__option-color react-select__option-color--any',
			colorOption: true,
		},
		{
			label: 'Color only',
			value: 'colorOnly',
			key: 'header-filters-filterbycolor-coloronly',
			className:
				'react-select__option-color react-select__option-color--only',
			colorOption: true,
		},
		{
			label: 'Monochrome',
			value: 'monochrome',
			key: 'header-filters-filterbycolor-monochrome',
			className:
				'react-select__option-color react-select__option-color--monochrome',
			colorOption: true,
		},
		{
			label: 'Red',
			value: 'Red',
			key: 'header-filters-filterbycolor-red',
			className:
				'react-select__option-color react-select__option-color--red',
			colorOption: true,
		},
		{
			label: 'Orange',
			value: 'Orange',
			key: 'header-filters-filterbycolor-orange',
			className:
				'react-select__option-color react-select__option-color--orange',
			colorOption: true,
		},
		{
			label: 'Yellow',
			value: 'Yellow',
			key: 'header-filters-filterbycolor-yellow',
			className:
				'react-select__option-color react-select__option-color--yellow',
			colorOption: true,
		},
		{
			label: 'Green',
			value: 'Green',
			key: 'header-filters-filterbycolor-green',
			className:
				'react-select__option-color react-select__option-color--green',
			colorOption: true,
		},
		{
			label: 'Blue',
			value: 'Blue',
			key: 'header-filters-filterbycolor-blue',
			className:
				'react-select__option-color react-select__option-color--blue',
			colorOption: true,
		},
		{
			label: 'Purple',
			value: 'Purple',
			key: 'header-filters-filterbycolor-purple',
			className:
				'react-select__option-color react-select__option-color--purple',
			colorOption: true,
		},
		{
			label: 'Pink',
			value: 'Pink',
			key: 'header-filters-filterbycolor-pink',
			className:
				'react-select__option-color react-select__option-color--pink',
			colorOption: true,
		},
		{
			label: 'Brown',
			value: 'Brown',
			key: 'header-filters-filterbycolor-brown',
			className:
				'react-select__option-color react-select__option-color--brown',
			colorOption: true,
		},
		{
			label: 'Black',
			value: 'Black',
			key: 'header-filters-filterbycolor-black',
			className:
				'react-select__option-color react-select__option-color--black',
			colorOption: true,
		},
		{
			label: 'Grey',
			value: 'Gray',
			key: 'header-filters-filterbycolor-gray',
			className:
				'react-select__option-color react-select__option-color--gray',
			colorOption: true,
		},
		{
			label: 'Teal',
			value: 'Teal',
			key: 'header-filters-filterbycolor-teal',
			className:
				'react-select__option-color react-select__option-color--teal',
			colorOption: true,
		},
		{
			label: 'White',
			value: 'White',
			key: 'header-filters-filterbycolor-white',
			className:
				'react-select__option-color react-select__option-color--white',
			colorOption: true,
		},
	],
	//&imageType=
	imageTypeFilterOptions: [
		{
			label: 'Any type',
			value: 'any',
			key: 'header-filters-filterbytype-anytype',
		},
		{
			label: 'Photo',
			value: 'Photo',
			key: 'header-filters-filterbytype-photograph',
		},
		{
			label: 'Clip art',
			value: 'clipart',
			key: 'header-filters-filterbytype-clipart',
		},
		{
			label: 'Animated',
			value: 'animatedGif',
			key: 'header-filters-filterbytype-animated',
		},
		{
			label: 'Transparent',
			value: 'transparent',
			key: 'header-filters-filterbytype-transparent',
		},
	],
	imageAspectRatioFilterOptions: [
		{
			label: 'All',
			value: 'any',
			key: 'header-filters-filterbyaspectratio-anylayout',
		},
		{
			label: 'Square',
			value: 'square',
			key: 'header-filters-filterbyaspectratio-square',
		},
		{
			label: 'Tall',
			value: 'tall',
			key: 'header-filters-filterbyaspectratio-tall',
		},
		{
			label: 'Wide',
			value: 'wide',
			key: 'header-filters-filterbyaspectratio-wide',
		},
	],
	imageLicenseFilterOptions: [
		{
			label: 'All',
			value: 'any',
			key: 'header-filters-filterbylicense-all',
		},
		{
			label: 'Public',
			value: 'public',
			key: 'header-filters-filterbylicense-public',
		},
		{
			label: 'Share',
			value: 'share',
			key: 'header-filters-filterbylicense-share',
		},
		{
			label: 'Modify',
			value: 'modify',
			key: 'header-filters-filterbylicense-modify',
		},
		{
			label: 'ShareCommercially',
			value: 'shareCommercially',
			key: 'header-filters-filterbylicense-sharecommercially',
		},
		{
			label: 'ModifyCommercially',
			value: 'modifyCommercially',
			key: 'header-filters-filterbylicense-modifycommercially',
		},
	],
	//&videoLength=
	videoDurationFilter: [
		{
			label: 'Any duration',
			value: 'any',
			key: 'header-filters-filterbyduration-anyduration',
		},
		{
			label: 'Short',
			value: 'short',
			key: 'header-filters-filterbyduration-short',
		},
		{
			label: 'Medium',
			value: 'medium',
			key: 'header-filters-filterbyduration-medium',
		},
		{
			label: 'Long',
			value: 'long',
			key: 'header-filters-filterbyduration-long',
		},
	],
	//&resolution=
	videoResolutionFilter: [
		{
			label: 'Any resolution',
			value: 'any',
			key: 'header-filters-filterbyresolution-anyresolution',
		},
		{
			label: '480p',
			value: '480p',
			key: 'header-filters-filterbyresolution-480p',
		},
		{
			label: '720p',
			value: '720p',
			key: 'header-filters-filterbyresolution-720p',
		},
		{
			label: '1080p',
			value: '1080p',
			key: 'header-filters-filterbyresolution-1080p',
		},
	],
	searchTypes: {
		home: 'home',
		all: 'all',
		news: 'news',
		images: 'images',
		videos: 'videos',
	},
};

export default filterOptions;
