/**
 * # Navigation Bar
*/

/**
 * ## Imports
*/
// import { Trans } from "react-i18next";
import Menu from '../Menu/Menu';

import { handleThemedLogo, isDarkThemeEnabled } from '../../../utils/helpers';

import './NavigationBar.scss';
import Link from "../Link/Link";
import CompanyLogo from "../../../features/Home/src/CompanyLogo";

/**
 * ## Component
 * @param {object} submitMetrics
 * @param {boolean} isInteriorPage
 * @param {boolean} onDarkBackground
 * @returns
 */
const NavigationBar = ({
    submitMetrics,
    isInteriorPage = true,
    onDarkBackground = false,
}) => {
    const isLightLogo = !isDarkThemeEnabled() && !onDarkBackground;
    const logo = `/images/homepage_rebrand/assets/tempest-logo${isLightLogo? '' : '-medium'}.svg`;

    return (
        <div className={`navbar${isInteriorPage ? ' navbar--interior' : ''}${onDarkBackground ? ' navbar--dark-background' : ''}`}>
            {!isInteriorPage ? (
                <h1 className="navbar__slogan">
                    {/* <Trans
                        ns="websiteV2"
                        i18nKey="homepage-section-1-topleft-punchline"
                        defaults={`<span className="navbar__slogan-first-part">It's your data.</span> Own it.`}
                        components={{ span: <span /> }}
                    /> */}
                    Keep your searches private
                </h1>
            ) : (
                <Link to="/">
                   <CompanyLogo
                        alwaysBrightLogo={true}
                        svgComponent={handleThemedLogo()}
                        width={176}
                        height={39}
                        classList="navbar__logo"
                    />
                </Link>
            )}
            <Menu
                className="simple-header__menu"
                isHomeMenu={true}
            />
        </div>
    );
}

export default NavigationBar;
