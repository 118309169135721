const breakpoints = {
	imageResultsLayoutBreakPoint: 760,
	imageSidebarLayoutBreakPoint: 1200,
	footerFeedbackButtonBreakPoint: 1102,
	filterLayoutBreakPoint: 760,
	minWidthSmBreakPoint: 760,
	minWidthMdBreakPoint: 1161,
	maxWidthSmBreakPoint: 759,
	maxWidthMdBreakPoint: 1160,
};
export default breakpoints;
