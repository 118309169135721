import React, {ButtonHTMLAttributes} from 'react';
import './ButtonCta.scss';

type ButtonCtaProps = {
	className?: string,
	onClick: () => void,
	children: React.ReactNode,
	buttonStyle?: 'light'
} & ButtonHTMLAttributes<HTMLButtonElement>;

function ButtonCta({ className, onClick, children, type = "button", buttonStyle, ...rest }: ButtonCtaProps) {
	return (
		<button
			className={`button-cta${buttonStyle ? ` button-cta--${buttonStyle}` : ''}${className ? ` ${className}` : ``}`}
			type={type}
			onClick={onClick}
			{...rest}
		>
			<span className="button-cta__label">{children}</span>
		</button>
	);
};

export default ButtonCta;
