import './Rating.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import ReactRating from 'react-rating';

import { ReactComponent as EmptyTripadvisorIcon } from '../../images/icons/ratingIcons/tripadvisor-empty.svg';
import { ReactComponent as FullTripadvisorIcon } from '../../images/icons/ratingIcons/tripadvisor-full.svg';
import { ReactComponent as YelpIcon } from '../../images/icons/ratingIcons/yelp-icon.svg';

class Rating extends PureComponent {
	render() {
		const { t, className, value, readonly, fractions, iconType } =
			this.props;
		const ratingStart = 0;
		const ratingStop = 5;

		const getFullIcon = () => {
			switch (iconType) {
				case 'tripadvisor':
					return <FullTripadvisorIcon className="full-icon" />;
				case 'yelp':
					return <YelpIcon className="full-icon" />;
				default:
					return '';
			}
		};

		const getEmptyIcon = () => {
			switch (iconType) {
				case 'tripadvisor':
					return <EmptyTripadvisorIcon className="empty-icon" />;
				case 'yelp':
					return <YelpIcon className="empty-icon" />;
				default:
					return '';
			}
		};

		const getRatingPrefix = () => {
			const valueInt = Number(value);

			if (valueInt > 0 && valueInt <= 1.5) {
				return '1';
			} else if (valueInt > 1.5 && valueInt <= 2.5) {
				return '2';
			} else if (valueInt > 2.5 && valueInt <= 3.5) {
				return '3';
			} else if (valueInt > 3.5 && valueInt <= 4.5) {
				return '4';
			} else if (valueInt > 4.5) {
				return '5';
			} else return '';
		};

		const ratingPrefix = getRatingPrefix();

		return (
			<>
				<ReactRating
					className={`rating${className ? ` ${className}` : ''}${
						iconType ? ` rating--${iconType}` : ''
					}${value > 0 ? ` rating--${ratingPrefix}` : ''}`}
					start={ratingStart}
					stop={ratingStop}
					step={1}
					initialRating={value}
					readonly={readonly}
					fractions={fractions}
					emptySymbol={getEmptyIcon()}
					fullSymbol={getFullIcon()}
				/>
				<p className="sr-only">
					<em>
						{t('rating')}: {ratingPrefix}/{ratingStop}
					</em>
				</p>
			</>
		);
	}
}

Rating.defaultProps = {
	className: '',
	value: 0,
	fractions: 0,
	readonly: true,
	iconType: '',
};

Rating.propTypes = {
	className: PropTypes.string,
	value: PropTypes.number,
	fractions: PropTypes.number,
	readonly: PropTypes.bool,
	iconType: PropTypes.oneOf(['', 'tripadvisor', 'yelp']),
};

export default withTranslation('common')(Rating);
