function getCurrentPosition(options = {}) {
	return new Promise((resolve, reject) => {
		navigator?.geolocation?.getCurrentPosition(resolve, reject, options);
	});
}

export const fetchCoordinates = async () => {
	try {
		const { coords } = await getCurrentPosition();
		const { latitude, longitude } = coords;

		return { latitude, longitude };
	} catch (error) {
		console.error(error);
	}
};

export const getLocationSharingPermissions = async () => {
	try {
		const result = await navigator?.permissions?.query({
			name: 'geolocation',
		});

		return result.state === 'granted';
	} catch (error) {
		return await fetchCoordinates();
	}
};
