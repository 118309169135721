import { PureComponent } from 'react';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';

import FeedbackButton from '../FeedbackButton/FeedbackButton';
import companyConfigs from '../../../constants/company';

function renderSentence(url, name, display_url, openInNewTab) {
	return url ? (
		<a
			href={url}
			rel="noreferrer"
			target={openInNewTab ? '_blank' : ''}
			className="infocard-details-card__link"
		>
			{(display_url ? display_url : name).toLowerCase()}
		</a>
	) : (
		name
	);
}
class InfoCard extends PureComponent {
	render() {
		const { t, data } = this.props;
		const byTempest = data?.byTempest;
		const { name, url, display_url } = data?.provider;
		const openInNewTab = data?.openInNewTab || data?.provider?.openInNewTab;
		const { withAboutPopupNortonWording } = companyConfigs?.features;

		return (
			<div className="infocard-details-card">
				<h4 className="infocard-details-card__title">
					{t(
						'about-results-tooltip-block-1-title',
						'About this result',
					)}
				</h4>
				<div className="infocard-details-card__text">
					This result is provided by Surf Encrypt with data from
					&nbsp;
					{name || url
						? renderSentence(url, name, display_url, openInNewTab)
						: null}
					.
				</div>
				<h4 className="infocard-details-card__title">
					{t(
						'about-results-tooltip-block-2-title',
						'Why am I seeing this?',
					)}
				</h4>
				<div className="infocard-details-card__text">
					{withAboutPopupNortonWording ? (
						<Trans
							ns={'common'}
							i18nKey="about-results-tooltip-block-2-text-norton"
							values={{ search_about: byTempest }}
							defaults="Your search query appears to be about {{search_about}}. We only use your current search query to decide which results to display."
						/>
					) : (
						<>
							<Trans
								ns={'common'}
								i18nKey="about-results-tooltip-block-2-text"
								values={{ search_about: byTempest }}
								defaults="Your search query appears to be about {{search_about}}. We only use your current search query to decide which results to display. If this doesn’t seem correct, "
							/>
							<FeedbackButton
								className="infocard-details-card__link"
								isInfoCard={false}
								withIcon={false}
								plainContainer={true}
							>
								{t(
									'about-results-tooltip-block-2-text-2',
									'please give us feedback',
								)}
							</FeedbackButton>
							.
						</>
					)}
				</div>
				<h4 className="infocard-details-card__title">
					{t(
						'about-results-tooltip-block-3-title',
						'Is this result an ad?',
					)}
				</h4>
				<div className="infocard-details-card__text">
					{t(
						'about-results-tooltip-block-3-text',
						'This search result is not sponsored. We are showing the data exactly as we receive it from reputable sources.',
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation('common')(InfoCard);
