import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { bindActionCreators } from 'redux';

import { setIsHeaderFixed } from '../../../actions/search';

import { freezeBody, unfreezeBody } from '../../../utils/helpers';
import { ReactComponent as FeedbackIcon } from '../../images/icons/feedback-bubble.svg';
import Button from '../Button/Button';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

class FeedbackButton extends PureComponent {
	state = {
		shownFeedbackPopup: false,
	};

	openFeedbackPopup() {
		freezeBody(true);
		this.setState({
			shownFeedbackPopup: true,
		});
	}

	closeFeedbackPopup() {
		this.setState({
			shownFeedbackPopup: false,
		});
		unfreezeBody();
	}

	componentWillUnmount() {
		unfreezeBody();
	}

	render() {
		const { isInfoCard, children, className, t, withIcon, plainContainer } =
			this.props;
		const { shownFeedbackPopup } = this.state;

		return (
			<>
				{plainContainer ? (
					<span
						className={'feedback-fake-link'}
						onClick={this.openFeedbackPopup.bind(this)}
					>
						{children}
					</span>
				) : (
					<Button
						className={`feedback-button${
							className ? ` ${className}` : ``
						}${isInfoCard ? ` feedback-button--infocard` : ``}`}
						onClick={this.openFeedbackPopup.bind(this)}
						type="button"
					>
						{withIcon && (
							<FeedbackIcon className="feedback-button__icon" />
						)}
						<span className="feedback-button__text">
							{children === 'Feedback'
								? t('feedback', children)
								: children}
						</span>
					</Button>
				)}

				<Popup
					className="feedback-popup"
					open={shownFeedbackPopup}
					onClose={this.closeFeedbackPopup.bind(this)}
					onOpen={this.openFeedbackPopup.bind(this)}
					nested
					position={'bottom center'}
				>
					<FeedbackForm
						onClose={this.closeFeedbackPopup.bind(this)}
					/>
				</Popup>
			</>
		);
	}
}

FeedbackButton.defaultProps = {
	isInfoCard: true,
	children: 'Feedback',
	className: '',
	withIcon: true,
	plainContainer: false,
};

FeedbackButton.propTypes = {
	isInfoCard: PropTypes.bool,
	children: PropTypes.node || PropTypes.string,
	className: PropTypes.string,
	withIcon: PropTypes.bool,
	plainContainer: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			setIsHeaderFixed,
			dispatch,
		},
		dispatch,
	);
}

const mapStateToProps = (state, props) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation('common')(FeedbackButton));
