import './WikiHowCard.scss';

import React from 'react';

import { ReactComponent as CalendarIcon } from '../../../images/icons/wiki-how-icons/calendar-icon.svg';
import { ReactComponent as EyeIcon } from '../../../images/icons/wiki-how-icons/eye-icon.svg';
import WidgetTemplate from '../../WidgetTemplate/WidgetTemplate';

type WikiHowCardProps = {
	imgUrl: string;
	title: string;
	className: string;
	viewCount: string;
	lastEditedRelative: string;
	lastEditedISO: string;
	url: string;
	openInNewTab: boolean;
	onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
};

function WikiHowCard({
	imgUrl,
	title,
	className,
	viewCount,
	lastEditedRelative,
	lastEditedISO,
	url,
	openInNewTab,
	onClick,
}: WikiHowCardProps) {
	return (
		<WidgetTemplate.Block
			className={`wiki-how-card${className ? ` ${className}` : ''}`}
		>
			<a
				href={url}
				className="wiki-how-card__inner"
				target={openInNewTab ? '_blank' : ''}
				rel="noreferrer"
				onClick={onClick}
			>
				<img
					className="wiki-how-card__img"
					src={imgUrl}
					alt=""
					width="211"
					height="159"
				/>
				<section className="wiki-how-card__content">
					<h3 className="wiki-how-card__title">{title}</h3>
					<p className="wiki-how-card__info">
						<EyeIcon className="wiki-how-card__info-icon" />
						<span className="wiki-how-card__info-text">
							{viewCount} views
						</span>
					</p>
					<p className="wiki-how-card__info">
						<CalendarIcon className="wiki-how-card__info-icon" />
						<span className="wiki-how-card__info-text">
							<time
								dateTime={lastEditedISO}
								className="wiki-how-card__publish-date"
							>
								{lastEditedRelative}
							</time>
						</span>
					</p>
					<img
						className="wiki-how-card__logo"
						src="./images/wiki-how/wiki-how-logo.png"
						alt=""
						width="73.85"
						height="12"
					/>
				</section>
			</a>
		</WidgetTemplate.Block>
	);
}

export default WikiHowCard;
