/**
 * # Back To Search
 */

/**
 * ## Imports
 */
import './BackToSearch.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import breakpoints from '../../../constants/breakpoints';
import { scrollToTop } from '../../../utils/helpers';

/**
 * ## Component
 */
const BackToSearch = () => {
	const { t } = useTranslation('websiteV2');
	const searchBox = document.querySelector(
		'.fixed-half__body .search-input .input',
	);

	const [show, setShow] = useState(false);
	const isDesktop = useMediaQuery({
		minWidth: breakpoints.minWidthSmBreakPoint,
	});

	useEffect(() => {
		function handleScroll() {
			const scrollPosition = window.scrollY;
            const homePagePortraitValue = window.innerHeight - 70;
            const homePageLandscapeValue = window.innerHeight - 50;
            const visibleScrollPosition =  window.innerHeight > window.innerWidth ?  homePagePortraitValue : homePageLandscapeValue
            const isVisible = scrollPosition > visibleScrollPosition

			if (isVisible) {
				setShow(true);
			} else {
				setShow(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const clickHandler = () => {
		scrollToTop();
		if (isDesktop) {
			searchBox.focus();
		}
	};

	return show ? (
		<button type="button" className="back-to-search" onClick={clickHandler}>
			<span className="back-to-search__body">
				{t(
					'homepage-section-1-searchbox-tooltip',
					'Search in complete privacy',
				)}
			</span>
		</button>
	) : null;
};

export default BackToSearch;
