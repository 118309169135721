import './WidgetCTA.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ReactComponent as ArrowDownIcon } from '../../images/icons/arrow-down-icon.svg';
import { ReactComponent as ArrowIcon } from '../../images/icons/cta-arrow-icon.svg';
import Button from '../Button/Button';

class WidgetCTA extends PureComponent {
	render() {
		const {
			className,
			children,
			buttonType,
			onClick,
			link,
			openInNewTab,
			isRotateIcon,
			buttonMode,
		} = this.props;

		const getIcon = () => {
			switch (buttonType) {
				case 'horizontal':
					return <ArrowIcon />;
				case 'vertical':
					return <ArrowDownIcon />;
				default:
					return '';
			}
		};

		return (
			<>
				{!!link && (
					<a
						href={link}
						target={openInNewTab ? '_blank' : ''}
						rel="noopener noreferrer"
						className={`button widget-cta${className ? ` ${className}` : ''}${buttonMode ? ` widget-cta--${buttonMode}` : ''}`}
					>
						{children}
						{getIcon()}
					</a>
				)}
				{!link && (
					<Button
						className={`widget-cta${className ? ` ${className}` : ''}${isRotateIcon ? ' rotate-icon' : ''}${buttonMode ? ` widget-cta--${buttonMode}` : ''}`}
						onClick={onClick}
					>
						{children}
						{getIcon()}
					</Button>
				)}
			</>
		);
	}
}

WidgetCTA.defaultProps = {
	buttonText: '',
	buttonType: '',
	isRotateIcon: false,
	buttonMode: '',
};

WidgetCTA.propTypes = {
	buttonText: PropTypes.string,
	buttonType: PropTypes.oneOf(['', 'horizontal', 'vertical']),
	isRotateIcon: PropTypes.bool,
	buttonMode: PropTypes.oneOf(['', 'light']),
};

export default WidgetCTA;
