export const decodeURIWrapper = (uri) => {
	try {
		return decodeURI(uri);
	} catch {
		return '';
	}
};

/**
 * This hack tries to differ between Macintosh and
 * PC systems and attaches a class to the `<body>`
 * tag.
 */
export const isMac = () => {
	if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
		document.body.classList.add('mac');
	} else {
		document.body.classList.add('pc');
	}
};
export const strip = (text) => {
	var tempDiv = document.createElement('DIV');
	tempDiv.innerHTML = text;
	return tempDiv.innerText;
};
