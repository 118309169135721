/**
 * # About Button Popup
 * Contents that appear when AboutButton was pressed
 */

/**
 * ## Imports
 */
import React, {FC} from "react";
import Button from '../Button/Button';

import { ReactComponent as CloseIcon } from '../../images/icons/close-icon.svg';
import InfoCard from "../InfoCard/InfoCard";
import OutsideAlerter from "../OutsideAlerter/OutsideAlerter";

type AboutButtonPopupProps = {
    onClose: (e ?: React.MouseEvent<HTMLElement>) => void;
    data: {
        byTempest: string,
        provider: unknown,
        openInNewTab?: boolean,
    },
}

const AboutButtonPopup:FC<AboutButtonPopupProps> = ({
    onClose,
    data,
}) => {
    return (
        <div className="popup-box">
            <OutsideAlerter onClick={onClose}>
                <>
                    <Button
                        className="popup-box__close-button"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>

                    <InfoCard data={data} />
                </>
            </OutsideAlerter>
        </div>
    );
}

export default AboutButtonPopup;