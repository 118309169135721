import './Menu.scss';

import PropTypes from 'prop-types';
import React, {Suspense, useEffect, useRef, useState} from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {closeGlobalMenu, submitMetrics, toggleGlobalMenu} from '../../../actions/app';
import {
	disableLocationTip,
	enableLocationTipStep2,
} from '../../../actions/search';

import { action, category } from '../../../constants/metrics';
import { addBodyClass, removeBodyClass } from '../../../utils/helpers';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings-icon.svg';
import TipsCard from '../TipsCard/TipsCard';
import useClickOutside from "../../hooks/useClickOutside";
import { analyticsType } from "../../../constants";

const MenuContent = React.lazy(() =>
	import('./lazyComponents/lazyMenuContent.js'),
);

const classNameMenuOpen = 'menu-open';
const classNameStickyMenuOpen = 'sticky-menu-open';

function Menu ({
	submitMetrics,
	isHeaderMenu,
	className,
	t,
	locationTipAllowed,
	disableLocationTip,
	locationSetting,
	enableLocationTipStep2,
	isStickyBar,
	isGlobalMenu,
	isOpen,
	toggleGlobalMenu,
	isHomeMenu,
    closeGlobalMenu
}) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [showLocationTipCard, setShowLocationTipCard] = useState(true);

	const menuRef = useRef(null);

	useClickOutside({
		element: menuRef.current,
		onClickOutside: () => {
			handleClickOutside();
		},
	});

	// Close the settings, when the location is enabled and the locationPopup appears
	useEffect(() => {
		const handleMenuClose = (event) => {
			// need to close the gloabel or local instance
			closeGlobalMenu()
			setIsMenuOpen(false)
		};

		window.addEventListener('closeMenu', handleMenuClose);

		// Cleanup after component unmounts
		return () => {
			window.removeEventListener('closeMenu', handleMenuClose);
		};
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		return () => {
			removeBodyClass(classNameMenuOpen);
		}
	}, [])

	const handleClickOutside = () => {
		closeMenu();
	};

	const onMenuOpen = () => {
		if (!isStickyBar) {
			addBodyClass(classNameMenuOpen);
		}

		setShowLocationTipCard(false);
	}

	const onMenuClose = () => {
		if (isMenuOpen || isOpen) {
			removeBodyClass(classNameMenuOpen);
		}
	}

	const openMenu = () => {
		if (!isMenuOpen) {
			onMenuOpen();
			setIsMenuOpen(true);
		}
	}

	const closeMenu = () => {
		onMenuClose();
		if (isMenuOpen) {
			setIsMenuOpen(false);
		} else if (isOpen) {
			toggleGlobalMenu();
		}
	}

	const toggleMenu = () => {
		if (isStickyBar || isHeaderMenu) {
			if (!isMenuOpen) {
				openMenu();
				addBodyClass(classNameStickyMenuOpen);
			} else if (isMenuOpen) {
				closeMenu();
				removeBodyClass(classNameStickyMenuOpen);
			}
		} else {
			if (!isOpen) {
				onMenuOpen();
			} else {
				onMenuClose();
			}
			toggleGlobalMenu();
		}

		submitMetrics({
			Category: isHeaderMenu ? category.serp : category.home,
			Action: action.click,
			Object: 'settings',
			Value: 'settings',
		}, isHeaderMenu ? analyticsType.serp : analyticsType.wb);
	}

	return (
		<>
			{
				<section className={`menu${className && ` ${className}`}`} ref={menuRef}>
					<h2 className="sr-only">{t('menu')}</h2>
					{locationTipAllowed &&
						showLocationTipCard &&
						!locationSetting && !isGlobalMenu && (
							<TipsCard
								className="menu__tips-card"
								pointerPosition="top-right"
								iconType="pin"
								title={t('tips-card-title-step-1')}
								description={t(
									'tips-card-description-step-1',
								)}
								buttonText={t('tips-card-next')}
								step={1}
								onClose={disableLocationTip}
								onSubmit={() => {
									enableLocationTipStep2();
									toggleMenu();
								}}
							/>
						)}
					{!isGlobalMenu && <button
						className="menu__button"
						onClick={toggleMenu}
					>
						<SettingsIcon/>
					</button>}
					{(isMenuOpen || isOpen) && (
						<Suspense fallback={<div> </div>}>
							<MenuContent
								showNav={!isHeaderMenu}
								toggleMenu={toggleMenu}
							/>
						</Suspense>
					)}
				</section>
			}
		</>
	);
}

const mapStateToProps = (state) => ({
	locationTipAllowed: state.search.locationTipAllowed,
	locationSetting: state.app.locationSetting,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			submitMetrics,
			disableLocationTip,
			enableLocationTipStep2,
			toggleGlobalMenu,
			closeGlobalMenu
		},
		dispatch,
	);
};

Menu.defaultProps = {
	className: '',
	isHeaderMenu: false,
};

Menu.propTypes = {
	className: PropTypes.string,
	isHeaderMenu: PropTypes.bool,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation('common')(Menu));
