import './App.css';

import detectBrowserLanguage from 'detect-browser-language';
import moment from 'moment';
import 'moment/min/locales.min';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
	changeAppLanguage,
	changeSearchType,
	historyPush,
	setImagesSidebarStatus,
	setRecentActionData,
	setRecentPath,
} from './actions/app';
import { getAPIBase } from './actions/axiosConfig';
import {
	clearImages,
	clearVideos,
	getLocationSharing,
	runSearch,
	readQueryFromURL,
	setSearchPageNumber,
	setupENVs
} from './actions/search';

import Menu from './common/components/Menu/Menu';

import MainRouter from './app/Router';
import history from './app/history';
import constants, { themeOptions } from './constants';
import { availableLanguages } from './constants/language';
import searchTypes from "./constants/searchTypes";
import {change} from "redux-form";
import * as queryStringHelper from "query-string";
import {scrollToTop} from "./utils/helpers";

class App extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			apiBase: null,
		};
	}

	componentDidMount() {
		const {
			changeAppLanguage,
			getLocationSharing,
			locationSetting,
			readQueryFromURL,
			queryFromUrl,
			encryptedQueryFromURL,
			query,
			setupENVs
		} = this.props;
		!locationSetting && getLocationSharing();
		const detectedLanguage = detectBrowserLanguage();
		const { theme, lang, i18n, config, langUserSet } = this.props;
		// Surf Encrypt Search logic
		const openBetaToken = localStorage.getItem('openBetaSearchToken');
		const corsValue = localStorage.getItem('cors');
		const sourceapiValue = localStorage.getItem('sourceapi');
		if ((!openBetaToken && !corsValue && !sourceapiValue) || ( sourceapiValue && sourceapiValue !== 'api.surfencrypt.com')) {
			Promise.all([setupENVs()]).then(() => {
				window.location.reload();
			});
		}
		
		if (config && config.hasOwnProperty('REACT_APP_API_HOST')) {
			this.setState({ apiBase: getAPIBase(config.REACT_APP_API_HOST) });
		} else {
			this.setState({ apiBase: getAPIBase() });
		}
		// some browsers ( you firefox) just send the half like pt instead of pt-PT
		const closestLanguage =
			availableLanguages.find(
				(i) => detectedLanguage.substring(0, 2) === i.substring(0, 2),
			) || availableLanguages[0];
		this.setupTheme(theme);
		if (langUserSet === 'yes') {
			i18n.changeLanguage(lang);
			moment.locale(lang);
		} else {
			changeAppLanguage(closestLanguage);
			i18n.changeLanguage(closestLanguage);
			moment.locale(closestLanguage);
		}

		if (!this.validateSearchParameters()) {
			historyPush('/');
			return;
		}

		// Load query from URL on initial page load
		if (!queryFromUrl && !encryptedQueryFromURL && !query) {
			Promise.all([readQueryFromURL(null, 'App :90')]).then(() => {
				this.loadFromURL();
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			theme,
			location,
			readQueryFromURL,
			setRecentActionData,
			searchType,
			setRecentPath,
		} = this.props;
		const { key } = this.state;
		const htmlElement = document.getElementsByTagName('html')[0];
		const interiorPageHtmlClass = 'interior-page';

		/**
		 * - So called "interior pages" receive their own
		 * class on the `<html>` tag.
		 */
		if (location.pathname !== '/search') {
			htmlElement.classList.add(interiorPageHtmlClass);
		} else {
			htmlElement.classList.remove(interiorPageHtmlClass);
		}

		if (location.pathname !== prevProps.location.pathname || searchType !== prevProps.searchType) {
			scrollToTop('instant');
		}

		if (
			history?.action === 'POP' &&
			location.key !== key &&
			location.search !== prevProps.location.search &&
			!prevProps.location.search.includes('&sideBar=true')
		) {
			setRecentPath(null);
			setRecentActionData({ action: history.action, ...history.location })
			Promise.all([readQueryFromURL(null, 'App :132')]).then(
				() => {
					this.loadFromURL();
				},
			);
		}
		// TODO: Close images sidebar on back action

		this.setState({
			key: history?.location?.key,
		});

		this.setupTheme(theme);
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten();
		this.unlisten = undefined;
	}

	validateSearchParameters() {
		const query = queryStringHelper.parse(history.location.search);
		if (!query) {
			return true;
		}
		return !!query.q || (!!query.encq && !!query.encv);
	}

	loadFromURL() {
		const {
			changeSearchType,
			searchParams,
			queryFromUrl,
			runSearch,
			setSearchPageNumber,
			clearImages,
			clearVideos,
			encryptedQueryFromURL,
			encryptedQueryKeyVersion,
			searchPageNumber,
			searchType,
		} = this.props;

		const safeOffset = searchParams.offset ? parseInt(searchParams.offset) : 0;
		const page = (searchType === searchTypes.videos) ?
			((safeOffset < 0 ? 0 : safeOffset) / 20) + 1 :
			((safeOffset < 0 ? 0 : safeOffset) + 5) / 10;

		if (page !== searchPageNumber) {
			setSearchPageNumber(page, 'SearchResult-loadFromURL');
		}

		if (searchType !== searchParams?.type) {
			changeSearchType(searchParams.type ? searchParams.type : searchTypes.all, 'SearchResult :158');
		}

		let promiseArray = [clearImages(), clearVideos()];
		let searchField = null;
		let isEncrypted = false;

		if (queryFromUrl) {
			if (searchType !== searchParams?.type) {
				changeSearchType(searchParams.type ? searchParams.type : searchTypes.all, 'SearchResult :158');
			}
			promiseArray.push(this.props.dispatch(change('search_input', 'search_field', queryFromUrl)));
			searchField = queryFromUrl;
		} else if (encryptedQueryFromURL) {
			const query = {
				encq: encryptedQueryFromURL,
				encv: encryptedQueryKeyVersion
			};
			searchField = query;
			isEncrypted = true;
		} else {
			changeSearchType(searchTypes.all, 'SearchResult :203');
		}

		Promise.all(promiseArray).then(() => {
			runSearch({
				query: searchField,
				offset: safeOffset,
				isEncryptedQueryFromURL: isEncrypted,
			})
		});
	}

	setupTheme(theme) {
		if (theme === themeOptions[1].value) {
			this.useDarkTheme();
		} else if (theme === themeOptions[0].value) {
			this.useLightTheme();
		} else if (theme === themeOptions[2].value) {
			const isDark = window.matchMedia('(prefers-color-scheme: dark)');
			if (isDark.matches) {
				this.useDarkTheme();
			} else {
				this.useLightTheme();
			}
		}
	}

	useDarkTheme() {
		document.querySelector('html').classList.remove('light-theme');
		document.querySelector('html').classList.add('dark-theme');
	}

	useLightTheme() {
		document.querySelector('html').classList.remove('dark-theme');
		document.querySelector('html').classList.add('light-theme');
	}

	render() {
		const { apiBase } = this.state;
		const {
			queryContextContainer,
			pageType,
			encryptionSetting,
			searchType,
			menuIsOpen,
		} = this.props;
		const queryContext =
			queryContextContainer && queryContextContainer[searchType];

		return (
			<>
				<Helmet
					title={
						queryContext &&
						pageType !== constants.pageTypes.home &&
						!encryptionSetting
							? `${queryContext.originalQuery} - Surf Encrypt`
							: 'Surf Encrypt'
					}
				>
					<meta
						http-equiv="Content-Security-Policy"
						content={`
							default-src 'self';
							manifest-src 'self';
							connect-src
								'self'
								https://privacy-reports.tempest.com/
								https://data.tempest.com
								${constants.paths.analyticsPath}
								${apiBase}
								${constants.paths.getLocationPath}
								https://mapbox-api.tempest.com
								https://*.apple-mapkit.com
								https://apple-mapkit.tempest.com
								https://*.ingest.sentry.api.surfencrypt.com
								https://s.yimg.com/ds/scripts/selectTier-v1.1.0.js
								https://msadsscale.azureedge.net/bingads/telemetryJS.js
								https://www.clarity.ms/s/0.7.47/clarity.js
								https://www.clarity.ms/tag/l8foujnwc8
								https://search.yahoo.com/beacon/syndi/sbai/gq/1
								https://x.clarity.ms/collect
								https://browser.pipe.aria.microsoft.com
								https://p.clarity.ms/collect
								https://*.clarity.ms/collect
								;
							frame-src 'self';
							worker-src 'self' blob:;
							base-uri 'self';
							style-src
								'self'
								'unsafe-inline'
								https://unpkg.com/leaflet@1.7.1/dist/leaflet.css
								;
							media-src *;
							script-src
								'self'
								${constants.paths.getLocationPath}
								https://*.apple-mapkit.com
								https://apple-mapkit.tempest.com
								https://*.ingest.sentry.api.surfencrypt.com
								https://s.yimg.com/ds/scripts/selectTier-v1.1.0.js
								https://msadsscale.azureedge.net/bingads/telemetryJS.js
								https://www.clarity.ms/s/0.7.47/clarity.js
								'unsafe-inline'
								https://www.clarity.ms/tag/l8foujnwc8
								https://search.yahoo.com/beacon/syndi/sbai/gq/1
								https://x.clarity.ms/collect
								https://browser.pipe.aria.microsoft.com
								https://p.clarity.ms/collect
								https://*.clarity.ms/collect
								;
							script-src-elem 
								'self'
								https://www.clarity.ms/tag/l8foujnwc8
								https://msadsscale.azureedge.net/bingads/telemetryJS.js
								https://www.clarity.ms/s/0.7.47/clarity.js
								;
							img-src
								'self'
								https:
								data:
								https://favicons.api.surfencrypt.com
							;
            `}
					/>
				</Helmet>
				<MainRouter />
				<div className="menu-wrapper-outside">
					<Menu isGlobalMenu={true} isOpen={menuIsOpen} />
				</div>
			</>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			changeAppLanguage,
			dispatch,
			getLocationSharing,
			setRecentPath,
			setRecentActionData,
			runSearch,
			changeSearchType,
			setSearchPageNumber,
			clearImages,
			clearVideos,
			readQueryFromURL,
			setImagesSidebarStatus,
			setupENVs,
		},
		dispatch,
	);
}

const mapStateToProps = (state, props) => ({
	theme: state.app.theme,
	lang: state.app.appLanguage,
	queryContextContainer: state.search.queryContext,
	pageType: state.app.pageType,
	encryptionSetting: state.app.encryptionSetting,
	locationSetting: state.app.locationSetting,
	searchType: state.app.searchType,
	menuIsOpen: state.app.menuIsOpen,
	langUserSet: state.app.langUserSet,
	searchParams: state.search.searchParams,
	queryFromUrl: state.search.queryFromUrl,
	encryptedQueryFromURL: state.search.encryptedQueryFromURL,
	encryptedQueryKeyVersion: state.search.encryptedQueryKeyVersion,
	searchPageNumber: state.search.searchPageNumber,
	recentActionData: state.app.recentActionData,
	query: state.search.query,
	imagesSidebarStatus: state.app.imagesSidebarStatus,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation('common')(withRouter(App)));
