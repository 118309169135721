import './Radiobutton.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class Radiobutton extends PureComponent {
	render() {
		const {
			checked,
			onChange,
			children,
			name,
			disabled,
			className,
			text,
			value,
			onFocus,
		} = this.props;

		return (
			<label className={`radiobutton${className ? ` $className` : ``}`}>
				<span className="radiobutton__text">{text || children}</span>
				<input
					className="radiobutton__input"
					type={'radio'}
					checked={checked}
					onChange={onChange}
					name={name}
					disabled={disabled}
					value={value}
					onFocus={onFocus}
				/>
				<span className="radiobutton__button" />
			</label>
		);
	}
}

Radiobutton.defaultProps = {
	onChange: Function.prototype,
	onFocus: Function.prototype,
	checked: false,
	children: '',
	name: 'name',
	disabled: false,
	className: '',
};

Radiobutton.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	children: PropTypes.node || PropTypes.string,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};

export default Radiobutton;
