/**
 * # Company Logo
 */
import React from 'react';
import cx from 'classnames';

interface CompanyLogoProps {
	alwaysBrightLogo?: boolean;
	classList?: string;
	height?: string | number;
	svgComponent: React.ReactElement;
	width?: string | number;
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({
	alwaysBrightLogo,
	svgComponent,
	width = 274,
	height = 60,
	classList,
}) => {

	/**
	 * - To permanently display the bright version of the logo, pass in the
	 * property `alwaysBrightLogo={true}`:
	 */
	const svgClass = cx(
		'logo-svg', {
			'logo-svg--bright': alwaysBrightLogo,
		},
		classList,
	);

	return (
		<div className="company-logo-wrapper"
		>
			{ React.cloneElement(svgComponent, { width, height, className: svgClass })}
		</div>
	);
};

export default CompanyLogo;