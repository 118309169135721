import './StockWidgetLoading.scss';

import React from 'react';

import SkeletonTemplate from '../SkeletonTemplate/SkeletonTemplate';
import WidgetTemplate from "../WidgetTemplate/WidgetTemplate";
import {useMediaQuery} from "react-responsive";

function StockWidgetLoading() {
	const isMobile = useMediaQuery({ maxWidth: 759 });
	const itemsAmount = isMobile ? 4 : 9;

	return (
		<WidgetTemplate.Wrapper>
			<WidgetTemplate.Paper>
				<div className="stock-widget-skeleton__header">
					<div className="stock-widget-skeleton__block stock-widget-skeleton__block--1">
						<SkeletonTemplate.Item
							className="stock-widget-skeleton__item-1-1"
							type="line"
							color="dark"
						/>
						<div className="stock-widget-skeleton__block-bottom">
							<SkeletonTemplate.Item
								className="stock-widget-skeleton__item-1-2"
								type="line"
								color="dark"
							/>
							<SkeletonTemplate.Item
								className="stock-widget-skeleton__item-1-3"
								type="line"
								color="dark"
							/>
						</div>
					</div>
					<div className="stock-widget-skeleton__block stock-widget-skeleton__block--2">
						<SkeletonTemplate.Item
							className="stock-widget-skeleton__item-2-1"
							type="line"
							color="dark"
						/>
						<SkeletonTemplate.Item
							className="stock-widget-skeleton__item-2-2"
							type="line"
							color="dark"
						/>
					</div>
				</div>
				<SkeletonTemplate.Item
					className="stock-widget-skeleton__field"
					type="gradient-field"
				/>
				<WidgetTemplate.Block className="stock-widget-skeleton__details">
					{Array.from({ length: itemsAmount }).map((value, index) => (
						<div
							key={index}
							className="stock-widget-skeleton__details-item"
						>
							<SkeletonTemplate.Item
								className="stock-widget-skeleton__details-brick"
								type="line"
							/>
							<SkeletonTemplate.Item
								className="stock-widget-skeleton__details-brick"
								type="line"
							/>
						</div>
					))}
					<div className="stock-widget-skeleton__details-buttons">
						{Array.from({length: 3}).map((value, index) => (
							<SkeletonTemplate.Item
								key={index}
								className="stock-widget-skeleton__details-button"
								type="circle"
							/>
						))}
					</div>
				</WidgetTemplate.Block>
			</WidgetTemplate.Paper>
			<SkeletonTemplate.Footer />
		</WidgetTemplate.Wrapper>
	);
}

export default StockWidgetLoading;
