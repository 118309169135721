import history from '../app/history';
import constants, { analyticsType, themeOptions } from '../constants';
import { availableLanguages } from '../constants/language';
import { encrypt } from '../utils/cryptoHelper';
import { storageAvailable, validatePublicKey } from '../utils/helpers';
import { axios } from './axiosConfig';
import { getLocation, getPublicKey } from './search';
import companyConfigs from "../constants/company";

export const CHANGE_APP_LANGUAGE = 'CHANGE_APP_LANGUAGE';

export const CHANGE_LANG_USER_SET = 'CHANGE_LANG_USER_SET';
export const CHANGE_SAFE_SEARCH_TYPE = 'CHANGE_SAFE_SEARCH_TYPE';
export const CHANGE_OPEN_NEW_TAB_SETTING = 'CHANGE_OPEN_NEW_TAB_SETTING';
export const CHANGE_SEARCH_TYPE = 'CHANGE_SEARCH_TYPE';
export const SET_MAP_SEARCH_TYPE = 'SET_MAP_SEARCH_TYPE';
export const TOGGLE_SHOW_SUGGESTIONS = 'TOGGLE_SHOW_SUGGESTIONS';
export const TOGGLE_LOCATION = 'TOGGLE_LOCATION';
export const CHANGE_CONTENT_LOAD_STATUS = 'CHANGE_CONTENT_LOAD_STATUS';
export const CHANGE_THEME = 'CHANGE_THEME';
export const TOGGLE_ENCRYPTION = 'TOGGLE_ENCRYPTION';
export const TOGGLE_SHOW_PRIVACY_CARDS = 'TOGGLE_SHOW_PRIVACY_CARDS';
export const TOGGLE_SEND_ANONYMOUS_USAGE_DATA =
	'TOGGLE_SEND_ANONYMOUS_USAGE_DATA';
export const CHANGE_PAGE_TYPE = 'CHANGE_PAGE_TYPE';
export const CHANGE_HOMEPAGE_SECTION = 'CHANGE_HOMEPAGE_SECTION';
export const CHANGE_BETA_STATUS = 'CHANGE_BETA_STATUS';
export const EMAIL_SUBMITTED = 'EMAIL_SUBMITTED';
export const ENABLE_MOCK_DATA = 'ENABLE_MOCK_DATA';
export const ENABLE_LOGGER = 'ENABLE_LOGGER';
export const PUT_METRICS = 'PUT_METRICS';
export const MOBILE_INPUT_OPEN = 'MOBILE_INPUT_OPEN';
export const TOGGLE_TIME_TO_SHOW_FOOTER = 'TOGGLE_TIME_TO_SHOW_FOOTER';
export const USE_APPLE_MAPS_LOOKUP = 'USE_APPLE_MAPS_LOOKUP';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU'
export const SET_RECENT_PATH = 'SET_RECENT_PATH';
export const SET_RECENT_ACTION_DATA = 'SET_RECENT_ACTION_DATA';
export const SET_OTHER_SETTINGS_EXPANDED = 'SET_OTHER_SETTINGS_EXPANDED';
export const SET_IMAGES_SIDEBAR_STATUS = 'SET_IMAGES_SIDEBAR_STATUS';
export const SET_DISMISS_OPEN_MAP_CARD = 'SET_DISMISS_OPEN_MAP_CARD';

const { paths, environment } = constants;

export function readSettingsFromLocalStorage() {
	return (dispatch) => {
		if (storageAvailable('localStorage')) {
			const lang = localStorage.getItem('lang');
			const langUserSet = localStorage.getItem('langUserSet');
			const search = localStorage.getItem('search');
			const showSuggestions = JSON.parse(
				localStorage.getItem('showSuggestions'),
			);
			const openInNewTab = JSON.parse(
				localStorage.getItem('openInNewTab'),
			);
			const locationSetting = JSON.parse(
				localStorage.getItem('locationSetting'),
			);
			const encryptionSetting = JSON.parse(
				localStorage.getItem('encryptionSetting'),
			);
			const theme = localStorage.getItem('theme');
			const privacyCardsSetting = JSON.parse(
				localStorage.getItem('privacyCardsSetting'),
			);
			const sendAnonymousUsage = JSON.parse(
				localStorage.getItem('sendAnonymousUsage'),
			);
			const openBetaSearchToken = localStorage.getItem(
				'openBetaSearchToken',
			);
			const emailSubmitted = JSON.parse(
				localStorage.getItem('emailSubmitted'),
			);
			const logger = JSON.parse(localStorage.getItem('logger'));
			const mockData = JSON.parse(localStorage.getItem('mockData'));
			const isOtherSettingsExpandedRaw = localStorage.getItem(
				'isOtherSettingsExpanded',
			);
			const isOtherSettingsExpanded =
				isOtherSettingsExpandedRaw === 'true'
					? true
					: isOtherSettingsExpandedRaw === 'false'
					? false
					: null;

			if (lang) {
				dispatch(changeAppLanguage(lang));
			}
			if (langUserSet) {
				dispatch(changeLangUserSet(langUserSet));
			}
			if (search) {
				dispatch(changeSafeSearchType(search));
			}
			if (showSuggestions !== null) {
				dispatch(toggleShowSuggestions(showSuggestions));
			}
			if (openInNewTab !== null) {
				dispatch(changeOpenNewTabSetting(openInNewTab));
			}
			if (locationSetting !== null) {
				dispatch(toggleLocation(locationSetting));
			} else {
				dispatch(toggleLocation(false));
			}
			if (encryptionSetting !== null) {
				dispatch(toggleEncryption(encryptionSetting));
			} else if (companyConfigs?.features?.withEncryptionSettingEnabledByDefault) {
				dispatch(toggleEncryption(true));
			}
			if (theme) {
				dispatch(changeTheme(theme));
			}
			if (privacyCardsSetting !== null) {
				dispatch(togglePrivacyCards(privacyCardsSetting));
			}
			if (sendAnonymousUsage !== null) {
				dispatch(toggleSendAnonymousUsage(sendAnonymousUsage));
			}
			if (openBetaSearchToken) {
				dispatch(changeBetaStatus(openBetaSearchToken));
			}
			if (emailSubmitted) {
				dispatch(changeEmailSubmissionStatus(emailSubmitted));
			}
			if (!!logger) {
				console.log('Logger is enabled');
				dispatch(enableLogger());
			}
			if (!!mockData) {
				console.log('Mock data is in use');
				dispatch(enableMockData());
			}
			if (!theme) {
				dispatch(changeTheme(themeOptions[2].value));
			}
			if (isOtherSettingsExpanded !== null) {
				dispatch(setOtherSettingsExpanded(isOtherSettingsExpanded));
			}
		}
	};
}

export function toggleGlobalMenu() {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_MENU,
		});
	};
}

export function closeGlobalMenu() {
	return (dispatch) => {
		dispatch({
			type: CLOSE_MENU,
		});
	};
}


export function enableLogger() {
	return (dispatch) => {
		dispatch({
			type: ENABLE_LOGGER,
		});
	};
}

export function setDismissMapOpenCard(value) {
	return (dispatch) => {
		dispatch({
			type: SET_DISMISS_OPEN_MAP_CARD,
			data: value
		})
	}
}

export function enableMockData() {
	return (dispatch) => {
		dispatch({
			type: ENABLE_MOCK_DATA,
		});
	};
}

export function changeEmailSubmissionStatus(status) {
	return (dispatch) => {
		dispatch({
			type: EMAIL_SUBMITTED,
			data: status,
		});
	};
}

export function submitBeta(email) {
	return async (dispatch, getState) => {
		let {
			publicKey,
			publicKeyVersion,
			publicKeyExpirationTime,
			publicKeyDomain,
		} = getState().search;

		if (
			!validatePublicKey(
				publicKey,
				publicKeyExpirationTime,
				publicKeyDomain,
			)
		) {
			await dispatch(getPublicKey());
			publicKey = getState().search.publicKey;
			publicKeyVersion = getState().search.publicKeyVersion;
		}
		const params = {
			email: email,
		};
		const encryptedQuery = encrypt(
			params,
			publicKey,
			publicKeyVersion,
			'submitBeta',
		);
		return axios()({
			method: 'get',
			url: paths.register,
			params: encryptedQuery,
		});
	};
}

const simpleHash = (str) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash &= hash;
	}
	return new Uint32Array([hash])[0].toString(36);
};

const metricSendAllowed = (sentMetrics, metricHash) => {
	if (!sentMetrics.hasOwnProperty(metricHash)) {
		return true;
	}
	return Date.now() - sentMetrics[metricHash] > 30000;
};

export function submitMetrics(data, type) {
	return async (dispatch, getState) => {
		const { searchLocation, pt } = getState().search;
		const { sendAnonymousUsage, sentMetrics } = getState().app;
		const endpoint =
			!type || type === analyticsType.serp ? paths.psPath : paths.wbPath;
		if (!sendAnonymousUsage) {
			return;
		}
		const dataToSend = {
			...data,
			Market: searchLocation.mcode,
			Mode:
				process.env.REACT_APP_ENVIRONMENT === environment.production
					? 'prod'
					: 'qa',
			Source: 'frontend',
			Origin: window.location.origin,
		};
		if (pt) {
			dataToSend.PT = pt;
		}
		const hash = simpleHash(JSON.stringify(dataToSend));
		if (metricSendAllowed(sentMetrics, hash)) {
			dispatch(putMetrics(hash));
			return navigator.sendBeacon(
				paths.analyticsPath + endpoint,
				JSON.stringify(dataToSend),
			);
		}
	};
}

export function sendFeedback(params, data) {
	return async (dispatch, getState) => {
		const { openBetaSearchToken } = getState().app;
		let headers = {};
		if (openBetaSearchToken) {
			headers['X-Search-Token'] = openBetaSearchToken;
		}
		return axios()({
			method: 'post',
			url: paths.feedback,
			params,
			data,
			headers,
		});
	};
}

export function changeBetaStatus(key) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_BETA_STATUS,
			data: key,
		});
	};
}

export function changeAppLanguage(language) {
	return (dispatch) => {
		if (
			!language ||
			!availableLanguages.find((lang) => lang === language)
		) {
			return;
		}
		dispatch({
			type: CHANGE_APP_LANGUAGE,
			data: language,
		});
		return;
	};
}

export function changeLangUserSet(langUserSet) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_LANG_USER_SET,
			data: langUserSet,
		});
		return;
	};
}

export function toggleEncryption(setting) {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_ENCRYPTION,
			data: setting,
		});
		return;
	};
}

export function toggleLocation(setting) {
	return (dispatch) => {
		if (setting) {
			dispatch(getLocation());
		}
		dispatch({
			type: TOGGLE_LOCATION,
			data: setting,
		});
		return;
	};
}

export function toggleShowSuggestions(setting) {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_SUGGESTIONS,
			data: setting,
		});
		return;
	};
}

export function togglePrivacyCards(setting) {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_PRIVACY_CARDS,
			data: setting,
		});
		return;
	};
}

export function toggleSendAnonymousUsage(setting) {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_SEND_ANONYMOUS_USAGE_DATA,
			data: setting,
		});
		return;
	};
}

export function changeSafeSearchType(type) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_SAFE_SEARCH_TYPE,
			data: type,
		});
		return;
	};
}

export function changeTheme(type) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_THEME,
			data: type,
		});
		return;
	};
}

export function changeSearchType(type, src) {
	// console.log('changeSearchType is called from: ', src)
	return (dispatch) => {
		dispatch({
			type: CHANGE_SEARCH_TYPE,
			data: type,
		});
		return;
	};
}

export function setMapSearchType(type) {
	return (dispatch) => {
		dispatch({
			type: SET_MAP_SEARCH_TYPE,
			data: type,
		});
	};
}

export function changeHomePageSection(type) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_HOMEPAGE_SECTION,
			data: type,
		});
		return;
	};
}

export function changePageType(type) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_PAGE_TYPE,
			data: type,
		});
		return;
	};
}

export function changeOpenNewTabSetting(setting) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_OPEN_NEW_TAB_SETTING,
			data: setting,
		});
		return;
	};
}

export function changeContentLoadStatus(status) {
	return (dispath) => {
		dispath({
			type: CHANGE_CONTENT_LOAD_STATUS,
			data: status,
		});
	};
}

export function putMetrics(metricHash) {
	return (dispatch) => {
		dispatch({
			type: PUT_METRICS,
			data: metricHash,
		});
	};
}

export function openMobileInput(value) {
	return (dispatch) => {
		dispatch({
			type: MOBILE_INPUT_OPEN,
			data: value,
		});
	};
}

export function appleMapsLookup(value) {
	return (dispatch) => {
		dispatch({
			type: USE_APPLE_MAPS_LOOKUP,
			data: value,
		});
	};
}

export function toggleTimeToShowFooter(setting) {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_TIME_TO_SHOW_FOOTER,
			data: setting,
		});
	};
}

export function setRecentPath(value) {
	return (dispatch) => {
		dispatch({
			type: SET_RECENT_PATH,
			data: value,
		});
	};
}

export function setRecentActionData(value) {
	return (dispatch) => {
		dispatch({
			type: SET_RECENT_ACTION_DATA,
			data: value,
		});
	};
}

export function setOtherSettingsExpanded(value) {
	return (dispatch) => {
		dispatch({
			type: SET_OTHER_SETTINGS_EXPANDED,
			data: value,
		});
	};
}

export function setImagesSidebarStatus(value) {
	return (dispatch) => {
		dispatch({
			type: SET_IMAGES_SIDEBAR_STATUS,
			data: value,
		});
	};
}

export function historyPush(value, src) {
	return (dispatch, getState) => {
		// console.log('historyPush is called from:', src, 'with value:', value);
		let url = value;
		if (typeof value !== 'string') {
			const pathname =
				value?.pathname || history?.location?.pathname || '';
			const searchStr = value?.search
				? new URLSearchParams(value?.search).toString()
				: '';
			url = `${pathname}?${searchStr}`;
		}
		if (
			url === getState().app.recentPath &&
			history?.location?.pathname !== '/'
		) {
			return;
		}
		dispatch(setRecentActionData(null))
		dispatch(setRecentPath(url));
		history.push(url);
	};
}
