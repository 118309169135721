import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loadStatuses from '../../../constants/loadStatuses';
import WeatherWidgetLoading from './loading';

const LazyWidget = lazy(() => import('./lazyLoad'));

class WeatherWidget extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			lazyWidgetIsLoaded: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { weatherLocation, richHeaderLoadStatus } = this.props;
		if (
			richHeaderLoadStatus === loadStatuses.completed &&
			!weatherLocation
		) {
			this.setState({
				lazyWidgetIsLoaded: true,
			});
		}
	}

	handleLazyLoad() {
		this.setState({
			lazyWidgetIsLoaded: true,
		});
	}

	render() {
		const { richHeaderLoadStatus, data, weatherLocation } = this.props;
		const { lazyWidgetIsLoaded } = this.state;

		return (
			<>
				{(richHeaderLoadStatus === loadStatuses.loading ||
					(!lazyWidgetIsLoaded &&
						richHeaderLoadStatus !== loadStatuses.idle)) && (
					<WeatherWidgetLoading />
				)}
				<Suspense fallback={<div> </div>}>
					{richHeaderLoadStatus === loadStatuses.completed &&
						weatherLocation && (
							<LazyWidget
								data={data}
								onLoad={this.handleLazyLoad.bind(this)}
							/>
						)}
				</Suspense>
			</>
		);
	}
}

const mapStateToProps = (state, props) => ({
	richHeaderLoadStatus: state.search.richHeaderLoadStatus,
	weatherLocation: state.search.weatherLocation,
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			dispatch,
		},
		dispatch,
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherWidget);
