const unlocode = [
	'GB',
	'IE',
	'US',
	'CA',
	'AU',
	'NZ',
	'IN',
	'PK',
	'BD',
	'MY',
	'MT',
	'EG',
	'MX',
	'PH',
];

export default unlocode;
