import './common/styles/index.scss';

// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import i18n from 'i18next';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import App from './App';
import history from './app/history';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import { config as i18nextConfig } from './translations';
// import { getFromLocalStorage } from "./utils/helpers";

i18n.init(i18nextConfig);

// const sendAnonymousUsage = getFromLocalStorage('sendAnonymousUsage');
// if(sendAnonymousUsage === null || sendAnonymousUsage === true) {
// 	Sentry.init({
// 		dsn: 'https://757b97f0b4184e24ad0b6a6f6c441392@o1.ingest.sentry.api.surfencrypt.com/4',
// 		integrations: [new BrowserTracing()],

// 		// We recommend adjusting this value in production, or using tracesSampler
// 		// for finer control
// 		tracesSampleRate: 1.0,
// 		// Capture Replay for 10% of all sessions,
// 		// plus for 100% of sessions with an error
// 		replaysSessionSampleRate: 0.1,
// 		replaysOnErrorSampleRate: 1.0,
// 	});
// }

fetch('/config.json')
	.then((r) => r.json())
	.then((config) => {
		render(
			<Provider store={store}>
				<Router history={history}>
					<I18nextProvider i18n={i18n}>
						<App config={config} />
					</I18nextProvider>
				</Router>
			</Provider>,
			document.getElementById('root'),
		);
	});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
