const protectedParams = [
	'q',
	'rh',
	'wb',
	'wbvers',
	'count',
	'offset',
	'encq',
	'encv',
	'mkt',
	'safe_search',
	'source',
	'trafficType',
	'form',
	'lang',
	'type',
	'secret'
];

export default protectedParams;
