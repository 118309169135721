import './WikiHow.scss';

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { submitMetrics } from '../../../actions/app';

import { action, category } from '../../../constants/metrics';
import WidgetTemplate from '../WidgetTemplate/WidgetTemplate';
import WikiHowCard from './WikiHowCard/WikiHowCard';

type WikiHowProps = {
	data: {
		title: string;
		url: string;
		articles: Array<{
			title: string;
			title_image: string;
			url: string;
			views: number;
			viewsDisplay: string;
			last_edited: number;
			lastEditedRelative: string;
			lastEditedISO: string;
		}>;
		entities: Array<{
			metadata: any;
			triggered: any;
		}>;
	};
	openInNewTab: boolean;
};

function WikiHow({ data, openInNewTab }: WikiHowProps) {
	const dispatch = useDispatch();
	const submitWikiHowMetrics = useCallback(
		(value: string) => {
			dispatch(
				submitMetrics({
					Category: category.serp,
					Action: action.click,
					Object: 'widget',
					Value: 'wikihow',
					Extras: value,
				}),
			);
		},
		[dispatch],
	);

	const onMoreBtnClick = () => {
		submitWikiHowMetrics('more-button-click');
		if (openInNewTab) {
			window.open(data.url, '_blank');
		} else {
			window.location.assign(data.url);
		}
	};

	const onItemClick = (index) => {
		submitWikiHowMetrics(`item-click-${index}`);
	};

	return (
		<WidgetTemplate.Wrapper className="wiki-how">
			<WidgetTemplate.Paper withAnimatedBorder>
				<WidgetTemplate.Header
					className="wiki-how__header"
					titleText={data.title}
					titleSize="lg"
					subtitleText="Results by WikiHow"
					onButtonClickHandler={onMoreBtnClick}
					buttonCtaStyle="light"
				/>
				<ul className="wiki-how__list">
					{data.articles.slice(0, 3).map((item, i) => (
						<li className="wiki-how__list-item">
							<WikiHowCard
								className="wiki-how__card"
								imgUrl={item.title_image}
								title={item.title}
								viewCount={item.viewsDisplay}
								lastEditedRelative={item.lastEditedRelative}
								lastEditedISO={item.lastEditedISO}
								url={item.url}
								openInNewTab={openInNewTab}
								onClick={() => onItemClick(i + 1)}
							/>
						</li>
					))}
				</ul>
			</WidgetTemplate.Paper>
			<WidgetTemplate.Footer
				infocardData={{
					byTempest: 'instructions',
					provider: data.entities[0].metadata.providers[0],
				}}
				withDetails
				withFeedback
			/>
		</WidgetTemplate.Wrapper>
	);
}

export default WikiHow;
