import React, {lazy, Suspense} from 'react';
import cx from 'classnames';
import './WidgetTemplate.scss';

import ButtonCta from '../ButtonCta/ButtonCta';
import AboutButton from '../AboutButton/AboutButton';
import companyConfigs from '../../../constants/company';
const FeedbackButton = lazy(() => import('../FeedbackButton/FeedbackButton'));

type WrapperProps = {
    className?: string,
    children: React.ReactNode,
}

function Wrapper({ children, className }: WrapperProps) {
    return (
        <div
            className={cx('widget-wrapper', className)}
        >
            {children}
        </div>
    );
}

type HeaderProps = {
    className?: string,
    titleText: string,
    titleSize?: 'lg',
    titleIconSrc?: string,
    titleIconAlt?: string,
    subtitleText?: string,
    onTitleClickHandler?: () => void,
    onButtonClickHandler?: () => void,
    ctaText?: string,
    buttonCtaStyle?: 'light',
    disabled?: boolean,
};

function Header({
    className,
    titleText,
    titleSize,
    titleIconSrc,
    titleIconAlt,
    subtitleText,
    onTitleClickHandler,
    onButtonClickHandler,
    ctaText,
    buttonCtaStyle,
    disabled = false
}: HeaderProps) {
    const titleContent = <>
        {titleIconSrc && (
            <img
                src={titleIconSrc}
                alt={titleIconAlt || 'icon'}
                className="widget-header__title-icon"
            />
        )}
        <span
            className={cx('widget-header__label', {
                [`widget-header__label--${titleSize}`]: titleSize
            })}
        >
            {titleText}
        </span>
    </>

    return (
        <header className={cx('widget-header', className)}>
            <div className="widget-header__titles">
                <h2 className="widget-header__title-wrapper">
                    {onTitleClickHandler ? (
                        <button
                            data-test={'WidgetTitle'}
                            className="widget-header__title"
                            onClick={onTitleClickHandler}
                            disabled={disabled}
                        >
                            {titleContent}
                        </button>
                    ) : titleContent}
                </h2>
                {subtitleText && (
                    <p className="widget-header__subtitle">
                        {subtitleText}
                    </p>
                )}
            </div>
            {ctaText && onButtonClickHandler && (
                <ButtonCta
                    className="widget-header__header-cta"
                    onClick={onButtonClickHandler}
                    buttonStyle={buttonCtaStyle}
                >
                    {ctaText}
                </ButtonCta>
            )}
        </header>
    );
}

type PaperProps = {
    children: React.ReactNode,
    className?: string,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void,
    callbackRef?: () => void,
    withAnimatedBorder?: boolean,
    paddingStyle?: 'narrow-top'
};

function Paper({
   children,
   className,
   onMouseEnter,
   onMouseLeave,
   callbackRef,
   withAnimatedBorder = false,
   paddingStyle
}: PaperProps) {
    return (
        <article
            className={cx('widget-paper', className, {
                'widget-paper--animated-border': withAnimatedBorder,
                'widget-paper--narrow-top-padding': paddingStyle === 'narrow-top'
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={callbackRef}
        >
            {children}
        </article>
    );
}

type BlockProps = {
    children: React.ReactNode,
    className?: string,
};

function Block({children, className}: BlockProps) {
    return (
        <div
            className={cx('widget-block', className)}
        >
            {children}
        </div>
    );
}

type FooterProps = {
    infocardData: {
        byTempest: string,
        provider: unknown,
        openInNewTab?: boolean,
    },
    withDetails: boolean,
    withFeedback: boolean,
};

function Footer({
    infocardData,
    withDetails,
    withFeedback,
}: FooterProps) {
    const isButtonVisible = companyConfigs.features?.withInfoCardsFeedbackButton && withFeedback;
    return (
        <footer
            className={`widget-footer`}
        >
            {withDetails && (
                <div className="widget-footer__button">
                    <AboutButton data={infocardData}/>
                </div>
            )}
            {isButtonVisible && (
              <Suspense fallback={<div></div>}>
                <div className="widget-footer__button widget-footer__button--feedback">
                  <FeedbackButton/>
                </div>
              </Suspense>
            )}
        </footer>
    );
}

const WidgetTemplate = {
    Wrapper,
    Paper,
    Header,
    Block,
    Footer,
}

export default WidgetTemplate;
