module.exports = {
    videosPageSize: 20,
    pageSize: 10,
    firstPageSize: 15,
    environment: {
        development: 'development',
        production: 'production',
    },
    environmentDomain: {
        development: 'qa.tempest.com',
        production: 'www.tempest.com',
        localhost: 'localhost:3000',
    },
    paths: {
        faviconPath: 'https://favicons.api.surfencrypt.com/s2/favicons?domain_url=',
        faviconPathV2:
            'https://favicons2.api.surfencrypt.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=32&url=',
        searchPath: 'v1/search',
        searchPathV2: 'v2/search?',
        newsPath: 'v1/news/search',
        imagePath: '/v1/images/search',
        imageDetailPath: '/v1/images/details',
        videoPath: '/v1/videos/search',
        entityPath: '/v1/entities?q=',
        enittyInfoBoxPath: '/v1/infobox?url=',
        weatherV2Path: '/infocard/v1/entities/weather_v2',
        suggestionsPath: '/v1/suggestions?',
        suggestionsPathV2: '/v2/suggestions?',
        imageSrcProxy: 'https://img-proxy.tempest.com/th',
        query: '?q=',
        getLocationPath: 'https://geoloc.tempest.com',
        publicKeyPath: '/publickey',
        privacyReportPath: 'https://privacy-reports.tempest.com/api/v1/query?targetDomain=',
        register: '/register',
        visibilityFeedback: '/vf/send',
        analyticsPath: 'https://improve.api.surfencrypt.com',
        psPath: '/v1/ps/',
        wbPath: '/v1/wb',
        feedback: '/feedback',
        currencyPath: '/infocard/v1/entities/currency',
        locationQuery: 'https://mapbox-api.tempest.com/geocoding/v5/mapbox.places/{searchQuery}.json',
        searchBusinesses: '/infocard/v1/entities/location/businesses/search?',
        locationWebsite: '/infocard/v1/entities/location/yelp/website',
    },
    analyticsType: {
        wb: 'WebPage',
        serp: 'SERP',
    },
    pageTypes: {
        home: 'home',
        about: 'about',
        support: 'support',
        search: 'search',
        privacy: 'privacy',
        privacyGdpr: 'privacy-gdpr',
        terms: 'terms',
        careers: 'careers',
    },
    widgetTypes: {
        images: 'images',
        videos: 'videos',
        news: 'news',
        ads: 'ads',
        sidebarAds: 'sidebarAds',
    },
    homePageSections: {
        howItWorks: 'how-it-works',
        products: 'products',
        top: 'top',
    },
    themeOptions: [
        { value: 'light', label: 'Light', key: 'menu-theme-options-light' },
        { value: 'dark', label: 'Dark', key: 'menu-theme-options-dark' },
        { value: 'system', label: 'System', key: 'menu-theme-options-system' },
    ],
    searchOptions: {
        preloaded: 'preloaded',
        newsWidget: 'newsWidget',
    },
    dropdownWithSearchTypes: {
        country: 'country',
        currency: 'currency',
        filter: 'filter',
    },
    richHeaderTypes: {
        weather: 'weather_v2',
        soccer: 'soccer_v2',
        basketball: 'basketball',
        cricket: 'cricket',
        currency: 'currency',
        stocks: 'stock_v2',
        location: 'location',
        entity: 'geography',
        movies: 'movie',
        timezone: 'geography',
        wikihow: 'wikihow',
    },
    widgetRichHeaderTypes: [
        'weather_v2',
        'soccer_v2',
        'basketball',
        'cricket',
        'currency',
        'stock_v2',
        'location',
        'movie',
    ],
    cricketResponseTypes: {
        team_response: 'team_response',
        league_response: 'league_response',
    },
    sportsWidgetViewTypes: {
        team: 'team',
        league: 'league',
        teamTeam: 'team-team',
    },
    widgetDataTypes: {
        countries: 'countries',
        usStates: 'usStates',
        country: 'country',
        divisions: 'divisions',
        division: 'division',
        subDivision: 'sub_division',
        nearCities: 'near_cities',
        city: 'city',
        timezone: 'timezone',
        timezoneCompare: 'timezone_compare',
        personality: 'personality',
        person: 'person',
        movie: 'movie',
        collection: 'collection',
        tv: 'tv',
    },
    adsTypes: {
        textAds: 'Ads/TextAd',
        productCardAds: 'Ads/ProductAd',
    },
    adsPosition: {
        sidebar: 'Sidebar',
        mainline: 'Mainline',
    },
    stocksIntraTypes: {
        intraday: 'intraday',
        intraweek: 'intraweek',
        intrayear: 'intrayear',
        intramax: 'intramax',
        month: 'month',
        halfYear: 'halfYear',
    },
    stocksStatusTypes: {
        available: 'data-available',
        noDataPreMarket: 'no-data-pre-market',
        noData: 'no-data',
    },
    currencyIntraTypes: {
        intraday: 'intraday',
        intraweek: 'intraweek',
        intrayear: 'intrayear',
        intramax: 'intramax',
        month: 'month',
        halfYear: 'halfYear',
    },
    sportsWidgetTeamType: {
        home: 'home',
        away: 'away',
    },
    soccerTabs: {
        games: 'games',
        classification: 'classification',
        news: 'news',
    },
    soccerMatchStatuses: {
        finished: 'Match Finished',
        notStarted: 'Not Started',
    },
    footballMatchStatuses: {
        tbd: 'TBD', //Time To Be Defined
        ns: 'NS', // //Not Started
        '1h': '1H', // First Half, Kick Off
        ht: 'HT', // Halftime
        '2h': '2H', // Second Half, 2nd Half Started
        et: 'ET', // Extra Time
        p: 'P', // Penalty In Progress
        ft: 'FT', // Match Finished
        aet: 'AET', // Match Finished After Extra Time
        pen: 'PEN', // Match Finished After Penalty
        bt: 'BT', // Break Time (in Extra Time)
        susp: 'SUSP', // Match Suspended
        int: 'INT', // Match Interrupted
        pst: 'PST', // Match Postponed
        post: 'POST', // Game Postponed
        canc: 'CANC', // Match Cancelled
        abd: 'ABD', // Match Abandoned
        awd: 'AWD', // Technical Loss
        wo: 'WO', // WalkOver
    },
    footballLiveStatuses: ['1H', 'HT', '2H', 'ET', 'P', 'BT'],
    footballEventsType: {
        goal: 'Goal',
    },
    footballEventsDetail: ['Normal Goal', 'Own Goal', 'Penalty'],
    footballLeagueTypes: {
        cup: 'Cup',
    },
    basketballLiveStatuses: [
        'Q1', // : Quarter 1 (In Play)
        'Q2', // : Quarter 2 (In Play)
        'Q3', // : Quarter 3 (In Play)
        'Q4', // : Quarter 4 (In Play)
        'OT', // : Over Time (In Play)
        'BT', // : Break Time (In Play)
        'HT', // : Halftime (In Play)
    ],
    searchSourceTypes: {
        msft: 'msft',
        tcat: 'tcat',
    },
    mapZoomLevel: {
        // in meters
        street: 500,
        city: 50000,
        country: 1000000,
        unitedStates: 10000000,
        usstate: 1000000,
    },
    safeSearchOptions: [
        {
            value: 'moderate',
            label: 'Moderate',
            key: 'menu-safesearch-options-moderate',
        },
        {
            value: 'strict',
            label: 'Strict',
            key: 'menu-safesearch-options-strict',
        },
        { value: 'off', label: 'Off', key: 'menu-safesearch-options-off' },
    ],
};
