import React, { PureComponent, Suspense } from 'react';
import {
	isMobile,
	// browserName
} from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
	changeSearchType,
	setRecentActionData,
	submitMetrics,
} from '../../../../../actions/app';
import {
	changeLocation,
	setIsMapTab,
	setSearchPageNumber,
} from '../../../../../actions/search';

import breakpoints from '../../../../../constants/breakpoints';
import { action, category } from '../../../../../constants/metrics';
import searchTypes from '../../../../../constants/searchTypes';
import { formatCountry, storageAvailable } from '../../../../../utils/helpers';
import { ReactComponent as ImageIcon } from '../../../../images/icons/header-icons/images.svg';
import { ReactComponent as MapIcon } from '../../../../images/icons/header-icons/map-pin.svg';
import { ReactComponent as NewsIcon } from '../../../../images/icons/header-icons/news.svg';
import { ReactComponent as SearchSmallIcon } from '../../../../images/icons/header-icons/search-small.svg';
import { ReactComponent as VideoIcon } from '../../../../images/icons/header-icons/videos.svg';
import Button from '../../../Button/Button';
import Menu from '../../../Menu/Menu';
import SearchInput from '../../../SearchInput/SearchInput';
import BrowserCtaButton from "../../../BrowserCtaButton/BrowserCtaButton";
import CompanyLogo from '../../../../../features/Home/src/CompanyLogo';
import logoSrc from '../../../../images/whiteLabel/layout-icons/logo.png';
import darkLogoSrc from '../../../../images/whiteLabel/layout-icons/logo-dark.png';
import { isDarkThemeEnabled } from '../../../../../utils/helpers';
import companyConfigs from "../../../../../constants/company";

const cloneDeep = require('lodash.clonedeep');
const remove = require('lodash.remove');

const FiltersBar = React.lazy(() => import('./lazyComponents/lazyFiltersBar'));

if (isMobile) {
	document.documentElement.scrollTop = 0;
}

const getExpandedFilterLayout = () =>
	window.innerWidth > breakpoints.minWidthSmBreakPoint;

// const getBrowserExtension = () => {
// 	if (browserName === 'Chrome') {
// 		return {
// 			browser: "chrome",
// 			link : "https://chrome.google.com/webstore/detail/tempest-private-search/aifabaooljbmglcjiedhcnmbpdbmliia"
// 		};
// 	}
//
// 	if (browserName === 'Firefox') {
// 		return {
// 			browser: 'firefox',
// 			link: "https://addons.mozilla.org/firefox/addon/tempest/"
// 		};
// 	}
// }

class Header extends PureComponent {
	constructor(props) {
		super(props);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.state = {
			isFiltersOpen: true,
			currentTab: 'all',
			fixed: false,
			countrySelectHeight: 300,
			countriesOption: [],
			isExpandedFilterLayout: getExpandedFilterLayout(),
		};
	}

	componentDidMount() {
		const { searchType } = this.props;
		const { currentTab } = this.state;
		const countriesOption = this.getCountriesOptions();

		if (
			searchType &&
			currentTab &&
			searchType !== currentTab &&
			searchType !== searchTypes.home
		) {
			this.setState({
				currentTab: searchType,
			});
		}
		this.setState({
			countriesOption: countriesOption,
		});

		window.addEventListener('scroll', this.handleScroll, { passive: true });
		window.addEventListener('resize', this.handleResize, { passive: true });
		if (isMobile) {
			document.documentElement.scrollTop = 0;
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			searchType,
			setSearchPageNumber,
			searchLocation,
			searchLocationInfo,
			appLanguage,
		} = this.props;
		const { currentTab } = this.state;
		const countriesOption = this.getCountriesOptions();
		const theme = this.props.theme;

		if (
			searchType &&
			currentTab &&
			searchType !== currentTab &&
			currentTab !== prevProps.currentTab
		) {
			this.setState({
				currentTab: searchType,
			});
			setSearchPageNumber(1, 'Header-onUpdate');
		}
		if (
			searchLocation !== prevProps.searchLocation ||
			searchLocationInfo !== prevProps.searchLocationInfo ||
			appLanguage !== prevProps.appLanguage ||
			theme !== prevProps.theme
		) {
			this.setState({
				countriesOption: countriesOption,
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.handleResize);
		this.setState = (state, callback) => {
			return;
		};
	}

	handleScroll() {
		let scroll = document.documentElement.scrollTop;
		if (scroll > 94) {
			this.setState({
				fixed: true,
			});
		} else {
			if (!this.props.isHeaderFixed) {
				this.setState({
					fixed: false,
				});
			}
		}
	}

	handleResize() {
		this.setState({
			isExpandedFilterLayout: getExpandedFilterLayout(),
		});
	}

	toggleFilters() {
		const { submitMetrics } = this.props;
		this.submitLabelMetrics('Filter');
		submitMetrics({
			Category: category.serp,
			Action: action.toggle,
			Object: 'filters',
			Value: this.state.isFiltersOpen ? 'hide' : 'show',
			Extras: this.state.currentTab,
		});
		this.setState({ isFiltersOpen: !this.state.isFiltersOpen });
	}

	getCountriesOptions() {
		const { searchLocation, searchLocationInfo, t } = this.props;
		let usersLocation;
		let options = [];
		const countriesList = t('list', {
			returnObjects: true,
			ns: 'countries',
		});
		let countriesCopy = cloneDeep(countriesList);
		let adjustHeight = 0;

		if (
			searchLocationInfo &&
			searchLocation.code !== searchLocationInfo.cc
		) {
			const findUsersLocation = countriesList.find(
				(country) => country.code === searchLocationInfo.cc,
			);

			if (findUsersLocation) {
				usersLocation = formatCountry(
					findUsersLocation,
					this.props.theme,
				);

				options.push(usersLocation);

				remove(countriesCopy, (x) => x.code === searchLocationInfo.cc);

				if (findUsersLocation.name.length > 14) {
					adjustHeight += 8;
				}
			}
		}
		const localizedSearchLocation = countriesList.find(
			(x) => x.mcode === searchLocation.mcode,
		);
		if (localizedSearchLocation && localizedSearchLocation.name) {
			searchLocation.name = localizedSearchLocation.name;
		}
		remove(countriesCopy, (x) => x.mcode === searchLocation.mcode);

		const selectedCountry = formatCountry(
			searchLocation,
			this.props.theme,
			true,
		);
		options.push(selectedCountry);
		const topValues = [
			{
				label: '',
				options: options,
			},
		];

		const countriesOption = countriesCopy
			.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
			.map((country) => {
				return formatCountry(country, this.props.theme, false);
			});
		const searchLocationNameLength = searchLocation.name.length;

		if (searchLocationNameLength >= 14) {
			if (adjustHeight > 0) {
				if (searchLocationNameLength > 20) {
					if (searchLocationNameLength < 26) {
						adjustHeight += 6;
					} else {
						adjustHeight += 1;
					}
				} else {
					adjustHeight += 12;
				}
			} else {
				if (searchLocationNameLength <= 13) {
					adjustHeight += 8;
				} else if (
					searchLocationNameLength > 14 &&
					searchLocationNameLength < 26
				) {
					adjustHeight += 4;
				} else if (searchLocationNameLength >= 26) {
					adjustHeight -= 4;
				}
			}
		} else {
			adjustHeight -= 8;
		}

		this.setState({
			countrySelectHeight: 300 + adjustHeight,
		});

		return topValues.concat(countriesOption);
	}

	changeLocation = async (location) => {
		const { changeLocation, setSearchPageNumber, submitMetrics } =
			this.props;
		const lsAvailable = storageAvailable('localStorage');
		const getLocationFromLS =
			lsAvailable && JSON.parse(localStorage.getItem('mkt'));
		const getQuery = this.getQuery();
		submitMetrics({
			Category: category.serp,
			Action: action.change,
			Object: 'regionItem',
			Value: location.mcode,
			Extras: getLocationFromLS?.mcode,
		});
		this.setState({
			countrySelectHeight: 300,
		});
		if (lsAvailable) {
			localStorage.setItem('locationSetByUser', 'true');
		}
		await changeLocation(location);
		await this.props.makeSearch(getQuery);
		await setSearchPageNumber(1, 'changeLocation');
	};

	onLocationSelectorToggle = (action) => {
		this.props.submitMetrics({
			Category: category.serp,
			Action: action.click,
			Object: 'regionSelector',
			Value: action,
		});
	};

	handleTabChange = async (type) => {
		const {
			changeSearchType,
			setIsMapTab,
			makeSearch,
			setRecentActionData,
		} = this.props;
		if (type === searchTypes.map) {
			setIsMapTab(true);
		}
		this.submitLabelMetrics(type);
		setRecentActionData(null);
		const getQuery = this.getQuery();
		await changeSearchType(type, 'Header :302');
		await this.setState({ currentTab: type });
		await makeSearch(getQuery, null, 'Header-handleTabChange');
	};

	getQuery() {
		const {
			savedFormInput,
			queryFromUrl,
			query,
			encryptedQueryFromURL,
			encryptedQueryKeyVersion,
			queryContextContainer,
		} = this.props;
		const { currentTab } = this.state;
		if (
			queryContextContainer &&
			queryContextContainer.hasOwnProperty(currentTab)
		) {
			return queryContextContainer[currentTab].originalQuery;
		} else if (query && !encryptedQueryFromURL) {
			return query;
		} else if (!query && encryptedQueryFromURL) {
			return {
				encq: encryptedQueryFromURL,
				encv: encryptedQueryKeyVersion,
			};
		}
		return queryFromUrl ? queryFromUrl : savedFormInput;
	}

	submitLabelMetrics(value) {
		this.props.submitMetrics({
			Category: category.serp,
			Action: action.click,
			Object: value,
			Value: value,
		});
	}

	render() {
		const { searchType, isHeaderFixed, showSuggestions, t } = this.props;
		const { countriesOption } = this.state;
		//Temporarily disable CTA button
		//const browserExtension = getBrowserExtension();
		const browserExtension = false;

		return (
			<header
				className={`${
					this.state.isFiltersOpen ? 'header active' : 'header'
				} ${this.state.fixed || isHeaderFixed ? 'fixed' : ''}`}
			>
				<div className="inner header__inner">
					<div className="header__main">
						<Link
							to="/"
							onClick={() => this.submitLabelMetrics('Logo')}
							className="logo-link header__logo-link"
						>
							<CompanyLogo
								classList='header__logo'
								svgComponent={
									isDarkThemeEnabled()
									? (<img src={darkLogoSrc} alt="Surf Encrypt dark logo" />)
									: (<img src={logoSrc} alt="Surf Encrypt light logo" />)
								}
								width={135}
								height={39}
							/>
						</Link>
						<Menu className="header__menu" isHeaderMenu />
						<div className="header__search-input-wrapper">
							<SearchInput
								className="header__search-input"
								onSubmit={this.props.makeSearch}
								showSuggestions={showSuggestions}
								newSearch={this.props.newSearch}
								makeSearch={this.props.makeSearch}
								type={searchType}
							/>
						</div>
						{browserExtension && (
							<BrowserCtaButton browserType={browserExtension.browser} className={'header__addon-button'} onClick={() => {
								window.open(browserExtension.link, '_blank')
							}
							}>{t(`cta-search-add-browser-extension-${browserExtension.browser}`)}</BrowserCtaButton>
						)}
					</div>
					<nav className="header__handlers">
						<h3 className="sr-only">
							{t('pagination-page-navigation')}
						</h3>
						<ul className="header__tabs">
							<li className="header__tabs-item">
								<NavLink
									component={Button}
									to={`/search?q=${this.getQuery()}`}
									className={`header__tab-button${
										this.state.currentTab ===
										searchTypes.all
											? ' header__tab-button--active'
											: ''
									}`}
									onClick={() =>
										this.handleTabChange(searchTypes.all)
									}
									dataTitle={t('header-category-all')}
								>
									<SearchSmallIcon
										className="header__tab-icon"
										height="16px"
										width="16px"
										tabIndex="-1"
									/>
									<span className="header__tab-label">
										{t('header-category-all')}
									</span>
								</NavLink>
							</li>
							<li className="header__tabs-item">
								<NavLink
									component={Button}
									to={`/search?q=${this.getQuery()}&type=images`}
									className={`header__tab-button${
										this.state.currentTab ===
										searchTypes.images
											? ' header__tab-button--active'
											: ''
									}`}
									onClick={() =>
										this.handleTabChange(searchTypes.images)
									}
									dataTitle={t('header-images')}
								>
									<ImageIcon
										className="header__tab-icon"
										height="14px"
										width="14px"
										tabIndex="-1"
									/>
									<span className="header__tab-label">
										{t('header-images')}
									</span>
								</NavLink>
							</li>
							<li className="header__tabs-item">
								<NavLink
									component={Button}
									to={`/search?q=${this.getQuery()}&type=videos`}
									className={`header__tab-button ${
										this.state.currentTab ===
										searchTypes.videos
											? ' header__tab-button--active'
											: ''
									}`}
									onClick={() =>
										this.handleTabChange(searchTypes.videos)
									}
									dataTitle={t('header-videos')}
								>
									<VideoIcon
										className="header__tab-icon"
										height="16px"
										width="16px"
										tabIndex="-1"
									/>
									<span className="header__tab-label">
										{t('header-videos')}
									</span>
								</NavLink>
							</li>
							{companyConfigs.features.withAppleMapsDetailedView &&
								<li className="header__tabs-item">
									<NavLink
										component={Button}
										to={`/search?q=${this.getQuery()}&type=map&ismaptab=true`}
										className={`header__tab-button ${
											this.state.currentTab ===
											searchTypes.map
												? ' header__tab-button--active'
												: ''
										}`}
										onClick={() =>
											this.handleTabChange(searchTypes.map)
										}
										dataTitle={t('header-map-tab')}
									>
										<MapIcon
											className="header__tab-icon"
											height="16px"
											width="16px"
											tabIndex="-1"
										/>
										<span className="header__tab-label">
											{t('header-map-tab')}
										</span>
									</NavLink>
								</li>
							}
							<li className="header__tabs-item">
								<NavLink
									component={Button}
									to={`/search?q=${this.getQuery()}&type=news`}
									className={`header__tab-button${
										this.state.currentTab ===
										searchTypes.news
											? ' header__tab-button--active'
											: ''
									}`}
									onClick={() =>
										this.handleTabChange(searchTypes.news)
									}
									dataTitle={t('header-news')}
								>
									<NewsIcon
										className="header__tab-icon"
										height="16px"
										width="16px"
										tabIndex="-1"
									/>
									<span className="header__tab-label">
										{t('header-news')}
									</span>
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
				{this.state.isFiltersOpen && (
					<Suspense fallback={<div> </div>}>
						<FiltersBar
							isFiltersOpen={this.state.isFiltersOpen}
							makeSearch={this.props.makeSearch}
							countriesOption={countriesOption}
							changeLocation={this.changeLocation.bind(this)}
							getQuery={this.getQuery.bind(this)}
						/>
					</Suspense>
				)}
			</header>
		);
	}
}

const mapStateToProps = (state) => ({
	filterByTime: state.search.filterByTime,
	filterByImageSize: state.search.filterByImageSize,
	filterByImageColor: state.search.filterByImageColor,
	filterByImageType: state.search.filterByImageType,
	filterByImageAspectRatio: state.search.filterByImageAspectRatio,
	filterByImageLicense: state.search.filterByImageLicense,
	filterByVideoLength: state.search.filterByVideoLength,
	filterByVideoResolution: state.search.filterByVideoResolution,
	searchLocation: state.search.searchLocation,
	searchLocationInfo: state.search.searchLocationInfo,
	query: state.search.query,
	savedFormInput: state.search.savedFormInput,
	searchType: state.app.searchType,
	safeSearchType: state.app.safeSearchType,
	showSuggestions: state.app.showSuggestions,
	queryFromUrl: state.search.queryFromUrl,
	encryptedQueryFromURL: state.search.encryptedQueryFromURL,
	encryptedQueryKeyVersion: state.search.encryptedQueryKeyVersion,
	queryContextContainer: state.search.queryContext,
	appLanguage: state.app.appLanguage,
	isHeaderFixed: state.search.isHeaderFixed,
	theme: state.app.theme,
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			changeLocation,
			setSearchPageNumber,
			changeSearchType,
			submitMetrics,
			setIsMapTab,
			setRecentActionData,
		},
		dispatch,
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation('common')(Header));
