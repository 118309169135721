module.exports = {
	searchErrors: {
		connectionLost: 'connectionLost',
		connectionTimeout: 'connectionTimeout',
		linkExpired: 'linkExpired',
		internalServerError: 'internalServerError',
		resourceNotFound: 'resourceNotFound',
		genericError: 'genericError',
		noResultsInData: 'noResultsInData',
		noResultsInMarket: 'noResultsInMarket',
		endOfImages: 'endOfImages',
		endOfVideos: 'endOfVideos',
	},
};
