import './SearchResultItem.scss';

import React, {PureComponent, Suspense} from 'react';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import { submitMetrics } from '../../../actions/app';

import * as constants from '../../../constants';
import { action, category } from '../../../constants/metrics';
import {
	getHostnameWithProtocolFromRegex,
	highlightWords,
} from '../../../utils/helpers';
import DeepLinkItem from '../DeepLinkItem';
import { decodeURIWrapper, isMac, strip } from './helpers';
import companyConfigs from "../../../constants/company";

const InfocardDetails = React.lazy(() => import('../InfocardDetails/InfocardDetails'));

//eslint-disable-next-line
const regexRemoveDotEnding = new RegExp(/\s\.\.\.\s[\/\-\\|]\s\w*$/, 'g');
class SearchResultItem extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showPrivacyCard: false,
			privacyInfoPreloaded: false,
			snippet: '',
			noDeeplinkSnippets: false,
		};
	}

	componentDidMount() {
		const { item, index, page } = this.props;
		if (item.deepLinks && page === 1 && index === 0) {
			let noDeeplinkSnippets = false;
			if ((!!item.deepLinks[0].snippet && !isMobile) || isMobile) {
				noDeeplinkSnippets = true;
			}
			const shallowCopyOfDeepLinks = [...item.deepLinks];
			shallowCopyOfDeepLinks.splice(4);
			this.setState({
				deepLinks: shallowCopyOfDeepLinks,
				noDeeplinkSnippets: noDeeplinkSnippets,
			});
		}

		isMac();
		// this.getURLString();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { item, query } = this.props;
		if (item !== prevProps.item) {
			this.setState({
				privacyInfoPreloaded: false,
			});
		}
		if (
			item.snippet !== prevProps.item.snippet ||
			!this.state.snippet ||
			query !== prevProps.query
		) {
			this.setState({
				snippet: highlightWords(strip(item.snippet)),
			});
		}
	}

	// componentWillReceiveProps(nextProps) {
	//   Object.keys(nextProps)
	//     .filter(key => {
	//       return nextProps[key] !== this.props[key];
	//     })
	//     .map(key => {
	//       console.log(
	//         'changed property:',
	//         key,
	//         'from',
	//         this.props[key],
	//         'to',
	//         nextProps[key]
	//       );
	//     });
	// }

	privacyCardPreload() {
		const {
			getPagePrivacyInfo,
			privacyInfo,
			domain,
			privateInfoLoadingFor,
		} = this.props;
		if (
			!privacyInfo &&
			!this.state.privacyInfoPreloaded &&
			!privateInfoLoadingFor.has(domain)
		) {
			getPagePrivacyInfo(domain);
			this.setState({ privacyInfoPreloaded: true });
		}
	}

	getTextWidth(text) {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		context.font = getComputedStyle(document.body).font;
		return context.measureText(text).width;
	}

	handleSearchResultItemClick() {
		const { isAd, isSidebarAd, keyIndex, page } = this.props;
		if (isAd || isSidebarAd) {
			this.props.submitMetrics({
				Category: category.serp,
				Action: action.click,
				Object: 'ad',
				Value: isSidebarAd ? 'sidebar' : 'mainline',
				Extras: 'text_' + (keyIndex + 1),
			});
		} else {
			this.props.submitMetrics({
				Category: category.serp,
				Action: action.click,
				Object: 'result',
				Value: keyIndex + 1,
				Extras: page,
			});
		}
	}

	onImgLoadError(event) {
		event.target.src = constants.paths.faviconPathV2;
	}

	renderName(item) {
		const name = item?.name || item?.Title;
		return <span dangerouslySetInnerHTML={{
			__html: name.replaceAll(regexRemoveDotEnding, ''),
		}} />
	}

	render() {
		const {
			item,
			index,
			openInNewTab,
			page,
			getPagePrivacyInfo,
			privacyInfo,
			privacyCardsSetting,
			setPrivateInfoLoadedStatus,
			domain,
			setOpenedPrivacyCards,
			openedPrivacyCards,
			privateInfoLoadingFor,
			isAd,
			isSidebarAd,
			t,
		} = this.props;
		const { noDeeplinkSnippets } = this.state;
		const withPrivacyCard = companyConfigs.features.withPrivacyCard;

		return (
			<article
				className={`search-result-item${
					isAd ? ` search-result-item--ad` : ``
				}`}
				id={index}
			>
				<h3 className="search-result-item__top">
					<a
						target={openInNewTab ? '_blank' : ''}
						href={item?.url || item?.URL}
						rel="noreferrer"
						className={`search-result-item__title${
							isAd || isSidebarAd
								? ' search-result-item__title--ad'
								: ''
						}`}
						onClick={this.handleSearchResultItemClick.bind(this)}
					>
						<span
							className={`search-result-item__text-label${
								!isAd && !isSidebarAd
									? ' search-result-item__text-label--search'
									: ''
							}`}
						>
							{!isAd && !isSidebarAd && (
								<img
									src={
										constants.paths.faviconPathV2 +
										getHostnameWithProtocolFromRegex(
											item.url || item?.URL,
										)
									}
									className="search-result-item__favicon"
									alt={'favicon'}
									onError={this.onImgLoadError}
								/>
							)}
							{isAd || isSidebarAd ? (
								<>
									<span className="search-result-item__text-body" >
										<span dangerouslySetInnerHTML={{
											__html: item?.title || item?.Title,
										}} />
										&nbsp;
										<span className="search-result-item__ad-tag">
											{t('ad-widget-ad', 'Ad')}
										</span>
									</span>
								</>
							) : (
								this.renderName(item)
							)}
						</span>
					</a>
				</h3>
				<div
					className="search-result-item__url-wrapper"
					ref={(el) => {
						this.urlString = el;
					}}
				>
					<a
						target={openInNewTab ? '_blank' : ''}
						rel="noreferrer"
						href={item.url || item?.URL}
						className="search-result-item__url"
					>
						{decodeURIWrapper(item.displayUrl || item?.SiteHost)}
					</a>
					{privacyCardsSetting && withPrivacyCard && (
						<Suspense fallback={<div> </div>}>
							<div
								onMouseOver={() => this.privacyCardPreload()}
								className="privacyCard"
							>
								<InfocardDetails
									data={{
										getPagePrivacyInfo,
										privacyInfo,
										originalUrl: domain,
										index,
										dataLoaded: this.props.dataLoaded,
										setPrivateInfoLoadedStatus,
										url: decodeURIWrapper(item.URL),
										setOpenedPrivacyCards,
										openedPrivacyCards,
										privateInfoLoadingFor,
									}}
									isPrivacyCard={true}
								/>
							</div>
						</Suspense>
					)}
				</div>
				<div
					className={`search-result-item__text${
						isMobile || isSidebarAd
							? ' search-result-item__text--sidebar-ad-or-mobile'
							: ''
					}`}
				>
					{isAd || isSidebarAd ? (
						<p
							dangerouslySetInnerHTML={{
								__html: item?.description || item?.Description,
							}}
						></p>
					) : (
						<p
							dangerouslySetInnerHTML={{
								__html: item?.Description,
							}}
						></p>
					)}
				</div>
				{this.state.deepLinks &&
					(page === 1 || isMobile) &&
					index === 0 &&
					this.state.deepLinks.map((deepLink, i) => {
						return (
							<DeepLinkItem
								key={i}
								index={i}
								item={deepLink}
								openInNewTab={openInNewTab}
								className={
									noDeeplinkSnippets
										? 'deepLink'
										: 'deepLink-snippet'
								}
								noDeeplinkSnippets={noDeeplinkSnippets}
							/>
						);
					})}
				{/*{(isAd || isSidebarAd) && isLastItem && <FeedbackButton />}*/}
			</article>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			submitMetrics,
		},
		dispatch,
	);
}

export default connect(
	null,
	mapDispatchToProps,
)(withTranslation('common')(SearchResultItem));
