// TODO add commented resources after lokilize sync
// DE
import countries_de from './locales/de/countries.json';
import common_de from './locales/de/no_filename.json';
import usStates_de from './locales/de/us_states.json';
import websiteV2_de from './locales/de/website-v2.json';
import privacyPolicy_de from './locales/de/privacy_policy.json';
// import termsOfService_de from './locales/de/terms_of_service.json';

// EN
import countries_en from './locales/en/countries.json';
import common_en from './locales/en/no_filename.json';
import usStates_en from './locales/en/us_states.json';
import websiteV2_en from './locales/en/website-v2.json';
import privacyPolicy_en from './locales/en/privacy_policy.json';
import termsOfService_en from './locales/en/terms_of_service.json';

// ES
import countries_es from './locales/es/countries.json';
import common_es from './locales/es/no_filename.json';
import usStates_es from './locales/es/us_states.json';
import websiteV2_es from './locales/es/website-v2.json';
import privacyPolicy_es from './locales/es/privacy_policy.json';
// import termsOfService_es from './locales/es/terms_of_service.json';

// FR
import countries_fr from './locales/fr/countries.json';
import common_fr from './locales/fr/no_filename.json';
import usStates_fr from './locales/fr/us_states.json';
import websiteV2_fr from './locales/fr/website-v2.json';
import privacyPolicy_fr from './locales/fr/privacy_policy.json';
// import termsOfService_fr from './locales/fr/terms_of_service.json';

// PT
import countries_pt from './locales/pt/countries.json';
import common_pt from './locales/pt/no_filename.json';
import usStates_pt from './locales/pt/us_states.json';
import websiteV2_pt from './locales/pt/website-v2.json';
import privacyPolicy_pt from './locales/pt/privacy_policy.json';
// import termsOfService_pt from './locales/pt/terms_of_service.json';

const resources = {
	en: {
		common: common_en,
		countries: countries_en,
		usStates: usStates_en,
		websiteV2: websiteV2_en,
		privacyPolicy: privacyPolicy_en,
		termsOfService: termsOfService_en
	},
	fr: {
		common: common_fr,
		countries: countries_fr,
		usStates: usStates_fr,
		websiteV2: websiteV2_fr,
		privacyPolicy: privacyPolicy_fr,
		// termsOfService: termsOfService_fr
	},
	de: {
		common: common_de,
		countries: countries_de,
		usStates: usStates_de,
		websiteV2: websiteV2_de,
		privacyPolicy: privacyPolicy_de,
		// termsOfService: termsOfService_de
	},
	pt: {
		common: common_pt,
		countries: countries_pt,
		usStates: usStates_pt,
		websiteV2: websiteV2_pt,
		privacyPolicy: privacyPolicy_pt,
		// termsOfService: termsOfService_pt
	},
	es: {
		common: common_es,
		countries: countries_es,
		usStates: usStates_es,
		websiteV2: websiteV2_es,
		privacyPolicy: privacyPolicy_es,
		// termsOfService: termsOfService_es
	},
};

export default resources;
