import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import SearchResult from '../features/SearchResult/SearchResult';
import HomePage from '../pages/HomePage';
import companyConfigs from "../constants/company";

const AboutPage = lazy(() => import('../features/AboutSurfEncrypt'));
const BrowserPage = lazy(() => import('../features/BrowserPage'));
const SearchPage = lazy(() => import('../features/SearchPage'));
const TermsOfUsePage = lazy(() => import('../features/TermsOfUsePage/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('../features/PrivacyPolicyPage/PrivacyPolicyPage'));
const CareersPage = lazy(() => import('../features/CareersPage/CareersPage'));
const SupportPage = lazy(() => import('../features/SupportPage/SupportPage'));
const Newsroom = lazy(() => import('../features/Newsroom/Newsroom'));
const NewsroomArticle2023062201 = lazy(() => import('../features/Newsroom/src/NewsroomArticle20230622-01'));
const NewsroomArticle2023032401 = lazy(() => import('../features/Newsroom/src/NewsroomArticle20230324-01'));
const NewsroomArticle20230525 = lazy(() => import('../features/Newsroom/src/NewsroomArticle20230525-01'));
const ImpressumPage = lazy(() => import('../features/ImpressumPage/ImpressumPage'));
const AfterDownloadPage = lazy(() => import('./lazyComponents/lazyAfterDownloadPage'));

const {
	withAboutPage,
    withBrowserPage,
    withSearchPage,
    withTermsOfUsePage,
    withCareersPage,
    withSupportPage,
    withNewsroom,
    withNewsroomArticle2023062201,
    withNewsroomArticle2023032401,
    withNewsroomArticle20230525,
    withImpressumPage,
    withPrivacyPolicyGdprPage,
    withAfterDownloadPage,
} = companyConfigs?.webPages;

const MainRouter = (props) => {
	return (
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route
				path="/search"
				render={(props) => {
					const filteredProps = {...props};
					delete filteredProps.match;
					return <SearchResult {...filteredProps} />;
				}}
			/>
			<Suspense fallback={<div> </div>}>
				{withNewsroom &&
					<Route
						exact path="/newsroom"
						component={Newsroom}
					/>
				}
				{withBrowserPage &&
					<Route
						exact path="/browser"
						component={BrowserPage}
					/>
				}
				{withSearchPage &&
					<Route
						exact path="/tempest-search"
						component={SearchPage}
					/>
				}
				{withAboutPage &&
					<Route
						exact path="/about"
						component={AboutPage}
					/>
				}
				{withSupportPage &&
					<Route
						exact path="/support"
						component={SupportPage}
					/>
				}
				{withTermsOfUsePage &&
					<Route
						exact path="/terms-of-service"
						component={TermsOfUsePage}
					/>
				}
				{withImpressumPage &&
					<Route
						exact path="/impressum"
						component={ImpressumPage}
					/>
				}
				{withNewsroomArticle2023062201 &&
					<Route
						exact path="/newsroom/q-and-a-with-michael-levit-tempests-past-present-and-future"
						component={NewsroomArticle2023062201}
					/>
				}
				{withNewsroomArticle2023032401 &&
					<Route
						exact path="/newsroom/tempest-launches-to-take-private-search-mainstream"
						component={NewsroomArticle2023032401}
					/>
				}
				{withNewsroomArticle20230525 &&
					<Route
						exact path="/newsroom/search-giants-continue-to-crush-privacy-pioneers"
						component={NewsroomArticle20230525}
					/>
				}
				{withCareersPage &&
					<Route
						exact path="/careers"
						component={CareersPage}
					/>
				}
				{withAfterDownloadPage && (
					<Route exact path="/after-download" component={AfterDownloadPage}/>
				)}
				{withAfterDownloadPage && (
					<Route exact path="/download" component={AfterDownloadPage} />
				)}
				{withPrivacyPolicyGdprPage &&
					<Route
						exact
						path="/privacy-policy"
						component={PrivacyPolicyPage}
					/>
				}
			</Suspense>
		</Switch>
	);
};

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps, null, null, { pure: false })(
	MainRouter,
);
