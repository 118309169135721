import { useEffect } from 'react';

const SelectTierScript = ({ adsContext }) => {
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.defer = true;
    scriptElement.async = true;
    scriptElement.src = './selectTier-v1.1.0.js';
    // scriptElement.src = 'https://s.yimg.com/ds/scripts/selectTier-v1.1.0.js';

    document.head.appendChild(scriptElement);

    // Call selectTier after script is loaded
    if (adsContext && Object.keys(adsContext).length > 1) {
      scriptElement.onload = () => {
        // @ts-ignore
        window?.selectTier?.init({
          source_tag: adsContext?.sourceTag || 'domaindev_webbox_xmlb_11786_surfencrypt',
          ysid: adsContext.searchId,
          cid: adsContext.clientId,
          ig: adsContext.impressionGuid,
          select_tier: { clarityId: adsContext.clarityId, rguid: adsContext.rguId },
          test_mode: false
        });
      };
    }

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(scriptElement);
    };
  }, [adsContext]);

  return null;
};

export default SelectTierScript;
