import companyConfigs from './company';

const supportedLanguages = [
	{ value: 'en-EN', label: 'English' },
	{ value: 'fr-FR', label: 'French' },
	{ value: 'es-ES', label: 'Spanish' },
	{ value: 'pt-PT', label: 'Portuguese' },
	{ value: 'de-DE', label: 'Deutsch' },
];

const filteredLanguages = supportedLanguages.filter((language) => companyConfigs.languages.includes(language.value))

const languageOptions = filteredLanguages || supportedLanguages;

export const availableLanguages = languageOptions.map((language) => language.value);

export default languageOptions;
