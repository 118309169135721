import './Footer.scss';

import React, { PureComponent, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	changeHomePageSection,
	changePageType,
	submitMetrics,
} from '../../../../../actions/app';

import history from '../../../../../app/history';
import moment from 'moment';
import { action, category } from '../../../../../constants/metrics';
import Link from '../../../Link/Link';
import CompanyLogo from '../../../../../features/Home/src/CompanyLogo';
import logoSrc from '../../../../images/whiteLabel/layout-icons/logo.png';
import darkLogoSrc from '../../../../images/whiteLabel/layout-icons/logo-dark.png';
import { isDarkThemeEnabled } from '../../../../../utils/helpers';

import companyConfigs from "../../../../../constants/company";
import cx from 'classnames';

const {
	withAboutPage,
    withBrowserPage,
    withSearchPage,
    withTermsOfUsePage,
    withPrivacyPolicyGdprPage,
} = companyConfigs?.webPages;
const { withSearchFeedbackButton } = companyConfigs?.features;

const FeedbackButton = lazy(() => import('../../../FeedbackButton/FeedbackButton'));

class Footer extends PureComponent {
	linkEventHandler(event, type) {
		const { changeHomePageSection } = this.props;
		this.submitLabelMetrics(type);
		event.preventDefault();
		changeHomePageSection(type);
		history.push('/');
	}

	submitLabelMetrics(value) {
		this.props.submitMetrics({
			Category: category.serp,
			Action: action.click,
			Object: 'label',
			Value: value,
		});
	}

	render() {
		const {
			t,
			withFeedbackButton,
			isFixedFooter,
			isHomepageLayout = false,
		} = this.props;
		const isButtonVisible = withSearchFeedbackButton && withFeedbackButton
		const shouldRenderFooterMenu = withAboutPage || withSearchPage || withBrowserPage ||
			withPrivacyPolicyGdprPage || withTermsOfUsePage
		return (
			<>
				{isButtonVisible &&  (
					<div className="float-feedback">
						<div className="float-feedback__title">
							{t(
								'footer-feedback-section-title',
								'Help us improve Tempest',
							)}
						</div>
						<div className="float-feedback__text">
							{t(
								'footer-feedback-section-text',
								'Please let us know if you see something wrong. Your feedback will be highly appreciated.',
							)}
						</div>
						<Suspense fallback={<div></div>}>
							<FeedbackButton
								className="float-feedback__button"
								isInfoCard={false}
								isFloating
							>
								{t('feedback-send', 'Send Feedback')}
							</FeedbackButton>
						</Suspense>
					</div>
				)}
				<footer
					className={cx('footer', {
						'footer--fixed': isFixedFooter,
						'footer--divider': !isHomepageLayout,
					})}
				>
					<div className="inner footer__inner">
						<div className={cx('footer__container', {
							'footer__container--homepage-layout': isHomepageLayout,
						})}>
							{!isHomepageLayout && (
								<Link to="/" className="footer__logo">
									<CompanyLogo
										svgComponent={
											isDarkThemeEnabled()
											? (<img src={darkLogoSrc} alt="Surf Encrypt dark logo" />)
											: (<img src={logoSrc} alt="Surf Encrypt light logo" />)
										}
										width={50}
										height={32}
									/>
								</Link>
							)}

							{shouldRenderFooterMenu &&
								<>
									<div className="footer__menu-body">
										{withAboutPage || withSearchPage || withBrowserPage &&
											<div className="footer__menu-items">
												{withAboutPage &&
													<Link
														to="/about"
														className="footer__menu-item"
														clickHandler={() =>
															this.submitLabelMetrics('about')
														}
													>
														{t('footer-about', 'About')}
													</Link>
												}
												{withSearchPage &&
													<Link
														to="/tempest-search"
														className="footer__menu-item"
														clickHandler={() =>
															this.submitLabelMetrics('search')
														}
													>
														{t('footer-search', 'Search')}
													</Link>
												}
												{withBrowserPage &&
													<Link
														to="/browser"
														className="footer__menu-item"
														clickHandler={() =>
															this.submitLabelMetrics('browser')
														}
													>
														{t('footer-browser', 'Browser')}
													</Link>
												}
											</div>
										}

										<div className="footer__menu-items">
													<Link
														to="/about"
														className="footer__menu-item link"
														clickHandler={() =>
															this.submitLabelMetrics('about')
														}
													>
														About Surf Encrypt Search
													</Link>

											{withTermsOfUsePage &&
												<Link
													to="/terms-of-service"
													className="footer__menu-item"
													clickHandler={() =>
														this.submitLabelMetrics(
															'termsOfService',
														)
													}
												>
													{t('footer-terms-of-service', 'Terms of Service')}
												</Link>
											}
											{withPrivacyPolicyGdprPage &&
												<Link
													to="/privacy-policy"
													className="footer__menu-item"
													clickHandler={() =>
														this.submitLabelMetrics(
															'privacyPolicy',
														)
													}
												>
													{t('footer-privacy', 'Privacy Policy')}
												</Link>
											}
										</div>
									</div>
									<div className="footer__copyrights-container">{
										<small className="footer__copyright">&copy;&nbsp;{moment().format("YYYY")}&nbsp;Surf Encrypt</small>
									}</div>
								</>
							}
						</div>
					</div>
				</footer>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	theme: state.app.theme,
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			changePageType,
			changeHomePageSection,
			dispatch,
			submitMetrics,
		},
		dispatch,
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation('common')(Footer));
