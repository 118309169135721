import './BackToTop.scss';

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const BackToTop = ({ t }) => {
	const [visible, setVisible] = useState(false);

	const toggleVisibility = () => {
		const scrolled = document.documentElement.scrollTop;
		const toggleValue = 300;

		if (scrolled > toggleValue) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	};

	const handleClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	window.addEventListener('scroll', toggleVisibility);

	return (
		<nav className="float-back-to-top">
			<h2 className="sr-only">
				{t('msg-back-to-top-navigation', 'Back to top navigation')}
			</h2>
			<button
				type="button"
				className={`back-to-top${
					visible ? ' back-to-top--visible' : ''
				}`}
				onClick={handleClick}
			>
				<span className="sr-only" lang="en">
					{t(
						'msg-back-to-top-navigate',
						'Navigate back to the top of the webpage',
					)}
				</span>
			</button>
		</nav>
	);
};

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(withTranslation('common')(BackToTop));
