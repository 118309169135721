import { createSelector } from 'reselect';
import { createMapSelector } from 'reselect-map';

// const getWebPages = state => state.webPages
const getWebPagesValue = (webPages) => {
	return webPages ?? [];
};
const getRankingResponse = (state) => state?.mainline ?? [];
const getSearchInput = (state) => state.searchInput;

export const getRankingResponseChanges = createMapSelector(
	[getRankingResponse],
	(response) => {
		return response;
	},
);

export const getWebPagesChanges = createMapSelector(
	[getWebPagesValue],
	(value) => {
		return value;
	},
);

export const getSearchInputChanges = createSelector(
	[getSearchInput],
	(getSearchInput) => {
		return getSearchInput;
	},
);
