import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';

import { readSettingsFromLocalStorage } from '../actions/app';

import appReducer from '../reducers/appReducer';
import searchReducer from '../reducers/searchReducer';

export const reducer = combineReducers({
	form: formReducer,
	app: appReducer,
	search: searchReducer,
});

export const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

export const middleware = [thunk];
const store = createStore(
	reducer,
	composeEnhancers(applyMiddleware(...middleware)),
);

store.dispatch(readSettingsFromLocalStorage());

export default store;
