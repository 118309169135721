import toQueryString from 'to-querystring';

const NodeRSA = require('node-rsa');

const customEncodeURIComponent = (value) => {
	if (!value || !value.length) {
		return value;
	}
	return value
		.split('')
		.map((character) =>
			['&', '=', '[', ']', '?'].includes(character)
				? encodeURIComponent(character)
				: character,
		)
		.join('');
};

export const encrypt = (data, key, publicKeyVersion, source) => {
	//   console.log(data, key, source)
	const urlEncodedText = toQueryString({ ...data, q: data.q });

	// window.performance.mark('NodeRSA-start');
	const encryptor = new NodeRSA();
	encryptor.importKey(key);
	encryptor.setOptions({
		encryptionScheme: {
			scheme: 'pkcs1_oaep', //scheme
			hash: 'sha1', //hash using for scheme
		},
	});
	let encq = encryptor.encrypt(urlEncodedText, 'base64');
	if (encq.length > 344) {
		const queryString = Object.keys(data)
			.map((k) => `${k}=${customEncodeURIComponent(data[k])}`)
			.join('&');
		encq = encryptor.encrypt(queryString, 'base64');
	}
	// window.performance.mark('NodeRSA-end');
	// window.performance.measure('NodeRSA', 'NodeRSA-start', 'NodeRSA-end');
	// console.log(window.performance.getEntriesByName('NodeRSA'));

	// console.log('encq', encq);
	return {
		encv: publicKeyVersion,
		encq,
		encha: 'sha1',
	};
};

export const getLimitedQueryString = ({
	pt,
	sendParams,
	sanitizedAdditionalParams,
}) => {
	if (typeof sendParams?.q !== 'string') return sendParams;

	const queryToEncrypt = {
		...sendParams,
		...(pt && { pt }),
		...(sanitizedAdditionalParams && { sanitizedAdditionalParams }),
	};

	const query = toQueryString(queryToEncrypt);
	const fullQueryLength = query?.length || 0;

	if (fullQueryLength > 214) {
		const queryLength = fullQueryLength - 214;
		const newQuery = sendParams.q?.slice(0, -queryLength);
		return { ...sendParams, q: newQuery };
	}

	return sendParams;
};
