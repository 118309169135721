import React from 'react';
import cx from 'classnames';
import './SkeletonTemplate.scss';
import companyConfigs from "../../../constants/company";

type ItemProps = {
	className?: string,
	type?: 'line' | 'circle' | 'gradient-field',
	color?: 'dark'
}
function Item({ className, type, color }: ItemProps) {
	return (
		<div
			className={cx('skeleton-item', className, {
				[`skeleton-item--${type}`]: type,
				[`skeleton-item--${color}`]: color
			})}
		/>
	);
}

function Footer() {
	const isButtonVisible = companyConfigs.features?.withInfoCardsFeedbackButton;

	return (
		<div className="skeleton-footer">
			<SkeletonTemplate.Item type="line" className="skeleton-footer__item"/>
			{isButtonVisible && (
				<SkeletonTemplate.Item type="line" className="skeleton-footer__item skeleton-footer__item--2"/>
			)}
		</div>
	);
}

const SkeletonTemplate = {
	Item,
	Footer,
};

export default SkeletonTemplate;
