import './TipsCard.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../images/icons/tips-card-arrow-icon.svg';
import { ReactComponent as PinIcon } from '../../images/icons/tips-card-pin-icon.svg';
import Button from '../Button/Button';

function TipsCard({
	className,
	pointerPosition,
	iconType,
	title,
	description,
	buttonText,
	step,
	t,
	onClose,
	onSubmit,
}) {
	const getIcon = () => {
		switch (iconType) {
			case 'pin':
				return <PinIcon />;
			case 'arrow':
				return <ArrowIcon />;
			default:
				return '';
		}
	};

	return (
		<div
			className={`tips-card${className ? ` ${className}` : ''}${
				pointerPosition ? ` tips-card--${pointerPosition}` : ''
			}`}
		>
			{iconType && <div className="tips-card__icon">{getIcon()}</div>}
			<div className="tips-card__title">{title}</div>
			<div className="tips-card__description">{description}</div>
			<div className="tips-card__bottom">
				<div className="tips-card__dots">
					<div
						className={`tips-card__dot${
							step === 1 ? '  tips-card__dot--active' : ''
						}`}
					/>
					<div
						className={`tips-card__dot${
							step === 2 ? '  tips-card__dot--active' : ''
						}`}
					/>
				</div>
				<div className="tips-card__buttons">
					<Button className="tips-card__button" onClick={onClose}>
						{t('tips-card-close-button')}
					</Button>
					<Button
						className="tips-card__button tips-card__button--outlined"
						onClick={onSubmit}
					>
						{buttonText}
					</Button>
				</div>
			</div>
		</div>
	);
}

TipsCard.defaultProps = {
	className: '',
	pointerPosition: 'top-right',
	iconType: '',
	title: '',
	description: '',
	buttonText: '',
	step: 0,
};

TipsCard.propTypes = {
	className: PropTypes.string,
	pointerPosition: PropTypes.oneOf(['', 'top-right', 'bottom-right']),
	iconType: PropTypes.oneOf(['', 'pin', 'arrow']),
	title: PropTypes.string,
	description: PropTypes.string,
	buttonText: PropTypes.string,
	step: PropTypes.number,
};

export default withTranslation('common')(TipsCard);
