import { useMediaQuery } from 'react-responsive';

import breakpoints from '../../../../../constants/breakpoints';

const LgView = ({ children, justLg = false }) => {
	const isLgView = useMediaQuery({
		minWidth: justLg ? breakpoints.minWidthMdBreakPoint : breakpoints.minWidthSmBreakPoint ,
	});
	return <>{isLgView && children}</>;
};

export default LgView;
