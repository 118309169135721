import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import queryString from 'query-string';

import {
    ADS_FETCHED,
    BASKETBALL_WIDGET_DATA_FETCHED,
    BUSINESS_WIDGET_DATA_FETCHED,
    CHANGE_FILTER_BY_COLOR_SETTING,
    CHANGE_FILTER_BY_IMAGE_ASPECT_RATIO_SETTING,
    CHANGE_FILTER_BY_IMAGE_LICENSE_SETTING,
    CHANGE_FILTER_BY_IMAGE_SIZE_SETTING,
    CHANGE_FILTER_BY_IMAGE_TYPE_SETTING,
    CHANGE_FILTER_BY_TIME_SEARCH_SETTING,
    CHANGE_FILTER_BY_VIDEO_LENGTH_SETTING,
    CHANGE_FILTER_BY_VIDEO_RESOLUTION_SETTING,
    CHANGE_LOCATION_SEARCH_SETTING,
    CHANGE_PARAM,
    CHANGE_PT,
    CHANGE_SOURCE_API,
    CLEAR_ALL_FILTERS_SEARCH_SETTING,
    CLEAR_WIKIBOX,
    CLEAR_IMAGES,
    CLEAR_INSTANT_ANSWER,
    CLEAR_NEWS_PAGES,
    CLEAR_RANKING_RESPONSE,
    CLEAR_RELATED_IMAGES,
    CLEAR_STATE,
    CLEAR_SUGGESTIONS,
    CLEAR_VIDEOS,
    CLEAR_WEB_PAGES,
    CRICKET_WIDGET_DATA_FETCHED,
    CURRENCY_WIDGET_DATA_FETCHED,
    WIKIBOX_INFO_BOX_DATA_FETCHED,
    WIKIBOX_INFO_BOX_DATA_NOT_FOUND,
    WIKIBOX_DATA_FETCHED,
    EXPECTED_ENTITIES_FETCHED,
    FOOTBALL_WIDGET_DATA_FETCHED,
    GET_LOCATION,
    GET_MORE_BUSINESSES,
    GET_PUBLIC_KEY,
    GET_SUGGESTIONS,
    IMAGES_FETCHED,
    INSTANT_ANSWER_COMPUTATION_FETCHED,
    INSTANT_ANSWER_TIMEZONE_FETCHED,
    INSTANT_ANSWER_TRANSLATION_FETCHED,
    MOBILE_NEWS_ARE_LOADING,
    MOBILE_PAGES_ARE_LOADING,
    MOVIES_COLLECTION_INFOCARD_FETCHED,
    MOVIES_INFOCARD_FETCHED,
    NEWS_FETCHED,
    NEWS_FETCHED_MOBILE,
    OPEN_WIDGET_IMAGE_IN_IMAGES_TAB,
    QUERY_CONTEXT_FETCHED,
    RANKING_RESPONSE_FETCHED,
    RANKING_RESPONSE_FETCHED_MOBILE,
    READ_QUERY_FROM_URL,
    READ_SAVED_LOCATION_FOR_WIDGET,
    READ_SEARCH_LOCATION_INFO,
    RELATED_IMAGES_FETCHED,
    RELATED_SEARCHES_FETCHED,
    SAVE_BUSINESS_CONTEXT,
    SAVE_FORM_INPUT,
    SEARCH_QUERY_SAVED,
    SEARCH_RESULTS_FETCHED,
    SET_APPLE_MAP_SEARCH_LOCATION,
    SET_CLEAR_IMAGE_HANDLER,
    SET_DATA_FETCHING,
    SET_DETAILED_MAP_QUERY,
    SET_GEOLOCATION,
    SET_IS_HEADER_FIXED,
    SET_IS_MAP_TAB,
    SET_LOCATION_SHARING,
    SET_LOCATION_TIP_ALLOWED,
    SET_LOCATION_TIP_STEP2_ALLOWED,
    SET_MAP_ACTIVE_PIN,
    SET_RECENT_QUERY_FILTERS,
    SET_RESPONSE_TIME,
    SET_RICHHEADER_LOAD_STATUS,
    SET_SEARCH_EXTRA_PARAMS,
    SET_SEARCH_PAGE_NUMBER,
    SET_SEARCH_RESULT_CACHE_DISABLED,
    SET_SPORTS_WIDGET_VIEW,
    SET_WIDGET_DATA_TYPE,
    SET_WIDGET_TYPE,
    SHOW_ERROR_MESSAGE,
    STOCKS_WIDGET_DATA_FETCHED,
    TIMEZONE_WIDGET_FETCHED,
    TOTAL_ESTIMATED_MATCHES_FETCHED,
    VIDEOS_FETCHED,
    WEATHER_WIDGET_DATA_FETCHED,
    WEB_PAGES_FETCHED,
    WEB_PAGES_FETCHED_MOBILE,
    WIDGET_IMAGES_FETCHED,
    WIDGET_NEWS_FETCHED,
    WIDGET_VIDEOS_FETCHED,
    WIKIBOX_DATA_IS_EXPECTED,
    WIKIHOW_WIDGET_DATA_FETCHED,
    SET_ADS_CONTEXT,
    SET_YAHOO_ADS
} from '../actions/search';

import constants from '../constants';
import appErrorTypes from '../constants/appErrorTypes';
import countries from '../constants/countries';
import filterOptions from '../constants/filterOptions';
import loadStatuses from '../constants/loadStatuses';
import searchTypes from '../constants/searchTypes';
import unlocode from '../constants/unlocode';
import {
    Capitalize,
    chunkArrayInGroups,
    formatCountry,
    getDateTimeFromUnix,
    getFromLocalStorage,
    saveToLocalStorage,
    storageAvailable,
    stripUnicodeChars,
    unixDatetimeByTimezone,
} from '../utils/helpers';
import { getImageIdFromThumbnailId } from '../utils/imagesHelper';

const { searchErrors } = appErrorTypes;
const weekSize = 8;

momentDurationFormatSetup(moment);

export const removeObsoleteWeatherData = (items, timezone) => {
    const today = unixDatetimeByTimezone(timezone);
    return items.filter((item) => !today.isAfter(moment.unix(item.dt).utcOffset(timezone), 'day'));
};

export const groupTimestampsByDays = ({ data, timezone }) => {
    let result = {};
    data.forEach((hour) => {
        const day = moment.unix(hour.dt).utcOffset(timezone).isoWeekday();
        if (!result[day]) {
            result[day] = [];
        }
        result[day].push(hour);
    });
    return result;
};

export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const prepareCurrencyData = (value) => {
    return {
        ...value,
        data: value.data.map((item) => ({
            open: item.rate,
            high: item.rate,
            low: item.rate,
            timestamp: item.timestamp,
        })),
    };
};

const bulkRemoveUnicodeChars = (data) => {
    if (!data) {
        return;
    }
    return data.map((item) => ({
        ...item,
        displayUrl: stripUnicodeChars(item.displayUrl),
        title: stripUnicodeChars(item.title),
        description: stripUnicodeChars(item.description),
    }));
};

const getWmp = (data) => {
    return data
        ? data.map((item) => {
              let itemModified = {
                  ...item,
                  displayUrl: stripUnicodeChars(item.displayUrl),
                  name: stripUnicodeChars(item.name),
              };
              if (item.hasOwnProperty('deepLinks')  && item.deepLinks) {
                  itemModified.deepLinks = item.deepLinks.map((deepLinkItem) => ({
                      ...deepLinkItem,
                      name: stripUnicodeChars(deepLinkItem.name.replace(/\uFFFD/g, ' ')),
                      snippet: stripUnicodeChars(deepLinkItem.snippet),
                  }));
              }
              return itemModified;
          })
        : data;
};

const prepNewsData = (data) => {
    return data
        ? {
              ...data,
              value: data.value.map((item) => ({
                  ...item,
                  name: stripUnicodeChars(item.name),
                  description: stripUnicodeChars(item.description),
              })),
          }
        : data;
};

const replaceTeamLogoUrl = (team) => {
    if (!team) {
        return;
    }
    const replacedLogo = team.logo
        ? team.logo.replace('https://media.api-sports.io', 'https://media-api-sports.tempest.com')
        : team.logo;
    return { ...team, logo: replacedLogo };
};

const replaceTeamsLogoUrl = (teams) => {
    if (!teams) {
        return;
    }
    const result = {};
    Object.entries(teams).forEach(([key, value]) => {
        result[key] = replaceTeamLogoUrl(value);
    });
    return result;
};

const parseSportsData = (data) => {
    const getData = data[0].entities[0]?.data;
    return {
        teams: replaceTeamsLogoUrl(getData.teams),
        games: getData.games,
        team: getData.team1 ? replaceTeamLogoUrl(getData.team1) : replaceTeamLogoUrl(getData.team),
        team1: replaceTeamLogoUrl(getData.team1),
        team2: replaceTeamLogoUrl(getData.team2),
        league: getData.league,
        rounds: getData.rounds,
        season: getData?.season?.name,
        leagues: getData.leagues,
    };
};

const checkIfNoResults = (data) => {
    return data === null || data?.value?.length === 0 || !data;
};

const getItems = (data) => {
    return data.items ? data.items : data.ıtems;
};

const mapSrcToFetchedImages = (data) =>
    data.map((image, i) => {
        const imageId = getImageIdFromThumbnailId(image);
        return {
            ...image,
            src: image.thumbnailUrl,
            key: `${i}_${image.thumbnailUrl}_${imageId}`,
            imageId: imageId,
        };
    });

const prepareInfobox = (data) => {
    if (!data || !data.rows) {
        return data;
    }
    const rowsBuf = data.rows.map((el, index) => ({
        ...el,
        hideBottomBorder: data.rows[index + 1] ? data.rows[index + 1].label === '' : false,
    }));
    return { ...data, rows: rowsBuf };
};

const rankingResponseImagesItem = {
    answerType: 'Images',
};

const detectWidgetDataProvider = (url) => {
    if (!url || typeof url !== 'string') {
        return;
    }
    if (url.includes('tripadvisor')) {
        return 'tripadvisor';
    }
    if (url.includes('yelp')) {
        return 'yelp';
    }
};

const prepareVideoItems = (items) => {
    const viewCountConvert = (value) => {
        let converted = value;
        if (value >= 1000000) {
            converted = Math.round(value / 1000000) + 'M';
        } else if (value >= 1000) {
            converted = Math.round(value / 1000) + 'K';
        }
        return converted;
    };

    const formatDuration = (duration) =>
        moment.duration(duration).hours() >= 1
            ? moment.duration(duration).format('h:mm:ss', { trim: false })
            : moment.duration(duration).format('mm:ss', { trim: false });

    const getOriginFromTheURL = (url) => {
        try {
            return new URL(url).origin;
        } catch (e) {
            return url;
        }
    };

    const itemsBuf = mapSrcToFetchedImages(items);

    return itemsBuf.map((item) => ({
        ...item,
        name: stripUnicodeChars(item.name),
        viewCountDisplay: viewCountConvert(item.viewCount),
        videoPubDate: item.datePublished ? item.datePublished.split('.')[0] : null,
        videoPubDateFormatted: item.datePublished ? moment(item.datePublished).format('MMM YYYY') : null,
        durationDisplay: formatDuration(item.duration),
        faviconPath: constants.paths.faviconPathV2 + getOriginFromTheURL(item.hostPageUrl),
    }));
};

const sameCoordinates = (location1, location2) => {
    const areEqual = (a, b, tolerance = 0.1) => {
        return Math.abs(a - b) <= tolerance;
    };
    if (!location1 || !location2) {
        return false;
    }
    return areEqual(location1.latitude, location2.latitude) && areEqual(location1.longitude, location2.longitude);
};

const prepareWikiHowData = (data) => {
    const viewCountConvert = (value) => {
        let converted = value;
        if (value >= 1000000) {
            converted = Math.round(value / 1000000) + 'm';
        } else if (value >= 1000) {
            converted = Math.round(value / 1000) + 'k';
        }
        return converted;
    };

    if (!data) {
        return data;
    }
    const articles = data.entities[0].data.articles.map((article) => ({
        viewsDisplay: viewCountConvert(article.views),
        lastEditedRelative: moment.unix(article.last_edited).fromNow(),
        lastEditedISO: moment.unix(article.last_edited).toISOString(),
        ...article,
    }));
    return {
        ...data,
        title: data.title.replace(/\s-\swikiHow$/, ''),
        articles,
    };
};

export const initialState = {
    query: '',
    searchInput: '',
    searchParams: {},
    queryFromUrl: '',
    encryptedQueryFromURL: '',
    encryptedQueryKeyVersion: '',
    excessiveItemsCount: 0,
    savedFormInput: '',
    searchResult: [],
    webPages: null,
    videos: [],
    widgetVideos: null,
    wikiBox: null,
    wikiBoxDataIsExpected: false,
    wikiBoxInfoBox: null,
    wikiBoxInfoBoxNotFound: false,
    news: [],
    widgetNews: [],
    bingAds: null,
    textAds: null,
    adsContext: null,
    yahooAds: [],
    sidebarTextAds: null,
    productCardAds: null,
    sideBarProductAds: null,
    images: null,
    relatedImages: null,
    widgetImages: [],
    currencyWidget: null,
    currencyWidgetMetaData: null,
    weather: null,
    weatherMetaData: null,
    weatherDaily: null,
    weatherHourly: null,
    additionalWeatherDataStatus: loadStatuses.idle,
    stocksWidget: null,
    stocksWidgetMetaData: null,
    footballWidget: [],
    sportsWidgetMetaData: null,
    basketballWidget: [],
    cricketWidget: [],
    businessWidget: null,
    businessWidgetMetaData: null,
    suggestions: [],
    suggestionsQuery: null,
    relatedSearches: [],
    rankingResponse: null,
    queryContext: null,
    filterByTime: filterOptions.filterByTimeOptions[0].value,
    filterByImageSize: filterOptions.imageSizeFilterOptions[0].value,
    filterByImageColor: filterOptions.imageFilterByColorOptions[0].value,
    filterByImageType: filterOptions.imageTypeFilterOptions[0].value,
    filterByImageAspectRatio: filterOptions.imageAspectRatioFilterOptions[0].value,
    filterByImageLicense: filterOptions.imageLicenseFilterOptions[0].value,
    filterByVideoLength: filterOptions.videoDurationFilter[0].value,
    filterByVideoResolution: filterOptions.videoResolutionFilter[0].value,
    searchLocation: countries.list[0],
    searchLocationInfo: '',
    savedLocationForBusinessWidget: null,
    isAMPM: false,
    searchPageNumber: '',
    publicKey: null,
    encryptedLinkExpired: false,
    responseTime: null,
    noResults: false,
    richHeaderLoadStatus: loadStatuses.idle,
    wikiBoxLoadStatus: loadStatuses.idle,
    widgetType: null,
    widgetDataType: null,
    widgetProvider: null,
    sportsWidgetView: null,
    moviesInfoCard: null,
    moviesInfoCardMetaData: null,
    moviesCollectionInfoCard: null,
    moviesCollectionInfoCardMetaData: null,
    instantAnswer: null,
    sourceapi: null,
    pt: getFromLocalStorage('pt'),
    isHeaderFixed: false,
    additionalParams: JSON.parse(getFromLocalStorage('additionalParams')) || {},
    timezoneWidget: null,
    timezoneWidgetMetaData: null,
    searchInputClearHandler: null,
    showErrorMessage: null,
    clearImageHandler: null,
    recentQueryFilters: {},
    dataFetching: false,
    locationTipAllowed: false,
    locationTipStep2Allowed: false,
    suppressLocationTip: getFromLocalStorage('suppressLocationTip') || false,
    searchResultCacheDisabled: false,
    mapsModalBusinesses: [],
    detailedMapQuery: null,
};

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_QUERY_SAVED:
            return {
                ...state,
                query: action.data,
            };
        case SET_RESPONSE_TIME:
            return {
                ...state,
                responseTime: action.data.toFixed(3),
            };
        case SHOW_ERROR_MESSAGE:
            return {
                ...state,
                showErrorMessage: action.data,
            };
        case GET_LOCATION:
            let setUsersLocation;
            const usedFields = ['cc', 'Latitude', 'Longitude', 'CityName', 'CountryCode', 'CountryName', 'PlaceName'];
            let searchLocationInfoData = action.data;

            saveToLocalStorage(state.searchLocationInfo, 'searchLocationInfo', JSON.stringify(searchLocationInfoData));

            Object.keys(searchLocationInfoData).forEach((key) => {
                if (!usedFields.includes(key)) {
                    delete searchLocationInfoData[key];
                }
            });
            if (
                getFromLocalStorage('locationSetByUser') === 'true' ||
                getFromLocalStorage('locationSetFromBrowser') === 'true'
            ) {
                return state;
            }

            if (searchLocationInfoData.mcode) {
                setUsersLocation = searchLocationInfoData;
            } else {
                const findCountry = countries.list.find((country) => country.code === searchLocationInfoData.cc);
                if (findCountry) {
                    setUsersLocation = formatCountry(findCountry);
                } else {
                    setUsersLocation = formatCountry(countries.list[0]);
                }
            }
            delete setUsersLocation.label;
            saveToLocalStorage(state.searchLocation, 'mkt', JSON.stringify(setUsersLocation, getCircularReplacer()));

            return {
                ...state,
                searchLocation: setUsersLocation,
                searchLocationInfo: searchLocationInfoData,
                isAMPM: action.data && action.data.CountryCode ? unlocode.includes(action.data.CountryCode) : false,
            };
        case SET_GEOLOCATION:
            const savedLocationForBusinessWidget = {
                ...state.savedLocationForBusinessWidget,
                Latitude: action.data.latitude,
                Longitude: action.data.longitude,
                PlaceName: action.data.placeName,
            };
            saveToLocalStorage(null, 'savedLocationForBusinessWidget', JSON.stringify(savedLocationForBusinessWidget));
            saveToLocalStorage(null, 'hideLocationPopup', 'true');
            return {
                ...state,
                savedLocationForBusinessWidget: savedLocationForBusinessWidget,
            };
        case GET_PUBLIC_KEY:
            const publicKey = action.data.key;
            const publicKeyExpirationTime = action.data.publicKeyExpTimeFromLS
                ? action.data.publicKeyExpTimeFromLS
                : moment().unix() + Number(action.data.headers['x-public-key-expiration-time']);
            const publicKeyVersion = action.data.headers['x-public-key-version'];
            if (storageAvailable('localStorage')) {
                localStorage.setItem('publicKey', publicKey);
                localStorage.setItem('publicKeyExpirationTime', publicKeyExpirationTime);
                localStorage.setItem('publicKeyVersion', publicKeyVersion);
                localStorage.setItem('publicKeyDomain', action.data.apiBase);
            }

            return {
                ...state,
                publicKey,
                publicKeyExpirationTime,
                publicKeyVersion,
                publicKeyDomain: action.data.apiBase,
            };
        case READ_SEARCH_LOCATION_INFO:
            return {
                ...state,
                searchLocationInfo: action.data,
                isAMPM: action.data && action.data.CountryCode ? unlocode.includes(action.data.CountryCode) : false,
            };
        case READ_SAVED_LOCATION_FOR_WIDGET:
            return {
                ...state,
                savedLocationForBusinessWidget: action.data,
            };
        case GET_SUGGESTIONS:
            return {
                ...state,
                suggestions: action.data[1],
                suggestionsQuery: action.data[0],
            };
        case CLEAR_SUGGESTIONS:
            return {
                ...state,
                suggestions: [],
                suggestionsQuery: null,
            };
        case READ_QUERY_FROM_URL:
            return {
                ...state,
                queryFromUrl: action.data.q,
                query: action.data.q,
                encryptedQueryFromURL: action.data.encq,
                encryptedQueryKeyVersion: action.data.encv,
                searchParams: action.data,
            };
        case SET_SEARCH_PAGE_NUMBER:
            return {
                ...state,
                searchPageNumber: Math.round(action.data),
            };
        case SAVE_FORM_INPUT:
            return {
                ...state,
                savedFormInput: action.data,
            };
        case SEARCH_RESULTS_FETCHED:
            return {
                ...state,
                searchResult: action.data,
            };
        case WEB_PAGES_FETCHED:
            if (checkIfNoResults(action.data)) {
                return {
                    ...state,
                    showErrorMessage: searchErrors.noResultsInData,
                };
            }
            const wpData = getWmp(action.data);
            return {
                ...state,
                webPages: wpData,
            };
        case SET_RICHHEADER_LOAD_STATUS:
            return {
                ...state,
                richHeaderLoadStatus: action.data,
            };
        case SET_WIDGET_TYPE:
            return {
                ...state,
                widgetType: action.data,
            };
        case SET_WIDGET_DATA_TYPE:
            return {
                ...state,
                widgetDataType: action.data,
            };
        case TOTAL_ESTIMATED_MATCHES_FETCHED:
            return {
                ...state,
                totalEstimatedMatches: action.data,
            };
        case IMAGES_FETCHED:
            const shouldImagesConcat = !!state.images && !!state.images.value;
            const mapSrcToImages = mapSrcToFetchedImages(action.data.value);

            if (checkIfNoResults(action.data) && (state.images.length === 0 || state.images?.value?.length === 0)) {
                return {
                    ...state,
                    showErrorMessage: searchErrors.noResultsInData,
                    images: {
                        ...action.data,
                    },
                };
            } else {
                return {
                    ...state,
                    images: {
                        ...action.data,
                        value: shouldImagesConcat ? state.images.value.concat(mapSrcToImages) : mapSrcToImages,
                        imagesGetRecently: action.data?.value?.length || 0,
                    },
                };
            }
        case OPEN_WIDGET_IMAGE_IN_IMAGES_TAB:
            return {
                ...state,
                imageIdFromWidget: action.data,
            };
        case RELATED_IMAGES_FETCHED:
            const mapSrcToRelatedImages = mapSrcToFetchedImages(action.data.value);

            return {
                ...state,
                relatedImages: {
                    ...action.data,
                    value: mapSrcToRelatedImages,
                },
            };
        case CLEAR_RELATED_IMAGES:
            return {
                ...state,
                relatedImages: null,
            };
        case WEB_PAGES_FETCHED_MOBILE:
            if (checkIfNoResults(action.data)) {
                return {
                    ...state,
                    showErrorMessage: searchErrors.noResultsInData,
                };
            }
            const shouldConcatPages = state.webPages?.length > 0;
            const wpmData = getWmp(action.data);
            return {
                ...state,
                webPages: shouldConcatPages ? state.webPages.concat(wpmData) : wpmData,
            };
        case MOBILE_PAGES_ARE_LOADING:
            return {
                ...state,
                mobilePagesAreLoading: action.data,
            };
        case CLEAR_WEB_PAGES:
            return {
                ...state,
                webPages: null,
                mobilePagesAreLoading: false,
            };
        case CLEAR_NEWS_PAGES:
            return {
                ...state,
                news: [],
                mobileNewsAreLoading: false,
            };
        case VIDEOS_FETCHED:
            const shouldVideosConcat = !!state.videos && !!state.videos.value;
            const mapSrcToVideos = prepareVideoItems(action.data.value);

            if (checkIfNoResults(action.data) && (state.videos.length === 0) | (state.videos?.value?.length === 0)) {
                return {
                    ...state,
                    showErrorMessage: searchErrors.noResultsInData,
                    videos: {
                        ...action.data,
                    },
                };
            }

            return {
                ...state,
                videos: {
                    ...action.data,
                    value: shouldVideosConcat ? state.videos.value.concat(mapSrcToVideos) : mapSrcToVideos,
                    videosGetRecently: action.data?.value?.length || 0,
                },
            };
        case CLEAR_VIDEOS:
            return {
                ...state,
                videos: [],
            };
        case WIKIBOX_DATA_FETCHED:
            return {
                ...state,
                wikiBox: action.data,
                wikiBoxInfoBox: prepareInfobox(action.data.infobox),
                wikiBoxLoadStatus: loadStatuses.completed,
            };
        case WIKIBOX_DATA_IS_EXPECTED:
            return {
                ...state,
                wikiBoxDataIsExpected: action.data,
            };
        case WIKIBOX_INFO_BOX_DATA_FETCHED:
            return {
                ...state,
                wikiBoxInfoBox: prepareInfobox(action.data),
            };
        case WIKIBOX_INFO_BOX_DATA_NOT_FOUND:
            return {
                ...state,
                wikiBoxInfoBoxNotFound: action.data,
            };
        case WIDGET_IMAGES_FETCHED:
            const mapDataToWidgetImages = mapSrcToFetchedImages(action.data.value);
            return {
                ...state,
                widgetImages: {
                    ...action.data,
                    value: mapDataToWidgetImages.slice(0, 10),
                },
            };
        case EXPECTED_ENTITIES_FETCHED:
            let setParams = {};
            action.data.forEach((entity) => {
                if (entity.expected?.data_probability > 0) {
                    if (constants.widgetRichHeaderTypes.includes(entity.triggered.name)) {
                        setParams.richHeaderLoadStatus = loadStatuses.loading;
                        setParams.widgetType = entity.triggered.name;
                        setParams.widgetEntityType = entity?.metadata?.data_type;
                        const richHeaderDataType = entity.metadata?.data_type || entity.triggered?.data_type;
                        if (richHeaderDataType) {
                            setParams.sportsWidgetView = richHeaderDataType;
                            setParams.widgetDataType = richHeaderDataType;
                        }
                        if (entity.triggered.name === constants.richHeaderTypes.location) {
                            setParams.widgetProvider = detectWidgetDataProvider(entity?.triggered?.url);
                        }
                    } else if (entity.triggered.name === constants.richHeaderTypes.entity) {
                        setParams.wikiBoxLoadStatus = loadStatuses.loading;
                    }
                }
            });
            return {
                ...state,
                ...setParams,
            };
        case CLEAR_STATE:
            return {
                ...state,
                timezoneWidget: null,
                timezoneWidgetMetaData: null,
                widgetVideos: null,
                widgetImages: [],
                // weatherCurrent: [],
                currencyWidget: null,
                currencyWidgetMetaData: null,
                weatherMetaData: null,
                weatherDaily: [],
                weatherHourly: [],
                weatherLocation: null,
                weatherUnits: null,
                weatherGroupedHourlyData: null,
                weatherGroupedHourly3Data: null,
                stocksWidget: null,
                stocksWidgetMetaData: null,
                businessWidget: null,
                businessWidgetMetaData: null,
                businessWidgetContext: null,
                footballWidget: [],
                sportsWidgetMetaData: null,
                basketballWidget: [],
                cricketWidget: [],
                widgetNews: [],
                richHeaderLoadStatus: loadStatuses.idle,
                widgetType: null,
                widgetDataType: null,
                wikiBox: {},
                wikiBoxDataIsExpected: false,
                wikiBoxInfoBox: null,
                wikiBoxInfoBoxNotFound: false,
                wikiBoxLoadStatus: loadStatuses.idle,
                moviesInfoCard: null,
                moviesInfoCardMetaData: null,
                moviesCollectionInfoCard: null,
                moviesCollectionInfoCardMetaData: null,
                bingAds: null,
                textAds: null,
                adsContext: null,
                yahooAds: [],
                sidebarTextAds: null,
                productCardAds: null,
                sideBarProductAds: null,
                queryContext: null,
                additionalWeatherDataStatus: loadStatuses.idle,
                rankingResponse: null,
                noResults: false,
                showErrorMessage: null,
                mapsModalBusinesses: [],
                instantAnswer: null,
                widgetProvider: null,
                widgetEntityType: null,
                detailedMapQuery: null,
            };
        case CURRENCY_WIDGET_DATA_FETCHED:
            const metaData = action.data[0].entities[0].metadata;
            const currencyData = action.data[0].entities[0].data;
            const rates = currencyData.rates || currencyData.currencies;
            const sortedRates = rates ? rates.sort((a, b) => a.name.localeCompare(b.name)) : rates;
            const currencyHalfYearData = currencyData.intrayear?.data.filter((each) => {
                return moment.unix(each.timestamp).isBetween(moment().subtract(6, 'months').startOf('day'), moment());
            });
            const currencyMonthData = currencyData.intrayear?.data.filter((each) => {
                return moment.unix(each.timestamp).isBetween(moment().subtract(1, 'months').startOf('day'), moment());
            });
            return {
                ...state,
                currencyWidget: {
                    ...currencyData,
                    rates: sortedRates,
                    intraday: prepareCurrencyData(currencyData.intraday),
                    intraweek: prepareCurrencyData(currencyData.intraweek),
                    intrayear: prepareCurrencyData(currencyData.intrayear),
                    intramax: prepareCurrencyData(currencyData.intramax),
                    month: prepareCurrencyData({
                        data: currencyMonthData,
                        last_fetched_time: currencyData.intrayear.last_fetched_time,
                        status: currencyData.intrayear.status,
                    }),
                    halfYear: prepareCurrencyData({
                        data: currencyHalfYearData,
                        last_fetched_time: currencyData.intrayear.last_fetched_time,
                        status: currencyData.intrayear.status,
                    }),
                },
                currencyWidgetMetaData:
                    metaData && metaData.hasOwnProperty('provider')
                        ? { ...metaData, providers: metaData.provider }
                        : metaData,
            };
        case STOCKS_WIDGET_DATA_FETCHED:
            const halfYear = action.data[0].entities[0].data.intrayear.data.filter((each) => {
                return moment.unix(each.timestamp).isBetween(moment().subtract(6, 'months').startOf('day'), moment());
            });
            const month = action.data[0].entities[0].data.intrayear.data.filter((each) => {
                return moment.unix(each.timestamp).isBetween(moment().subtract(1, 'months').startOf('day'), moment());
            });
            const intradayHasData = !!action.data[0].entities[0].data.intraday.data?.find(
                (entry) => entry.market_status !== 'pre_market',
            );
            return {
                ...state,
                stocksWidget: {
                    ...action.data[0].entities[0].data,
                    month: {
                        data: month,
                        last_updated_at: action.data[0].entities[0].data.intrayear.last_updated_at,
                        status: month.length
                            ? action.data[0].entities[0].data.intrayear.status
                            : constants.stocksStatusTypes.noData,
                    },
                    halfYear: {
                        data: halfYear,
                        last_updated_at: action.data[0].entities[0].data.intrayear.last_updated_at,
                        status: halfYear.length
                            ? action.data[0].entities[0].data.intrayear.status
                            : constants.stocksStatusTypes.noData,
                    },
                    intradayHasData,
                },
                stocksWidgetMetaData: action.data[0].entities[0].metadata,
            };
        case BUSINESS_WIDGET_DATA_FETCHED:
            const businessWidgetData = action.data.data[0].entities[0].data;
            const businessWidgetContext = businessWidgetData.context;
            const shouldRenderWidget = action.data.shouldRenderWidget;
            const isNearMeSearch = businessWidgetContext.nearme_loc;
            return {
                ...state,
                businessWidget: shouldRenderWidget ? businessWidgetData : null,
                businessWidgetMetaData: action.data.data[0].entities[0].metadata,
                businessWidgetContext: businessWidgetContext,
                locationTipAllowed: !state.suppressLocationTip && !!isNearMeSearch,
                widgetEntityType: action.data.data[0].entities[0].metadata.data_type,
            };
        case SAVE_BUSINESS_CONTEXT:
            return {
                ...state,
                businessWidgetContext: action.data,
            };
        case GET_MORE_BUSINESSES:
            // A workaround for a problem described here:
            // https://github.com/tempest-tech-ltd/tempest-search/issues/2824#issuecomment-1486636349
            let shouldConcatBusinesses = false;
            const forWidget = action.data.forWidget;
            const businessList = action.data.businesses;
            const businessesListFromState = state.businessWidget?.businesses;
            const mapsModalBusinessesFromState = state.mapsModalBusinesses;
            const businessesFromStateSrc = mapsModalBusinessesFromState?.length > 0 ?
                mapsModalBusinessesFromState : businessesListFromState;
            let orderedList = [];
            let filteredArray = businessList;
            if (businessesFromStateSrc?.length > 0 && businessesFromStateSrc?.length === 3) {
                const aliasFromState = businessesFromStateSrc.map((x) => x.alias);
                shouldConcatBusinesses = businessesFromStateSrc?.length > 0;
                filteredArray = businessList.filter((x) => !aliasFromState.includes(x.alias));
                orderedList = [...businessesFromStateSrc];
            }
            if (shouldConcatBusinesses) {
                const recentLocation = {
                    latitude: state.recentQueryFilters?.all?.recentLatitude,
                    longitude: state.recentQueryFilters?.all?.recentLongitude,
                };
                const currentLocation = {
                    latitude: action.data.params.latitude,
                    longitude: action.data.params.longitude,
                };
                if (
                    currentLocation.latitude &&
                    currentLocation.longitude &&
                    recentLocation.latitude &&
                    recentLocation.longitude
                ) {
                    shouldConcatBusinesses = sameCoordinates(recentLocation, currentLocation);
                }
            }
            orderedList = orderedList.concat(filteredArray);

            return {
                ...state,
                mapsModalBusinesses: shouldConcatBusinesses && !forWidget ? orderedList : action.data.businesses,
            };
        case SET_IS_MAP_TAB:
            return {
                ...state,
                isMapTab: action.data,
            };
        case SET_MAP_ACTIVE_PIN:
            return {
                ...state,
                activeCardFromWidget: action.data,
            };
        case MOVIES_INFOCARD_FETCHED:
            return {
                ...state,
                moviesInfoCard: action.data[0].entities[0].data,
                moviesInfoCardMetaData: action.data[0].entities[0].metadata,
            };
        case MOVIES_COLLECTION_INFOCARD_FETCHED:
            return {
                ...state,
                moviesCollectionInfoCard: action.data[0].entities[0].data,
                moviesCollectionInfoCardMetaData: action.data[0].entities[0].metadata,
            };
        case WEATHER_WIDGET_DATA_FETCHED:
            const data = action.data[0].entities[0].data;
            const locationUTCOffset = data.location.timezone / 60;
            data.daily = removeObsoleteWeatherData(data.daily, locationUTCOffset);
            const today = unixDatetimeByTimezone(locationUTCOffset);
            const getCurrentDay = today.isoWeekday();
            const getCurrentHour = today.hour();
            const dataProvided = !!action.data && action.data.length > 0;
            const weatherGroupedHourlyData = groupTimestampsByDays({
                data: data.hourly,
                timezone: locationUTCOffset,
            });
            const weatherGroupedHourly3Data = groupTimestampsByDays({
                data: data.hourly3,
                timezone: locationUTCOffset,
            });
            let getMissingHoursForCurrentDay = [];
            weatherGroupedHourlyData[getCurrentDay > 6 ? 1 : getCurrentDay + 1].forEach((hour) => {
                if (moment.unix(hour.dt).utcOffset(locationUTCOffset).hour() <= getCurrentHour) {
                    getMissingHoursForCurrentDay.push(hour);
                }
            });
            if (typeof weatherGroupedHourlyData[getCurrentDay] === 'undefined') {
                weatherGroupedHourlyData[getCurrentDay] = [];
            }
            weatherGroupedHourlyData[getCurrentDay].push(...getMissingHoursForCurrentDay);
            let mergeCurrentWithDaily = data.daily;
            const getCurrentSource = Array.isArray(data.current) ? data.current[0] : data.current;
            if (!today.isAfter(moment.unix(getCurrentSource.dt).utcOffset(locationUTCOffset), 'day')) {
                mergeCurrentWithDaily = [
                    {
                        ...getCurrentSource,
                        pop: data.daily[0].pop,
                        temp: data.daily[0].temp,
                    },
                    ...data.daily.slice(1),
                ];
            }
            if (
                mergeCurrentWithDaily.length > 1 &&
                getDateTimeFromUnix({
                    timestamp: mergeCurrentWithDaily[0].dt,
                    utcOffset: locationUTCOffset,
                    format: 'D',
                }) ===
                    getDateTimeFromUnix({
                        timestamp: mergeCurrentWithDaily[1].dt,
                        utcOffset: locationUTCOffset,
                        format: 'D',
                    })
            ) {
                mergeCurrentWithDaily.shift();
            }
            return {
                ...state,
                weatherDaily: dataProvided ? chunkArrayInGroups(mergeCurrentWithDaily, weekSize)[0] : '',
                weatherHourly: dataProvided ? data.hourly3 : '',
                weatherLocation: dataProvided ? { ...data.location, timezone: locationUTCOffset } : '',
                weatherMetaData: action.data[0].entities[0].metadata,
                weatherUnits: data.units,
                weatherGroupedHourlyData: weatherGroupedHourlyData,
                weatherGroupedHourly3Data: weatherGroupedHourly3Data,
            };
        case FOOTBALL_WIDGET_DATA_FETCHED:
            return {
                ...state,
                footballWidget: parseSportsData(action.data),
                sportsWidgetMetaData: action.data[0].entities[0].metadata,
            };
        case BASKETBALL_WIDGET_DATA_FETCHED:
            return {
                ...state,
                basketballWidget: parseSportsData(action.data),
                sportsWidgetMetaData: action.data[0].entities[0].metadata,
            };
        case CRICKET_WIDGET_DATA_FETCHED:
            let getRounds = [];
            let getTeams = [];
            let getLeague;
            action.data[0].entities[0].data.leagues.forEach((league) => {
                if (league.seasons) {
                    league.seasons.forEach((season) => {
                        if (season.rounds.length > 0) {
                            getRounds.push(...season.rounds);
                            getTeams.push(...season.teams);
                            getLeague = league;
                        }
                    });
                }
            });

            return {
                ...state,
                cricketWidget: {
                    responseType: action.data[0].entities[0].data.type,
                    league: getLeague,
                    teams: getTeams,
                    rounds: getRounds,
                    team: action.data[0].entities[0].data.team,
                },
                sportsWidgetMetaData: action.data[0].entities[0].metadata,
            };
        case WIDGET_VIDEOS_FETCHED:
            return {
                ...state,
                widgetVideos: {
                    ...action.data,
                    value: prepareVideoItems(action.data.value),
                },
            };
        case CLEAR_IMAGES:
            return {
                ...state,
                images: [],
            };
        case NEWS_FETCHED:
            if (checkIfNoResults(action.data)) {
                return {
                    ...state,
                    showErrorMessage: searchErrors.noResultsInData,
                };
            }
            return {
                ...state,
                news: prepNewsData(action.data),
            };
        case NEWS_FETCHED_MOBILE:
            if (checkIfNoResults(action.data)) {
                return {
                    ...state,
                    showErrorMessage: searchErrors.noResultsInData,
                };
            }
            const shouldConcatNews = state.news?.value?.length > 0;
            const newsMobileData = prepNewsData(action.data);
            return {
                ...state,
                news: shouldConcatNews
                    ? {
                          ...newsMobileData,
                          value: state.news.value.concat(newsMobileData.value),
                      }
                    : newsMobileData,
            };
        case MOBILE_NEWS_ARE_LOADING:
            return {
                ...state,
                mobileNewsAreLoading: action.data,
            };
        case SET_ADS_CONTEXT:
            return {
                ...state,
                adsContext: action.data,
            }
        case SET_YAHOO_ADS:
            return {
                ...state,
                yahooAds: action.data,
            }
        case ADS_FETCHED:
            let ads = {};
            let setSidebarProductAds, setProductAds, setTextAds, setSidebarTextAds;

            const productAds = action?.data?.value?.filter((x) => x._type === constants.adsTypes.productCardAds);
            const textAds = action?.data?.value?.filter((x) => x._type === constants.adsTypes.textAds);
            if (productAds) {
                let visibilityFeedbackUrlParams;
                if (action.data.visibilityFeedbackUrl) {
                    visibilityFeedbackUrlParams = queryString.parse(action.data.visibilityFeedbackUrl);
                }
                setProductAds = {
                    value: productAds.filter((x) => x.position === constants.adsPosition.mainline),
                    visibilityFeedbackUrlParams,
                };
                setSidebarProductAds = {
                    value: productAds.filter((x) => x.position === constants.adsPosition.sidebar),
                    columns: action.data?.productAdsLayouts?.[0]?.columns,
                    rows: action.data?.productAdsLayouts?.[0]?.rows,
                    visibilityFeedbackUrlParams,
                };
            }

            if (textAds) {
                setTextAds = textAds.filter((x) => x.position === constants.adsPosition.mainline);
                setSidebarTextAds = textAds.filter((x) => x.position === constants.adsPosition.sidebar);
            }

            const hasProductAds = setProductAds?.value?.length > 0;
            const hasSidebarProductAds = setSidebarProductAds?.value?.length > 0;
            const hasTextAds = setTextAds?.length > 0;
            const hasSidebarTextAds = setSidebarTextAds?.length > 0;

            if (hasProductAds) {
                ads.productCardAds = setProductAds;
            }
            if (hasSidebarProductAds) {
                ads.sideBarProductAds = setSidebarProductAds;
            }
            if (hasTextAds) {
                ads.textAds = bulkRemoveUnicodeChars(setTextAds);
            }
            if (hasSidebarTextAds) {
                ads.sidebarTextAds = bulkRemoveUnicodeChars(setSidebarTextAds);
            }
            return {
                ...state,
                ...ads,
            };
        case INSTANT_ANSWER_COMPUTATION_FETCHED:
            return {
                ...state,
                instantAnswer: {
                    isBig: true,
                    leftTitle: 'EXPRESSION',
                    leftValue: action.data.expression,
                    rightTitle: 'VALUE',
                    rightValue: action.data.value,
                    type: 'computation',
                },
            };
        case INSTANT_ANSWER_TRANSLATION_FETCHED:
            if (
                action.data.translatedText === null ||
                (typeof action.data.translatedText === 'string' && action.data.translatedText.trim() === '')
            ) {
                return { ...state, instantAnswer: null };
            }
            return {
                ...state,
                instantAnswer: {
                    isBig: true,
                    leftTitle: action.data.inLanguage,
                    leftValue: Capitalize(action.data.originalText),
                    rightTitle: action.data.translatedLanguageName,
                    rightValue: Capitalize(action.data.translatedText),
                    type: 'translation',
                },
            };
        case INSTANT_ANSWER_TIMEZONE_FETCHED:
            if (action.data.primaryCityTime) {
                return {
                    ...state,
                    instantAnswer: {
                        tzData: action.data,
                        type: 'time',
                    },
                };
            }
            return state;
        case TIMEZONE_WIDGET_FETCHED:
            return {
                ...state,
                timezoneWidget: action.data?.[0]?.entities[0].data,
                timezoneWidgetMetaData: action.data?.[0]?.entities[0].metadata,
            };
        case CLEAR_INSTANT_ANSWER:
            return {
                ...state,
                instantAnswer: null,
            };
        case WIDGET_NEWS_FETCHED:
            const wnData = action.data
                ? {
                      ...action.data,
                      value: action.data.value
                          .map((item) => ({
                              ...item,
                              name: stripUnicodeChars(item.name),
                              description: stripUnicodeChars(item.description),
                          }))
                          .slice(0, 4),
                  }
                : action.data;

            return {
                ...state,
                widgetNews: {
                    ...wnData,
                },
            };
        case RELATED_SEARCHES_FETCHED:
            return {
                ...state,
                relatedSearches: action.data
                    ? {
                          ...action.data,
                          value: action.data.value
                              ? action.data.value
                                    .map((item) => ({
                                        ...item,
                                    }))
                                    .slice(0, 8)
                              : null,
                      }
                    : action.data,
            };
        case RANKING_RESPONSE_FETCHED:
            // Temporary workaround to avoid empty SERP,
            // see: https://github.com/tempest-tech-ltd/tempest-search/issues/1183#issuecomment-1234297002
            const items = getItems(action.data?.mainline);
            let filteredMainline = [];
            if (items) {
                filteredMainline = items.filter((x) => x.answerType !== 'Ads');
            }
            // Render Images widget on top of the page if response contains images for it
            if (action.shouldRenderImagesWidget) {
                filteredMainline.splice(0, 0, rankingResponseImagesItem);
            }

            return {
                ...state,
                rankingResponse: {
                    mainline: [...filteredMainline],
                },
            };
        case RANKING_RESPONSE_FETCHED_MOBILE:
            let getNextItems;
            const itemsForMobile = getItems(action.data?.mainline);
            const filterWebPages = (item) => item.answerType === 'WebPages';
            const shouldConcatRankingResponse = state.rankingResponse?.mainline?.length > 0;
            if (!shouldConcatRankingResponse && action.shouldRenderImagesWidget) {
                // Including answerType: "Images" object into the itemsForMobile
                itemsForMobile.splice(0, 0, rankingResponseImagesItem);
            } else if (shouldConcatRankingResponse) {
                const getLastItemsIndex = state.rankingResponse?.mainline
                    ?.filter(filterWebPages)
                    .slice(-1)[0].resultIndex;
                getNextItems = itemsForMobile.filter(filterWebPages).map((item, i) => {
                    return {
                        ...item,
                        resultIndex: getLastItemsIndex + i + 1,
                    };
                });
            }

            return {
                ...state,
                rankingResponse: {
                    mainline: !shouldConcatRankingResponse
                        ? [...itemsForMobile]
                        : state.rankingResponse.mainline.concat(getNextItems),
                },
            };
        case CLEAR_RANKING_RESPONSE:
            return {
                ...state,
                rankingResponse: null,
            };
        case QUERY_CONTEXT_FETCHED:
            const queryContextBuf = state.queryContext || {};
            queryContextBuf[action.data.searchType] = action.data.queryContext;
            return {
                ...state,
                queryContext: queryContextBuf,
            };
        case CLEAR_ALL_FILTERS_SEARCH_SETTING:
            return {
                ...state,
                filterByTime: filterOptions.filterByTimeOptions[0].value,
                filterByImageSize: filterOptions.imageSizeFilterOptions[0].value,
                filterByImageColor: filterOptions.imageFilterByColorOptions[0].value,
                filterByImageType: filterOptions.imageTypeFilterOptions[0].value,
                filterByImageAspectRatio: filterOptions.imageAspectRatioFilterOptions[0].value,
                filterByImageLicense: filterOptions.imageLicenseFilterOptions[0].value,
                filterByVideoLength: filterOptions.videoDurationFilter[0].value,
                filterByVideoResolution: filterOptions.videoResolutionFilter[0].value,
            };
        case CHANGE_FILTER_BY_VIDEO_LENGTH_SETTING:
            saveToLocalStorage(state.filterByVideoLength, 'video_length', action.data);
            return {
                ...state,
                filterByVideoLength: action.data,
            };
        case CHANGE_FILTER_BY_VIDEO_RESOLUTION_SETTING:
            saveToLocalStorage(state.filterByVideoResolution, 'resolution', action.data);
            return {
                ...state,
                filterByVideoResolution: action.data,
            };
        case CHANGE_FILTER_BY_IMAGE_SIZE_SETTING:
            saveToLocalStorage(state.filterByImageSize, 'size', action.data);
            return {
                ...state,
                filterByImageSize: action.data,
            };
        case CHANGE_FILTER_BY_COLOR_SETTING:
            saveToLocalStorage(state.filterByImageColor, 'color', action.data);
            return {
                ...state,
                filterByImageColor: action.data,
            };
        case CHANGE_FILTER_BY_IMAGE_TYPE_SETTING:
            saveToLocalStorage(state.filterByImageType, 'imageType', action.data);
            return {
                ...state,
                filterByImageType: action.data,
            };
        case CHANGE_FILTER_BY_IMAGE_ASPECT_RATIO_SETTING:
            saveToLocalStorage(state.filterByImageAspectRatio, 'imageAspect', action.data);
            return {
                ...state,
                filterByImageAspectRatio: action.data,
            };
        case CHANGE_FILTER_BY_IMAGE_LICENSE_SETTING:
            saveToLocalStorage(state.filterByImageLicense, 'imageLicense', action.data);
            return {
                ...state,
                filterByImageLicense: action.data,
            };
        case CHANGE_LOCATION_SEARCH_SETTING:
            const setLocation = action.data.mcode
                ? action.data
                : formatCountry(countries.list.find((country) => country.mcode === action.data));
            delete setLocation.label;
            saveToLocalStorage(state.searchLocation, 'mkt', JSON.stringify(setLocation, getCircularReplacer()));
            return {
                ...state,
                searchLocation: setLocation,
                isAMPM: unlocode.includes(setLocation.code),
            };
        case CHANGE_FILTER_BY_TIME_SEARCH_SETTING:
            saveToLocalStorage(state.filterByTime, 'freshness', action.data);
            return {
                ...state,
                filterByTime: action.data,
            };
        case CHANGE_SOURCE_API:
            saveToLocalStorage(state.sourceapi, 'sourceapi', action.data);
            return {
                ...state,
                sourceapi: action.data,
            };
        case CLEAR_WIKIBOX:
            return {
                ...state,
                wikiBox: {},
                wikiBoxInfoBox: null,
                wikiBoxInfoBoxNotFound: false,
            };
        case SET_SPORTS_WIDGET_VIEW:
            return {
                ...state,
                sportsWidgetView: action.data,
            };
        case CHANGE_PT:
            saveToLocalStorage(state.pt, 'pt', action.data);
            return {
                ...state,
                pt: action.data,
            };
        case SET_IS_HEADER_FIXED:
            return {
                ...state,
                isHeaderFixed: action.data,
            };
        case CHANGE_PARAM:
            const paramData = typeof action.data === 'string' ? [action.data] : action.data;
            const additionalParams = JSON.parse(getFromLocalStorage('additionalParams')) || {};
            paramData.forEach((paramDataItem) => {
                const matches = paramDataItem.match(/^([^:]+):(.+)$/);
                if (matches) {
                    additionalParams[matches[1]] = matches[2];
                }
            });
            saveToLocalStorage(
                JSON.stringify(state.additionalParams),
                'additionalParams',
                JSON.stringify(additionalParams),
            );
            return {
                ...state,
                additionalParams,
            };
        case SET_CLEAR_IMAGE_HANDLER:
            return {
                ...state,
                clearImageHandler: action.data,
            };
        case SET_LOCATION_SHARING:
            return {
                ...state,
                withSharedLocation: action.data,
            };
        case SET_RECENT_QUERY_FILTERS:
            const searchType = action.data.searchType === searchTypes.home ? searchTypes.all : action.data.searchType;
            const stFilters = state.recentQueryFilters.hasOwnProperty(searchType)
                ? state.recentQueryFilters[searchType]
                : {};
            const rqFiltersSlice = {};
            rqFiltersSlice[searchType] = {
                ...stFilters,
                ...action.data.filters,
            };
            return {
                ...state,
                recentQueryFilters: {
                    ...state.recentQueryFilters,
                    ...rqFiltersSlice,
                },
            };
        case SET_DATA_FETCHING:
            return {
                ...state,
                dataFetching: action.data,
            };
        case SET_LOCATION_TIP_ALLOWED:
            if (!action.data) {
                saveToLocalStorage(null, 'suppressLocationTip', true);
            }
            return {
                ...state,
                locationTipAllowed: action.data,
                locationTipStep2Allowed: action.data,
                suppressLocationTip: !action.data,
            };
        case SET_LOCATION_TIP_STEP2_ALLOWED:
            return {
                ...state,
                locationTipStep2Allowed: action.data,
            };
        case SET_SEARCH_RESULT_CACHE_DISABLED:
            return {
                ...state,
                searchResultCacheDisabled: action.data,
            };
        case SET_APPLE_MAP_SEARCH_LOCATION:
            return {
                ...state,
                appleMapsSearchLocation: action.data,
            };
        case SET_DETAILED_MAP_QUERY:
            return {
                ...state,
                detailedMapQuery: action.data,
            };
        case WIKIHOW_WIDGET_DATA_FETCHED:
            return {
                ...state,
                wikiHowData: prepareWikiHowData(action.data[0]),
            };
        case SET_SEARCH_EXTRA_PARAMS:
            return {
                ...state,
                searchExtraParams: action.data,
            };
        default:
            return state;
    }
}
