import './InstantAnswerWidget.scss';

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import * as loadStatuses from '../../../constants/loadStatuses';
import InstantAnswerSkeleton from './InstantAnswerSkeleton/InstantAnswerSkeleton'

import { ReactComponent as ArrowIcon } from '../../images/icons/arrow-right-icon.svg';
import WidgetTemplate from '../WidgetTemplate/WidgetTemplate';

class InstantAnswerWidget extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			leftInputFontSize: 28,
			rightInputFontSize: 28,
		};
		this.leftInputRef = React.createRef();
		this.rightInputRef = React.createRef();
	}

	componentDidMount() {
		const { leftValue, rightValue, isBig } = this.props.data;
		if (!isBig && leftValue) {
			this.setState({
				leftInputFontSize: this.getFontSize(
					leftValue,
					this.leftInputRef.current,
				),
			});
		}
		if (!isBig && rightValue) {
			this.setState({
				rightInputFontSize: this.getFontSize(
					rightValue,
					this.rightInputRef.current,
				),
			});
		}
	}

	getLangTitle(langCode) {
		const { language } = this.props;
		const langNames = new Intl.DisplayNames([language], {
			type: 'language',
		});
		return langNames.of(langCode);
	}

	getTextWidth(text, font) {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		context.font = font || getComputedStyle(document.body).font;

		return context.measureText(text).width;
	}

	getFontSize(text, element) {
		let fontSize = 28;
		if (!element) {
			return fontSize;
		}
		const paddingLeft = parseInt(
			window.getComputedStyle(element, null).paddingLeft,
		);
		const paddingRight = parseInt(
			window.getComputedStyle(element, null).paddingRight,
		);
		const width = element.clientWidth - paddingLeft - paddingRight;
		while (fontSize > 12) {
			const length = this.getTextWidth(text, `${fontSize}px Arial`);
			if (length <= width) {
				return fontSize;
			}
			fontSize--;
		}
		return fontSize;
	}

	render() {
		const { data, t } = this.props;
		const { isBig, leftValue, rightValue, type, rightTitle, leftTitle } =
			data;
		const byTempestLabel = isBig
			? t('infocard-by-tempest-text-translation', 'text translation')
			: t('infocard-by-tempest-converting-values', 'converting values');
		const { leftInputFontSize, rightInputFontSize } = this.state;
		const isTranslation = type === 'translation';

    if (this.props.richHeaderLoadStatus === loadStatuses.loading) return <InstantAnswerSkeleton/>

		return (
			<WidgetTemplate.Wrapper>
				<WidgetTemplate.Paper
					className="instant-answer-widget"
					paddingStyle="narrow-top"
				>
					{isBig ? (
						<div className="instant-answer-widget__text">
							<em className="instant-answer-widget__title">
								Instant answer
							</em>
							{rightValue}
						</div>
					) : (
						<div className="instant-answer-widget__wrapper">
							<WidgetTemplate.Block className="instant-answer-widget__input-wrapper">
								<input
									style={{ fontSize: leftInputFontSize }}
									className={`instant-answer-widget__input${
										isTranslation
											? ' instant-answer-widget__input--with-title'
											: ''
									}`}
									type="text"
									value={leftValue}
									readOnly
									ref={this.leftInputRef}
									name="instantAnswerTerm"
									id="instantAnswerTerm"
								/>
								{isTranslation && (
									<label
										htmlFor="instantAnswerTerm"
										className="instant-answer-widget__input-title"
									>
										{this.getLangTitle(leftTitle)}
									</label>
								)}
							</WidgetTemplate.Block>
							<div className="instant-answer-widget__arrow">
								<ArrowIcon />
							</div>
							<WidgetTemplate.Block className="instant-answer-widget__input-wrapper">
								<input
									style={{ fontSize: rightInputFontSize }}
									className={`instant-answer-widget__input${
										isTranslation
											? ' instant-answer-widget__input--with-title'
											: ''
									}`}
									type="text"
									value={rightValue}
									readOnly
									ref={this.rightInputRef}
									name="instantAnswerDef"
									id="instantAnswerDef"
								/>
								{isTranslation && (
									<label
										htmlFor="instantAnswerDef"
										className="instant-answer-widget__input-title"
									>
										{this.getLangTitle(rightTitle)}
									</label>
								)}
							</WidgetTemplate.Block>
						</div>
					)}
				</WidgetTemplate.Paper>
				<WidgetTemplate.Footer
					infocardData={{
						byTempest: byTempestLabel,
						provider: {
							name:
								type === 'translation'
									? 'Microsoft Translator'
									: 'Microsoft Computation API',
						},
					}}
					withDetails
					withFeedback
				/>
			</WidgetTemplate.Wrapper>
		);
	}
}

const mapStateToProps = (state, props) => ({
	language: state.app.appLanguage,
  richHeaderLoadStatus: state.search.richHeaderLoadStatus,
});

export default connect(
	mapStateToProps,
	null,
)(withTranslation('common')(InstantAnswerWidget));
